<template>
<div :class="labelPosition === 'left' ? 'flex justify-between items-center' : ''">
    <div class="flex items-center mt-2" :class="labelPosition === 'left' ? 'w-2/12' : ''">
        <rb-label
            :label="label"
            :helpers="
            [{
                type: 'info',
                content: dataHelp,
            }]"
        />
        <n-button
            secondary
            size="small"
            class="ml-2 rounded-md"
            type="tertiary"
            :disabled="!value?.length"
            @click="letsCopyList">
            <n-icon class="cursor-pointer mr-1 text-base">
                <Copy16Regular />
            </n-icon>
            {{ refs.localization?.confirmations?.currencys?.lets_copy_list || 'Скопировать список' }}
        </n-button>
        <n-button
            secondary
            size="small"
            class="ml-2 rounded-md"
            type="tertiary"
            @click="letsPasteList">
            <n-icon class="cursor-pointer mr-1 text-base">
                <ClipboardPaste16Filled />
            </n-icon>
            {{ refs.localization?.confirmations?.currencys?.lets_paste_list || 'Вставить список' }}
        </n-button>
    </div>
    <div :class="labelPosition === 'left' ? 'w-10/12' : 'w-full mt-2'">
        <n-select
            clearable
            size="medium"
            class="rounded-md"
            :multiple="multiple"
            :filterable="filterable"
            :options="options"
            :placeholder="`- ${t('select')} -`"
            :render-label="renderLabel"
            :loading="loading"
            :disabled="disabled || loading"
            :value="value !== -1 ? value : undefined"
            @update:value="$emit('update:value', $event)"/>
    </div>
    <slot name="messages"></slot>
  </div>
</template>

<script>
// general
import general from './general';

// icons
import { Copy16Regular, ClipboardPaste16Filled } from '@vicons/fluent';

// naive-ui
import {
    NIcon,
    NSelect,
    NButton,
    NPopover } from 'naive-ui';

export default {
    name: 'rb-coin-list',
    emits: ['update:value'],
    props: {
        label: {
            type: String,
            required: true,
        },
        labelPosition: {
            type: String,
            default: 'top',
            validator: prop => ['right', 'top'].includes(prop),
        },
        dataHelp: {
            type: String,
        },
        filterable: {
            type: Boolean,
            default: true,
        },
        options: {
            type: Array,
            required: true,
        },
        loading: {
            type: Boolean,
        },
        value: {
            type: [String, Number],
            required: true,
            default: null,
        },
        multiple: {
            type: Boolean,
        },
        disabled: {
            type: Boolean,
        },
        nowrap: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        NIcon,
        NSelect,
        NPopover,
        NButton,
        Copy16Regular,
        ClipboardPaste16Filled,
    },
    setup(props, context) {
        
        return {
            ...general(...arguments),
        };
    },
};
</script>