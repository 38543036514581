// vue
import { h, ref, reactive, computed, watch, onMounted, onBeforeUnmount } from 'vue';

// services
import BotsService from'@services/bots';
import ExchangesService from '@services/exchangesService';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useBots } from '@store/bots';
import { useConfirm } from '@store/confirm';

// components
import RbCheckbox from '@components/rb-checkbox/index.vue';

// local services
import { prepareSwitchTp, prepareStartFilters } from '@components/bots/services';

import { BOTS_DATA_FORM_FACTORY, BOTS_DATA_FORM_FACTORY_PREPARE } from '@shared/factories';

// i18n
import { useI18n } from 'vue-i18n';

export default function (props, context) {
    // store
    const gl = useGl();
    const refs = useRefs();
    const bots = useBots();
    const confirm = useConfirm();

    // vars
    const start = ref(false);
    const allBots = ref([]);
    const allExchanges = ref();
    const loadings = reactive({
        modal: false,
        button: false,
        bots: {},
    });
    const dataForm = reactive({});
    const messages = ref({});
    const showDropdownRef = ref(false);
    const visibleSettings = ref([]);
    // lodash
    const { _ } = window;

    // i18n
    const { t } = useI18n();

    watch(() => props.show, v => {
        if (!v) {
            visibleSettings.value = [];
            start.value = false;
        }
    });

    watch(() => visibleSettings.value.length, () => {
        start.value = false;
    });

    // computeds
    // const leverageRefs = computed(() => exchangeInfo.value?.leverage || {});
    // const leverageMinMax = computed(() => leverageRefs.value[dataForm.pair.value] || {});
    const isAlgoLong = computed(() => allBots.value[0]?.algo == 'long');
    const leverageMinMax = computed(() => {
        let result = null;

        allBots.value.forEach(({ exchange, pair }) => {
            const i = allExchanges.value.findIndex(({ id }) => id == exchange);

            if (~i) {
                const { leverage } = allExchanges.value[i];

                const localLeverage = leverage?.[pair];

                if (localLeverage?.min != null && localLeverage?.max != null) {
                    if (!result) {
                        result = localLeverage;
                    } else {
                        if (result.min > localLeverage.min) {
                            result.min = localLeverage.min;
                        }

                        if (result.max > localLeverage.max) {
                            result.max = localLeverage.max;
                        }
                    }
                }
            }
        });
        
        return result;
    });

    const settingsList = computed(() => [
        {
            id: 'leverage',
            path: 'leverage',
            title: bots.localization['bot/leverage/label'],
            checked: visibleSettings.value.includes('leverage'),
        }, {
            id: 'profit',
            path: 'settings.profit',
            title: bots.localization['bot_profit_f'],
            checked: visibleSettings.value.includes('profit'),
        }, {
            id: 'rate_cover',
            path: 'settings.rate_cover',
            title: bots.localization['bot_rate_cover_f'],
            checked: visibleSettings.value.includes('rate_cover'),
        }, {
            id: 'logarithmic_scale',
            path: 'settings.logarithmic_scale',
            title: bots.localization['bot_additional_settings_logarithmic_scale_checkbox'],
            checked: visibleSettings.value.includes('logarithmic_scale'),
        }, {
            id: 'cycle_up',
            path: 'settings.cycle_up',
            title: bots.localization['bot_cycle_up_f'],
            checked: visibleSettings.value.includes('cycle_up'),
        }, {
            id: 'first_order_indent',
            path: 'settings.first_order_indent',
            title: bots.localization['bot_first_order_indent_f'],
            checked: visibleSettings.value.includes('first_order_indent'),
        }, {
            id: 'part_orders_enabled',
            path: 'settings.part_orders_enabled',
            title: bots.localization['bot/settings/part_orders_enabled'],
            checked: visibleSettings.value.includes('part_orders_enabled'),
        }, {
            id: 'switch_check_running',
            path: 'switch_tp.switch_check_running',
            title: bots.localization['bot_auto_switch_unique_checkbox'],
            checked: visibleSettings.value.includes('switch_check_running'),
        }, {
            id: 'stop_loss',
            path: 'start_filters',
            title: bots.localization['stop_loss'],
            checked: visibleSettings.value.includes('stop_loss'),
        },
    ]);

    const settingsOptions = computed(() => {
        return settingsList.value.map((el, index) => {
            const label = el.title?.[el.title.length - 1] == ':' ? el.title.slice(0, -1) : el.title;

            const data = {
                key: el.id,
                value: el.id,
                label,
                ...el,
            };

            return {
                ...data,
                type: 'render',
                render: () => h('div', {
                    class: 'w-full h-full flex items-center justify-between mx-0 hover:bg-gray-100 dark:hover:bg-inputBackground',
                }, h(RbCheckbox, {
                    label: data.label,
                    checked: data.checked,
                    class: 'w-full h-full items-center py-2 px-3',
                    onUpdateChecked: () => {
                        setVisibleSettings(data.key, index);
                        // showDropdownRef.value = false;
                    },
                })),
            };
        });
    });

    const setVisibleSettings = (setting, index) => {
        const idx = visibleSettings.value.findIndex(el => el == setting);

        ~idx
            ? visibleSettings.value.splice(idx, 1)
            : visibleSettings.value.splice(index, 0, setting);
    };

    const profitOptions = computed(() => bots.settingsRefs.profit.map(el => ({
        label: el.title + '',
        value: el.id + '',
        ...el,
    })));

    const logarithmicFactorOptions = computed(() => bots.settingsRefs.logarithmic_factor.map(el => ({
        label: el.title,
        value: el.id,
        ...el,
    })));

    const cycleUpOptions = computed(() => bots.settingsRefs.cycle_up.map(el => ({
        label: el.title + '',
        value: el.id + '',
        ...el,
    })));

    const firstOrderIndentOptions = computed(() => bots.settingsRefs.first_order_indent.map(el => ({
        label: el.title,
        value: el.id + '',
        ...el,
    })));

    const allLogarithmicScaleChecked = computed(() => allBots.value.every(({ settings }) => !!settings.logarithmic_scale));

    const compMatrixMaxCount = computed(() => {
        let matrixCount = 0;

        allBots.value.forEach(({ settings }) => {
            const ordersMatrix = bots.ordersMatrix.find(({ id }) => id == settings.order_matrix);
  
            const localMatrixCount = parseInt(ordersMatrix?.matrix_count || ordersMatrix?.matrix.length, 10);

            if (localMatrixCount > matrixCount) {
                matrixCount = localMatrixCount;
            }
        });

        return matrixCount;
    });

    const rateCoverOptions = computed(() =>
        bots.settingsRefs.rate_cover
            .map(el => ({
                label: el.title + '',
                value: el.id + '',
                ...el,
            }))
            .filter(el => isAlgoLong.value ? el.value <= 99 : true ),
    );

    // watches
    watch(() => props.show, v => {
        if (v) {
            // const id = props.checkedBots[0].id;
            // getFullInfo(id);
            getInfoAllBots();
        } else {
            // copiesBotModel.count = 1;
            // countDone.value = 0;
            // percentage.value = 0;
        }
    });

    const getInfoAllBots = async () => {
        loadings.modal = true;

        const result = await Promise.allSettled([
            Promise.allSettled(props.checkedBots.map(({ id }) => getFullInfo(id, false))),
            Promise.allSettled(props.checkedBots.map(({ exchange }) => getExchange(exchange, false))),
        ]);

        allBots.value = [...result[0].value.map(({ value }) => value)];
        allExchanges.value = [...result[1].value.map(({ value }) => value)];
        
        // console.log('allBots', allBots.value);
        // console.log('allExchanges', allExchanges.value);
        initDataForm();

        loadings.modal = false;
    };

    const getExchange = async id => {
        try {
            const result = await ExchangesService.getFullInfo(id);

            if (result.data.status) {
                return result.data;
            }
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        }
    };

    const getFullInfo = async (id, loading = true) => {
        if (loading)
            loadings.modal = true;

        try {
            const result = await BotsService.getFullInfo(id);

            if (!result.data.status) {
                result.data.errors.forEach(({ msg }) => {
                    gl.showNotification({
                        type: 'error',
                        msg,
                    });
                });
            } else {
                return result.data.record;
            }
        } catch (error) {
            console.log(error);
            // gl.showNotification({
            //     type: 'error',
            //     msg: t('errorMessage'),
            // });
        }
        if (loading)
            loadings.modal = false;
    };

    const initDataForm = () => {
        const res = reactive(BOTS_DATA_FORM_FACTORY({}, []));

        for (const [key, value] of Object.entries(res)) {
            if (key === 'leverage') {
                dataForm[key] = {
                    value,
                    label: bots.localization['bot/leverage/label'],
                    info: bots.localization['bot/leverage/info'],
                    customField: true,
                };
            } else {
                dataForm[key] = value;
            }
        }

        // set default values
        dataForm.settings.logarithmic_scale = allLogarithmicScaleChecked.value;
    };

    const isFutures = exchange => {
        const current = allExchanges.value.find(({ id }) => id == exchange);

        if (current) {
            return current.futures == 1 ? true : false;
        }

        return false;
    };

    const prepareConfigs = payload => {
        allBots.value.forEach(bot => {
            const exchangeIsFutures = isFutures(bot.exchange);

            settingsList.value.forEach(setting => {
                if (setting.checked) {
                    if (setting.id == 'leverage') {
                        if (exchangeIsFutures) {
                            _.set(bot, setting.path, _.get(payload, setting.path));
                        }
                    } else if (setting.id == 'logarithmic_scale') {
                        _.set(bot, setting.path, _.get(payload, setting.path));

                        if (_.get(payload, setting.path)) {
                            _.set(bot, 'settings.logarithmic_factor', _.get(payload, 'settings.logarithmic_factor'));
                        }
                    } else if (setting.id == 'part_orders_enabled') {
                        _.set(bot, setting.path, _.get(payload, setting.path));

                        if (_.get(payload, setting.path)) {
                            _.set(bot, 'settings.part_orders_value', _.get(payload, 'settings.part_orders_value'));
                        }

                        if (!exchangeIsFutures && _.get(payload, setting.path)) {
                            _.set(bot, 'settings.part_orders_unstopable', _.get(payload, 'settings.part_orders_unstopable'));
                        }
                    } else if (setting.id == 'stop_loss') {
                        if (payload.start_filters.length) {
                            payload.start_filters.forEach(filter => {
                                const hasFilter = bot.start_filters.findIndex(botFilter => botFilter?.id == filter?.id);
                
                                if (!~hasFilter) {
                                    bot.start_filters.push(filter);
                                }
                            });
                        }
                    } else if (_.get(payload, setting.path) != -1 && _.get(payload, setting.path) !== null) {
                        _.set(bot, setting.path, _.get(payload, setting.path));
                    }

                    bot.start_filters_enabled = bot.start_filters.length > 0;

                    bot.start_filters.forEach(el => {
                        delete el.parent_id;
                        delete el.changed;
                    });
            
                    bot.start_filters = prepareStartFilters(bot);
                    bot.switch_tp = prepareSwitchTp(bot);
                    bot['_formPath'] = 'bots.update';
                }
            });
        });
    };

    const onSave = async () => {
        loadings.button = true;
        start.value = true;
        messages.value = {};
        const payload = BOTS_DATA_FORM_FACTORY_PREPARE(dataForm);

        prepareConfigs(payload);

        // console.log('allBots', allBots.value);

        let hasError = false;

        for (let index = 0; index < allBots.value.length; index++) {
            try {
                loadings.bots[index] = true;

                const bot = allBots.value[index];
    
                const result = await BotsService.botUpdate({
                    record: bot,
                });
    
                if (result.postMessages) {
                    result.postMessages.forEach(({ success, msg }) => {
                        if (!Array.isArray(messages.value[index])) {
                            messages.value[index] = [{
                                type: success ? 'success' : 'error',
                                msg,
                            }];
                        } else {
                            messages.value[index].push({
                                type: success ? 'success' : 'error',
                                msg,
                            });
                        }

                        if (!success) {
                            hasError = true;
                        }
                    });
                };

                loadings.bots[index] = false;
            } catch {
                gl.showNotification({
                    type: 'error',
                    msg: t('errorMessage'),
                });

                loadings.bots[index] = false;
                loadings.button = false;
            };
        };

        loadings.button = false;

        if (!hasError) {
            context.emit('update:show', false);

            gl.showNotification({
                type: 'success',
                msg: bots.localization?.bot_settings_have_been_updated_successfully || 'Настройки ботов успешно обновлены',
            });
        }
    };

    return {
        gl,
        refs,
        bots,
        start,
        allBots,
        messages,
        loadings,
        dataForm,
        isAlgoLong,
        profitOptions,
        leverageMinMax,
        cycleUpOptions,
        rateCoverOptions,
        showDropdownRef,
        settingsOptions,
        visibleSettings,
        compMatrixMaxCount,
        firstOrderIndentOptions,
        logarithmicFactorOptions,
        onSave,
    };
}