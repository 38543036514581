<template>
<base-drawer-mobile
    :label="bots.localization?.['bot_create_button']"
    :show="show"
    @update:show="!$event ? $emit('update:show', false) : null">
    <template #default>
        <n-alert v-if="invalidConfig" class="rounded-md mb-4 text-xs" type="error">
            {{ bots.localization?.['Invalid_bot_configuration'] || 'Неверная конфигурация бота' }}
        </n-alert>
        <section class="border border-dashed border-gray-600 rounded-md flex flex-col items-center py-4 px-4" v-bind="getRootProps()">
            <section class="flex flex-col items-center">
                <n-icon
                    class="text-[38px] text-gray-400 dark:text-white/75 mb-4">
                    <DrawerArrowDownload20Regular />
                </n-icon>
                <input v-bind="getInputProps()" />
                <div v-if="file.info?.path" class="text-center text-gray-600 dark:text-white/75 text-sm">
                  {{ file.info.path }}
                </div>
            </section>
            
            <n-button
                secondary
                class="mt-4 rounded-md"
                @click.prevent.stop="open">
                + {{ bots.localization?.['choose_config_file'] || 'Выбрать файл конфигурации' }}
            </n-button>
        </section>

        <section v-if="loadings.main" class="py-8 flex justify-center">
            <n-spin size="tiny" />
        </section>
        
        <section v-if="init" class="py-4">
            <!-- name -->
            <rb-input
                id="name"
                class="mb-4"
                :label="dataForm.name.title"
                :help="dataForm.name.dataHelp"
                :status="!!bots.errorsForm?.name ? 'error' : undefined"
                :msg="bots.errorsForm?.name?.msg"
                :placeholder="dataForm.name.placeholder"
                v-model:value="dataForm.name.value"
                @update:value="delete bots.errorsForm.name" />
            <n-divider />

            <!-- api-key -->
            <rb-api-key-cr
                :only-create="false"
                :like-a-modal="false"
                :data="{
                    show_dashboard: true,
                }"
                :label="{
                    helpers: [{
                        type: 'info',
                        content: dataForm.api_key.dataHelp,
                    }]
                }"
                :exchange-id="dataForm.exchange"
                :api-keys-list="apiKeysRefs"
                v-model:api-key-value="dataForm.api_key.value"
                @addNewApiKey="setApiKey($event)">
            </rb-api-key-cr>
            <n-divider />

            <!-- wallet -->
            <bot-wallet
                id="wallet"
                :data="dataForm.wallet"
                :apiKeysRefs="apiKeysRefs"
                :coinSymbolForWallet="coinSymbolForWallet"
                :exchangeInfoRef="exchangeInfo"
                :exchangeRef="dataForm.exchange"
                :pairWalletsRefs="pairWalletsRefs"
                :api-key="dataForm.api_key.value"
                :status="!!bots.errorsForm?.wallet ? 'error' : undefined"
                :msg="bots.errorsForm?.wallet?.msg"
                v-model="dataForm.wallet.value"
                @addNewWallet="addNewWallet($event)"
                @updateWallet="updateWallet($event)"
                @update:modelValue="delete bots.errorsForm.wallet" />
        </section>
    </template>
    <template #footer>
        <n-button
            strong
            class="rounded-md text-white/90 uppercase w-full"
            :color="gl.mainColor"
            :loading="loadings.buttonCreate"
            :disabled="!canCreateBot || loadings.buttonCreate"
            @click="onApplyClicked">
            {{ bots.localization['bot_create_button'] }}
        </n-button>
    </template>
</base-drawer-mobile>
</template>

<script>
// general
import general from './general';

// icons
import { DrawerArrowDownload20Regular } from '@vicons/fluent';

// naive-ui
import {
    NAlert,
    NIcon,
    NCard,
    NSpin,
    NButton, 
    NDivider } from 'naive-ui';

// components
import RbApiKeyCr from '@components/rb-api-key-cr/mobile.vue';
import BotWallet from '@components/bot-wallet/mobile.vue';

export default {
    name: 'bot-json-copy-mobile',
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        NIcon,
        NCard,
        NSpin,
        NAlert,
        NButton,
        NDivider,
        BotWallet,
        RbApiKeyCr,
        DrawerArrowDownload20Regular,
    },
    setup(props, context) {

        return {
            ...general(...arguments),
        };
    },
};
</script>