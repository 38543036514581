<template>
<section class="px-3 min-h-screen">
    <n-card
        size="small"
        :bordered="false"
        class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg"
        :segmented="{
          content: true,
          footer: 'soft'
        }">
        <template #default>
            <div class="border border-dashed border-gray-600 rounded-md items-center pb-4">
                <div class="flex items-center px-3">
                    <Bots__running class="fill-current w-12" />
                    <div class="notes text-xs text-left text-gray-600 dark:text-white/75 pl-4" v-html="bots.localization['bots_how_to_create_bot']"></div>
                </div>
                <n-button
                    strong
                    size="small"
                    class="text-white/90 rounded-md text-[14px]"
                    :color="gl.mainColor"
                    @click="router.push({ name: 'bots.create' })">
                    + {{ bots.localization['bot_create_button'] }}
                </n-button>
                <section class="flex items-center justify-center my-3">
                    <div class="border-b border-gray-200 dark:border-gray-600 w-[16px]"></div>
                    <div class="text-gray-600 text-xs dark:text-white/75 px-2">{{ t('or') }}</div>
                    <div class="border-b border-gray-200 dark:border-gray-600 w-[16px]"></div>
                </section>
                <n-button
                    strong
                    size="small"
                    secondary
                    class="rounded-md text-xs"
                    @click="showCreateModalFormConfig = true">
                    <n-tag round size="tiny" type="warning" class="mr-2">
                        <span class="text-yellow-500 font-semibold text-xs">new</span>
                    </n-tag>
                    {{ bots.localization?.['choose_config_file'] || 'Выбрать файл конфигурации' }}
                </n-button>
            </div>
        </template>
    </n-card>
    <div v-if="!init" class="flex h-full justify-center items-center py-20">
        <n-spin size="small" />
    </div>
    <super-table
        v-else
        showSearch
        :filters="filters"
        :actions="actions"
        :refFilters="refFilters"
        :pager="bots.pager"
        :columns="columns"
        :records="bots.records"
        :loading="loadings.table"
        :filtersChanged="filtersChanged"
        columnsStorageName="__columns_bots"
        type="bots"
        @getData="getData"
        @doSomething="doSomething"
        @reset-filters="resetFilters"
        @applyFilters="changeFilter">
        <template #tableTitle>
            <div class="text-sm text-left mb-2 text-gray-600 dark:text-white/75">
                {{ bots.localization['available_bots_table_title'] }}
            </div>
        </template>
        <template #stats>
            <n-card
                size="small"
                :bordered="false"
                class="shadow bg-white dark:bg-darkCardBg rounded-md my-2"
                content-style="padding-bottom: 4px;"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">
                <div class="text-[10px] text-gray-600 dark:text-white/75 text-left flex flex-wrap">
                    <div class="flex items-center mr-4 mb-2">
                        <span class="whitespace-nowrap">{{ bots.localization['available_bots_total'] }}</span>
                        <n-tag round size="tiny" :bordered="false" type="warning" class="ml-2">
                            <span class="text-yellow-500 font-semibold">{{ bots.dataTable.stats.total }}</span>
                        </n-tag>
                    </div>

                    <div class="flex items-center mr-4 mb-2">
                        <span class="whitespace-nowrap">{{ bots.localization['available_bots_running'] }}</span>
                        <n-tag round size="tiny" :bordered="false" type="success" class="ml-2">
                            <span class="text-green-500 font-semibold">{{ bots.dataTable.stats.running }}</span>
                        </n-tag>
                    </div>
                    
                    <div class="flex items-center mr-4 mb-2">
                        <span class="whitespace-nowrap">{{ bots.localization['available_bots_stopped'] }}</span>
                        <n-tag round size="tiny" :bordered="false" class="ml-2">
                            <span class="font-semibold">{{ bots.dataTable.stats.stopped }}</span>
                        </n-tag>
                    </div>
                    
                    <div class="flex items-center mr-4 mb-2">
                        <span class="whitespace-nowrap">{{ bots.localization['available_bots_archived'] }}</span>
                        <n-tag round size="tiny" :bordered="false" type="info" class="ml-2">
                            <span class="text-blue-500 font-semibold">{{ bots.dataTable.stats.archived }}</span>
                        </n-tag>
                    </div>
                    
                    <div class="flex items-center mr-4 mb-2">
                        <span class="whitespace-nowrap">{{ bots.localization['available_bots_error'] }}</span>
                        <n-tag round size="tiny" :bordered="false" type="error" class="ml-2">
                            <span class="text-red-400 font-semibold">{{ bots.dataTable.stats.error }}</span>
                        </n-tag>
                    </div>
                </div>
            </n-card>
        </template>
        <template #actionButtons>
            <section v-if="bots.records.length" class="flex mt-4 mb-2">
                <n-button
                    strong
                    size="tiny"
                    class="text-white/90 rounded-md"
                    :color="gl.mainColor"
                    :disabled="!actionButtonsDisabled"
                    @click="letsShowConfirmation('start')">
                    {{ bots.localization['available_bots_table_mass_start_button'] }}
                </n-button>
                <n-button
                    strong
                    size="tiny"
                    class="ml-2 text-white/90 rounded-md"
                    :color="gl.mainColor"
                    :disabled="!actionButtonsDisabled"
                    @click="letsShowConfirmation('stop')">
                    {{ bots.localization['available_bots_table_mass_stop_button'] }}
                </n-button>
                <n-button
                    strong
                    size="tiny"
                    class="ml-2 text-white/90 rounded-md"
                    :color="gl.mainColor"
                    :disabled="!actionButtonsDisabled"
                    @click="letsShowConfirmation('pause')">
                    {{ bots.localization['available_bots_table_mass_pause_button'] }}
                </n-button>
                <n-button
                    strong
                    size="tiny"
                    class="ml-2 text-white/90 rounded-md"
                    :color="gl.mainColor"
                    :disabled="!actionButtonsDisabled"
                    @click="letsShowConfirmation('archive')">
                    {{ bots.localization['available_bots_table_mass_archive_button'] }}
                </n-button>
                <n-button
                    strong
                    size="tiny"
                    class="ml-2 text-white/90 rounded-md"
                    :color="gl.mainColor"
                    :disabled="!actionButtonsDisabled"
                    @click="letsShowConfirmation('delete')">
                    {{ bots.localization['available_bots_table_mass_delete_button'] }}
                </n-button>
            </section>
            <section class="flex items-center">
                <n-button
                    strong
                    secondary
                    class="rounded-md mt-2 w-full"
                    :disabled="checkedCountBots > 10 || checkedCountBots < 1 || showEditingNotMessages"
                    @click="openPackageEditing">
                    <n-tag round size="small" type="warning" class="mr-2">
                        <span class="text-yellow-500 font-semibold text-sm">new</span>
                    </n-tag>
                    {{ bots.localization?.['package_editing'] || 'Пакетное редактирование' }}
                </n-button>
                <n-popover
                    v-if="showEditingNotMessages"
                    trigger="click"
                    class="lg:max-w-[300px] max-w-[180px] lg:max-h-[400px] max-h-[180px] rounded-md overflow-auto bg-yellow-600"
                    placement="top">
                    <template #trigger>
                        <n-icon class="text-lg cursor-pointer text-yellow-600 ml-2" >
                            <Warning24Filled />
                        </n-icon>
                    </template>
                    <span
                        v-if="packageEditingNotLess"
                        class="text-[10px] lg:text-xs text-white" v-html="bots.localization?.package_editing_not_less || 'Выберите больше одного бота'">
                    </span>
                    <span
                        v-if="packageEditingNotMore"
                        class="text-[10px] lg:text-xs text-white" v-html="bots.localization?.package_editing_not_more || 'Вы не можете редактировать больше 10 ботов за раз'">
                    </span>
                    <span
                        v-if="packageEditingNotSameAlgo" class="text-[10px] lg:text-xs text-white"
                        v-html="bots.localization?.package_editing_not_same_algo || 'Вы не можете редактировать боты с разными алгоритмами'">
                    </span>
                </n-popover>
            </section>
        </template>
    </super-table>

    <base-dialog-mobile
        v-model:show="showConfirmation"
        @positive-click="showConfirmation = false, groupAction(actionType)"
        @negative-click="showConfirmation = false">
        <div class="text-md" v-html="refs.localization.confirmations.bot[actionType]"></div>
    </base-dialog-mobile>
    
    <bot-clone-more
        :botId="cloneMoreShowId"
        @update:bot-id="cloneMoreShowId = $event"
        :localization="bots.localization"
        @oneBotIsDone="oneBotIsDone($event)" />

    <bot-json-copy
        v-model:show="showCreateModalFormConfig"/>

    <bot-package-editing
        :checked-bots="checkedBots"
        v-model:show="showPackageEditing"/>
</section>

</template>

<script>
// general
import general from './general';

// components
import SuperTable from '@components/super-table/mobile.vue';
import Bots__running from '@components/svg/Bots__running.vue';
import BotCloneMore from '@components/bots/childs/bot-clone-more';
import BotJsonCopy from '@components/bots/childs/bot-json-copy';
import BotPackageEditing from '@components/bots/childs/bot-package-editing';

// icons
import {
    Warning24Filled } from '@vicons/fluent';

// naive-ui
import {
    NIcon,
    NTag,
    NCard,
    NSpin,
    NPopover,
    NButton } from 'naive-ui';

export default {
    name: 'bots-review-desktop',
    components: {
        NTag,
        NIcon,
        NCard,
        NSpin,
        NButton,
        NPopover,
        SuperTable,
        BotJsonCopy,
        BotCloneMore,
        Bots__running,
        Warning24Filled,
        BotPackageEditing,
    },
    setup(props, context) {
        
        return {
            ...general(arguments),
        };
    },
};
</script>