<template>
<n-button
    v-if="!botShortInfo?.simulate"
    class="mr-3 mb-3 text-white/90 rounded-md"
    :color="gl.mainColor"
    @click="showResetFixOrder">
    {{ stats.localization['trade_statistics_management_resetfixorder_button'] }}
</n-button>

<base-modal-desktop
    v-model:show="show"
    width="800px"
    :label="stats.localization['new_rate_for_finish_cycle_order']">
    <template #default>
        <div class="flex items-center text-gray-600 dark:text-white/75">
            {{ stats.localization['finish_cycle_order__rate_to_finish_cycle_with_0_profit'] }}
            <span
                class="text-main font-semibold underline hover:no-underline cursor-pointer pl-2"
                @click="onRateValueClicked(reSetFixOrder.rate_to_finish_cycle)">
                {{ reSetFixOrder.rate_to_finish_cycle }}
            </span>
        </div>
        <div class="flex text-sm items-center text-gray-600 dark:text-white/75">
            {{ stats.localization['finish_cycle_order__current_order_rate'] }}
            <span
                class="text-main font-semibold underline hover:no-underline cursor-pointer pl-2"
                @click="onRateValueClicked(reSetFixOrder.rate_to_get_profit_hint)">
                {{ reSetFixOrder.rate_to_get_profit_hint }}
            </span>
        </div>
        <n-divider class="mt-3" />

        <div class="flex items-center text-base">
            <!-- <div>{{ stats.localization['finish_cycle_order__calculate_rate_to_get'] }}</div> -->
            <rb-input
                type="percent"
                label-position="left"
                :label="stats.localization['finish_cycle_order__calculate_rate_to_get']"
                v-model:value="reSetFixOrder.rate_to_get_profit" />
            <span class="text-main font-bold px-2">%</span>
            <span class="whitespace-nowrap">{{ stats.localization['finish_cycle_order__profit'] }}</span>
            <n-button
                strong
                class="ml-4 text-white/90 rounded-md"
                :color="gl.mainColor"
                :disabled="isNaN(Number.parseFloat(reSetFixOrder.rate_to_get_profit, 10))"
                @click="onCalculateRateGetProfit">
                {{ t('calculate') }}
            </n-button>
        </div>
        <n-divider />

        <div class="flex items-center justify-start text-gray-600 dark:text-white/75">
            <span>{{ stats.localization['finish_cycle_order__current'] }}</span>
            <span class="mx-4 rb-td-stock-icon w-12" :class="`rb-td-stock-icon-${exchange(botShortInfo?.exchange)?.code_name}`"></span>
            <span>{{ stats.localization['finish_cycle_order__tickers_for'] }}</span>
            <div class="ml-4 flex items-center">
                <rb-coin class="w-6" :coin="currency(botShortInfo?.tickers_for?.split('/').shift().trim())" />
                <div class="ml-2">{{ botShortInfo?.tickers_for }}</div>
            </div>
            <span class="ml-4">{{ stats.localization['finish_cycle_order__buy'] }}:</span>
            <strong class="text-main font-bold underline hover:no-underline cursor-pointer ml-2" @click="onRateValueClicked(botShortInfo.buy)">&nbsp;{{ botShortInfo?.buy }}</strong>
            <span class="ml-4">{{ stats.localization['finish_cycle_order__sell'] }}:</span>
            <strong class="text-main font-bold underline hover:no-underline cursor-pointer ml-2" @click="onRateValueClicked(botShortInfo.sell)">&nbsp;{{ botShortInfo?.sell }}</strong>

            <n-button
                class="ml-4 text-white/90 rounded-md"
                :color="gl.mainColor"
                @click="showResetFixOrder">
                {{ stats.localization['finish_cycle_order__update'] }}
            </n-button>
        </div>
        <n-divider />

        <template v-if="calculateShow">
            <div class="flex items-center text-gray-600 dark:text-white/75">
                {{ stats.localization['finish_cycle_order__new_rate_with'] }}
                <span
                    class="text-main text-lg font-semibold px-2">
                    {{ reSetFixOrder.new_rate_to_get_profit_hint }}
                </span>
                {{ stats.localization['finish_cycle_order__profit'] }}
                <span
                    class="text-main text-lg font-semibold underline hover:no-underline cursor-pointer pl-2"
                    @click="onRateValueClicked(reSetFixOrder.new_rate_to_finish_cycle_suggest)">
                    {{ reSetFixOrder.new_rate_to_finish_cycle_suggest }}
                </span>
            </div>
            <n-divider />
        </template>

        <rb-input
            :label="stats.localization['finish_cycle_order__new_rate_to_finish_cycle']"
            label-position="left"
            v-model:value="reSetFixOrder.new_rate_to_finish_cycle" />
    </template>
    <template #footer>
        <div class="flex justify-end items-center">
            <rb-checkbox
                class="mr-4"
                :label="stats.localization['finish_cycle_order__stop_bot_after_finish_cycle_order_completed']"
                v-model:checked="reSetFixOrder.stop_bot_after_fix_completed" />
            <n-button
                :color="gl.mainColor"
                class="text-white/90 rounded-md"
                @click="doResetFixOrder"
                :disabled="isNaN(Number.parseFloat(reSetFixOrder.new_rate_to_finish_cycle, 10)) || reSetFixOrder.new_rate_to_finish_cycle < 0">
                {{ stats.localization['finish_cycle_order__reset_finish_cycle_order'] }}
            </n-button>
        </div>
    </template>
</base-modal-desktop>
</template>

<script>
// general
import general from './general';

// components
import RbInput from '@components/rb-input';
import RbCheckbox from '@components/rb-checkbox';
import BaseModal from '@components/base/base-modal';

// UI
import {
    NButton,
    NDivider } from 'naive-ui';

export default {
    name: 'reset-fix-order',
    props: {
        botShortInfo: {
            type: Object,
            required: true,
        },
    },
    components: {
        NButton,
        RbInput,
        NDivider,
        BaseModal,
        RbCheckbox,
    },
    setup(props, context) {
        
        return {
            ...general(props, context),
        };
    },
};
</script>