// vue
import { ref } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useStats } from '@store/stats';

// i18n
import { useI18n } from 'vue-i18n';

// services
import StatsService from '@services/statsService';

// router
import { useRoute } from 'vue-router';

// UI
import {
    useNotification } from 'naive-ui';

export default function (props, context) {
    // store
    const gl = useGl();
    const refs = useRefs();
    const stats = useStats();

    // naive-ui
    const notification = useNotification();

    // i18n
    const { t } = useI18n();

    // router
    const route = useRoute();

    // vars
    const show = ref(false);
    const calculateShow = ref(false);
    const RE_SET_FIX_ORDER_FACTORY = id => ({
        rate_to_finish_cycle: 0,
        rate_to_get_profit_hint: '',
        rate_to_get_profit: '',
        new_rate_to_finish_cycle: '',
        new_rate_to_get_profit_hint: '0%',
        new_rate_to_finish_cycle_suggest: 'Please, calculate',

        stop_bot_after_fix_completed: false,
    });
    const reSetFixOrder = ref(RE_SET_FIX_ORDER_FACTORY());

    const showResetFixOrder = async () => {
        gl.showLoading = true;

        try {
            const { id, rate_to_finish_cycle, rate_to_get_profit, status, errors } = ( await StatsService.getRateFinishCycle(route.params.id) ).data;

            // show messages
            if (!status) {
                errors.forEach(({ msg }) => {
                    gl.showNotification({
                        type: 'error',
                        msg,
                    });
                });
            } else {
                const data = { rate_to_finish_cycle, rate_to_get_profit_hint: rate_to_get_profit };
                reSetFixOrder.value = { ...reSetFixOrder.value, ...data };
                show.value = true;
            }
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        gl.showLoading = false;
    };

    const onCalculateRateGetProfit = async () => {
        gl.showLoading = true;

        const rate_to_get_profit = reSetFixOrder.value.rate_to_get_profit;
        const data = { _formPath: 'resetFixOrder', id: route.params.id, rate_to_get_profit };

        try {
            const recalcRateForProfit = ( await StatsService.recalcRateForProfit(data) ).data;

            reSetFixOrder.value = {...reSetFixOrder.value,
                new_rate_to_get_profit_hint: rate_to_get_profit + '%',
                new_rate_to_finish_cycle_suggest: recalcRateForProfit.new_rate_to_finish_cycle,
            };

            calculateShow.value = true;
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        gl.showLoading = false;
    };

    const doResetFixOrder = async () => {
        gl.showLoading = true;

        const { new_rate_to_finish_cycle, stop_bot_after_fix_completed } = reSetFixOrder.value;
        const payload = { _formPath: 'resetFixOrder', id: route.params.id, new_rate_to_finish_cycle, stop_bot_after_fix_completed };

        try {
            const prepare = await StatsService.resetFixOrder(payload);

            if (prepare) {
                // show messages
                if (prepare.postMessages)
                    prepare.postMessages.forEach(el => {
                        gl.showNotification({
                            type: el.success ? 'success' : 'error',
                            msg: el.msg,
                        });
                    });
              
                // show messages
                if (prepare.data.errors)
                    prepare.data.errors.forEach(el => {
                        gl.showNotification({
                            type: 'error',
                            msg: t('errorMessage'),
                        });
                    });
            }

            if (prepare.data.status) {
                context.emit('getData');
            } else {
                gl.showLoading = false;
            }

            show.value = false;
          
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
            gl.showLoading = false;
        };
    };

    const onRateValueClicked = new_rate_to_finish_cycle => {
        reSetFixOrder.value = { ...reSetFixOrder.value, new_rate_to_finish_cycle };
    };

    return {
        gl,
        show,
        stats,
        calculateShow,
        reSetFixOrder,
        t,
        doResetFixOrder,
        showResetFixOrder,
        onRateValueClicked,
        onCalculateRateGetProfit,
        currency: icon => refs.currencies.find(el => el.id === icon),
        exchange: exchange => refs.exchanges.find(el => el.id === exchange),
    };
}