<template>
<n-drawer
    v-model:show="gl.showTopUpYourBalance"
    width="100%"
    class="bg-gray-100 dark:bg-darkbg">
    <n-drawer-content body-content-style="padding: 0 12px;">
        <div class="flex flex-col h-full">
            <div class="relative w-full bg-gray-100 dark:bg-darkbg text-sm text-center text-gray-600 dark:text-white/75 py-4">
                <n-icon
                    size="20"
                    class="text-gray-600 dark:text-white/75 absolute top-1/2 left-0 -translate-y-1/2"
                    @click="gl.showTopUpYourBalance = false">
                    <ArrowLeft12Regular />
                </n-icon>
                {{ refs.localization.topUpBalance.dialog_title }}
            </div>
            <div class="pb-4 flex-grow overflow-y-scroll overscroll-none">
                <rb-currency-select />
            </div>
        </div>
    </n-drawer-content>
</n-drawer>
<n-drawer
    :show="showStage"
    width="100%"
    class="bg-gray-100 dark:bg-darkbg">
    <n-drawer-content v-if="gl.stage" body-content-style="padding: 0 12px;">
        <div class="flex flex-col h-full">
            <div class="relative w-full bg-gray-100 dark:bg-darkbg text-sm text-center text-gray-600 dark:text-white/75 py-4">
                {{ gl.stage.caption }}
            </div>
            
            <div class="pb-4 flex-grow overflow-y-scroll overscroll-none scrollbar-hide">
                <n-alert class="bg-[#eaf0fe] dark:bg-blue-900 dark:bg-opacity-20 rounded-md mb-2 mt-3" type="info">
                    <div class="text-xs">{{ gl.stage.message }}</div>
                </n-alert>
                <template
                    v-for="(field, key) in gl.stage.fields" :key="field.title+key">
                    <rb-input
                        size="large"
                        :status="gl.stageErrors[key] ? 'error' : undefined"
                        :msg="gl.stageErrors[key]?.msg"
                        :label="field.title"
                        :placeholder="field.placeholder"
                        v-model:value="field.value"
                        @update:value="gl.cleanError('stageErrors', key)" />
                </template>
                <div class="w-full flex justify-between mt-3">
                    <div class="w-6/12 pr-1">
                        <n-button
                            v-if="!!gl.stage.nahLabel"
                            secondary
                            size="large"
                            class="w-full rounded-md flex items-center text-gray-600 dark:text-white/75"
                            :disabled="loading"
                            :loading="loading"
                            @click="onCancelStagedResponse">
                            {{ gl.stage.nahLabel }}
                        </n-button>
                    </div>
                    <div class="w-6/12 pl-1">
                        <n-button
                            secondary
                            size="large"
                            class="w-full rounded-md flex items-center text-main dark:text-white/75 dark:bg-main bg-white"
                            :disabled="loading"
                            :loading="loading"
                            @click="onVerifyStagedResponse">
                            {{ gl.stage.yepLabel }}
                        </n-button>
                    </div>
                </div>
            </div>
        </div>
    </n-drawer-content>
</n-drawer>
<base-modal-mobile
    v-if="!!gl.new_build"
    :show="true"
    :maskClosable="false"
    :showCLoseButton="false">
    <template #headerContent>
        <div class="flex items-center text-sm">
            <n-spin size="small" />
            <div class="ml-4">
                <span v-html="gl.new_build?.message"></span>
                <span>{{ gl.new_build?.autorefresh_in }}</span>
            </div>
        </div>
    </template>
</base-modal-mobile>
<!-- <base-dialog-mobile
    :show="confirm.show"
    @positive-click="confirm.doFn"
    @update:show="confirm.closeConfirm"
    @negative-click="confirm.closeConfirm">
    <div class="text-md" v-html="confirm.title"></div>
</base-dialog-mobile> -->
<base-dialog-mobile
    :show="confirm.show"
    :disabled="confirm.showCheck && confirm.disabledByChecked ? !confirm.genConfCheck : false"
    @positive-click="confirm.doFn"
    @update:show="confirm.closeConfirm"
    @negative-click="confirm.closeConfirm">
    <div v-if="confirm.title" class="text-sm text-gray-500 dark:text-white/75" v-html="confirm.title"></div>
    <div v-if="confirm.declineMessage" class="text-xs text-gray-500 dark:text-white/75" v-html="confirm.declineMessage"></div>
    <template #footerCheck v-if="confirm.showCheck">
        <rb-checkbox
            size="small"
            class="mb-3"
            :nowrap="false"
            :label="confirm.confirmLabel"
            v-model:checked="confirm.genConfCheck" />
    </template>
</base-dialog-mobile>
</template>

<script>
// vue
import { ref, watch, onMounted } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useConfirm } from '@store/confirm';

// services
import Twostage from '@services/twostage';

// components
import RbInput from '@components/rb-input/mobile.vue';
import RbCurrencySelect from '@components/rb-currency-select/mobile.vue';

// naive-ui
import {
    NSpin,
    NIcon,
    NAlert,
    NButton,
    NDrawer,
    NDrawerContent,
    useNotification } from 'naive-ui';

// icons
import { ArrowLeft12Regular }  from '@vicons/fluent';

export default {
    name: 'root-component-mobile',
    components: {
        NSpin,
        NIcon,
        NAlert,
        NDrawer,
        NButton,
        RbInput,
        NDrawerContent,
        RbCurrencySelect,
        ArrowLeft12Regular,
    },
    setup() {
        // store
        const gl = useGl();
        const refs = useRefs();
        const confirm = useConfirm();

        // vars
        const { _ } = window;
        const showStage = ref();
        const loading = ref(false);

        watch(() => gl.stage, v => {
            if (v) showStage.value = true;
        });

        const onCancelStagedResponse = async () => {
            loading.value = true;
            await Twostage.cancel({
                fields: _.get(gl.stage, 'fields', {}),
                extra: _.get(gl.stage, 'extra', {}),
                _formPath: 'GSR',
            });

            gl.stage = null;
            gl.stageState = 'canceled';

            // show messages
            // data.postMessages.forEach(el => {
            //     gl.showNotification({
            //     type: el.success ? 'success' : 'error',
            //     msg: el.msg,
            // });
            // });

            showStage.value = false;

            loading.value = false;
        };

        const onVerifyStagedResponse = async () => {
            loading.value = true;
            const data = await Twostage.execute({
                fields: _.get(gl.stage, 'fields', {}),
                extra: _.get(gl.stage, 'extra', {}),
                _formPath: 'GSR',
            });

            if (!data.data.status) {
                gl.stageErrors = data.data.errors_form['GSR'].fields;
            } else {
                // show messages
                // data.data.messages.forEach(el => {
                //     message.success(el.msg);
                // });

                gl.stageExtra = data.data;
                gl.stageState = 'inactive';

                showStage.value = false;
            }

            loading.value = false;
        };

        onMounted(() => {
            gl.notification = useNotification();
        });

        return {
            gl,
            refs,
            confirm,
            loading,
            showStage,
            onCancelStagedResponse,
            onVerifyStagedResponse,
        };
    },
};
</script>