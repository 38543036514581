// vue
import { ref, computed } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';

export default function (props, context) {
    // store
    const gl = useGl();
    const refs = useRefs();

    // vars
    const show = ref(false);

    const currency = icon => refs.currencies.find(el => el.id === icon);
    const options = computed(() => ({
        'autosize': true,
        'symbol': `${props.twData?.tradingview_name}:${props.twData?.pair}${props.twData?.futures ? '.P' : ''}`,
        'interval': 'D',
        'timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'theme': gl.darkTheme ? 'dark' : 'light',
        'style': '1',
        'locale': refs.lang,
        'withdateranges': true,
        'allow_symbol_change': true,
        'calendar': false,
        // 'backgroundColor': gl.darkTheme ? '#141414' : '',
        // 'gridColor': 'rgba(255, 0, 0, 1)',
        'support_host': 'https://www.tradingview.com',
    }));

    return {
        gl,
        refs,
        show,
        options,
        currency,
    };
}