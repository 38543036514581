<template>
<div class="text-left">
    <rb-coin-list
        :nowrap="false"
        :label="bots.localization['bot_auto_switch_type_user_list_info_f']"
        :options="marketsPoolOptions"
        @update:value="onSymbolSelected" />
    <div class="mt-4">
        <div class="text-xs text-gray-600 dark:text-white/75 font-semibold mb-4 sto-descr" v-html="bots.localization['bot_auto_switch_type_user_list_currencies_list_info']"></div>
        <draggable
            :list="dataForm.switch_tp.user_list"
            item-key="name"
            ghost-class="ghost"
            @start="dragging = true"
            @end="dragging = false">
            <template #item="{ element }">
            <div
                v-if="findCurrency(element)"
                class="flex items-center cursor-pointer hover:bg-main hover:text-white px-2 text-base dark:odd:bg-darkbg odd:bg-gray-300 odd:bg-opacity-40 first:rounded-t-md last:rounded-b-md"
                @click="onStoItemClicked(element)">
                <rb-coin class="mr-2 w-4" :coin="currency(element)" />
                <div class="ml-4 text-xs">{{ findCurrency(element)?.title }}</div>
            </div>
            </template>
        </draggable>
    </div>
</div>
</template>

<script>
// vue
import { ref, reactive, computed } from 'vue';

// dragable
import draggable from 'vuedraggable';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useBots } from '@/store/bots';

// i18n
import { useI18n } from 'vue-i18n';

// components
import RbCoin from '@components/rb-coin';
import RbCoinList from '@components/rb-coin-list';

export default {
    name: 'rb-switch-tokens-order-mobile',
    props: {
        symbolsForMarketRefs: {
            type: Object,
            required: true,
        },
    },
    components: {
        RbCoin,
        draggable,
        RbCoinList,
    },
    setup(props, { emit }) {
    // store
        const gl = useGl();
        const bots = useBots();
        const refs = useRefs();

        // lodash
        const { _ } = window;

        // vars
        const dataForm = bots.dataForm;
        const dragging = ref(false);

        const marketsPoolOptions = computed(() =>
            Object.values(_.pick(props.symbolsForMarketRefs,
                _.difference(
                    _.keys(props.symbolsForMarketRefs),
                    _.get(dataForm, 'switch_tp.user_list', []),
                ))).map(el => ({
                label: el.title,
                value: el.id,
                ...el,
            })));

        const onSymbolSelected = $event => {
            console.log('onSymbolSelected', $event);
            if ($event == -1) return;
            dataForm.switch_tp.user_list.push($event);
            reorderUserList(true);
        };

        const reorderUserList = isLast => {
            const pairSymbols = String(dataForm.pair).split('/');
            if (!Array.isArray(pairSymbols) || pairSymbols.length !== 2) return;
            const firstPairSymbol = pairSymbols[0];
            let userList = [...new Set(dataForm.switch_tp.user_list)];
            const firstPairSymbolIdx = userList.indexOf(firstPairSymbol);
            if (firstPairSymbolIdx !== -1) {
                if (isLast) {
                    userList.splice(firstPairSymbolIdx, 1);
                    userList.push(firstPairSymbol);
                }
            } else {
                userList.push(firstPairSymbol);
            }
        };

        const onStoItemClicked = symbol => {
            dataForm.switch_tp.user_list.splice(dataForm.switch_tp.user_list.indexOf(symbol), 1);
        };

        const findCurrency = i => Object.values(props.symbolsForMarketRefs).find(({ id }) => id === i);

        return {
            gl,
            bots,
            refs,
            dataForm,
            dragging,
            marketsPoolOptions,
            currency: icon => refs.currencies.find(el => el.id === icon),
            findCurrency,
            onStoItemClicked,
            onSymbolSelected,
        };
    },
};
</script>

<style lang="scss">
.sto-descr--rm {
  font-weight: bold;
  color: #df1818;
}
.sto-descr--dd {
  font-weight: bold;
  color: #5abc19;
}
</style>