<template>
<div :class="labelPosition === 'left' ? 'flex justify-between items-center' : ''">
    <div class="flex mt-2" :class="labelPosition === 'left' ? 'w-2/12' : ''">
        <rb-label
            :label="label"
            :helpers="
            [{
                type: 'info',
                content: dataHelp,
            }]"
        />
    </div>
    <n-button
        tertiary
        size="large"
        :loading="loading"
        :disabled="loading || disabled"
        class="select-button w-full rounded-md pl-2 pr-2"
        :class="label ? 'mt-2' : ''"
        @click="showDrawer = true">
        <div class="w-full flex justify-between items-center">
            <div
                v-if="value"
                class="flex items-center w-[calc(100%-24px)] overflow-x-auto scrollbar-hide">
                <template v-if="multiple && currentValue?.length">
                    <div
                        v-for="checked in currentValue"
                        :key="checked"
                        class="flex items-center rounded-md h-full bg-main bg-opacity-20 border-opacity-50 border border-main p-1 mr-2">
                        <rb-coin class="w-4 h-4 mr-2" :coin="currency(checked.value)"/>
                        {{ checked.label }} 
                    </div>
                </template>
                <template v-else-if="!multiple && currentValue">
                    <div
                        class="flex items-center h-full">
                        <rb-coin class="w-5 h-5 mr-2" :coin="currency(currentValue.value)"/>
                        {{ currentValue.label }} 
                    </div>
                </template>
                <template v-else>
                    <span class="text-gray-600 dark:text-white/75 text-xs pl-2">{{ t('select') }}</span>
                </template>
            </div>
            <span v-else
                class="text-gray-600/50 dark:text-white/40">{{ t('select') }}</span>
            <n-icon size="18"><CaretDown12Filled /></n-icon>
        </div>
    </n-button>
    <section class="flex w-full mt-4">
        <n-button
            secondary
            size="tiny"
            class="mr-2 rounded-md flex-grow"
            type="tertiary"
            :disabled="!value?.length"
            @click="letsCopyList">
            <n-icon class="cursor-pointer mr-1 text-base">
                <Copy16Regular />
            </n-icon>
            {{ refs.localization?.confirmations?.currencys?.lets_copy_list || 'Скопировать список' }}
        </n-button>
        <n-button
            secondary
            size="tiny"
            class="ml-1 rounded-md flex-grow"
            type="tertiary"
            @click="letsPasteList">
            <n-icon class="cursor-pointer mr-1 text-base">
                <ClipboardPaste16Filled />
            </n-icon>
            {{ refs.localization?.confirmations?.currencys?.lets_paste_list || 'Вставить список' }}
        </n-button>
    </section>
    <n-drawer
        v-model:show="showDrawer"
        width="100%"
        display-directive="show"
        class="bg-gray-100 dark:bg-darkbg"
        :auto-focus="false">
        <n-drawer-content body-content-style="padding: 0">
            <div class="flex flex-col h-full">
                <div class="w-full flex justify-between px-3 items-center bg-gray-100 dark:bg-darkbg text-sm text-center text-gray-600 dark:text-white/75 py-2">
                    <n-icon
                        size="20"
                        class="text-gray-600 dark:text-white/75"
                        @click="showDrawer = false">
                        <ArrowLeft12Regular />
                    </n-icon>
                    <!-- <rb-input v-if="showSearch" v-model:value="query" class="ml-3" /> -->
                    <n-button
                        quaternary
                        class="bg-transparent text-main pr-0"
                        @click="showDrawer = false">
                        {{ t('done') }}
                    </n-button>
                </div>
                <div class="pb-4 flex-grow overflow-y-scroll overscroll-none scrollbar-hide">
                    <template
                        v-for="(item, i) in options"
                        :key="i">
                        <div
                            v-if="item"
                            class="w-full flex justify-between items-center pl-6 pr-3 py-2 rounded-none dark:odd:bg-[#242424] odd:bg-gray-50"
                            @click="setValue(item)">
                            <div class="flex-grow flex items-center">
                                <rb-coin class="w-4 h-4 mr-2" :coin="currency(item.value)"/>
                                {{ item.label }}
                            </div>
                            <n-icon v-if="isChecked(item)" size="20" class="text-main">
                                <Checkmark12Filled />
                            </n-icon>
                        </div>
                    </template>
                </div>
            </div>
        </n-drawer-content>
    </n-drawer>
    <slot name="messages"></slot>
  </div>
</template>

<script>
// general
import general from './general';

// components
import RbCoin from '@components/rb-coin';

// icons
import { ArrowLeft12Regular, CaretDown12Filled, Checkmark12Filled, Copy16Regular, ClipboardPaste16Filled } from '@vicons/fluent';

// naive-ui
import {
    NIcon,
    NSelect,
    NButton,
    NDrawer,
    NPopover,
    NDrawerContent } from 'naive-ui';

export default {
    name: 'rb-coin-list-mobile',
    emits: ['update:value'],
    props: {
        label: {
            type: String,
            required: true,
        },
        labelPosition: {
            type: String,
            default: 'top',
            validator: prop => ['right', 'top'].includes(prop),
        },
        dataHelp: {
            type: String,
        },
        filterable: {
            type: Boolean,
            default: true,
        },
        options: {
            type: Array,
            required: true,
        },
        loading: {
            type: Boolean,
        },
        value: {
            type: [String, Number],
            required: true,
            default: null,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
        },
        nowrap: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        NIcon,
        RbCoin,
        NDrawer,
        NButton,
        NSelect,
        NPopover,
        NDrawerContent,
        CaretDown12Filled,
        Checkmark12Filled,
        ArrowLeft12Regular,
        Copy16Regular,
        ClipboardPaste16Filled,
    },
    setup(props, context) {
        
        return {
            ...general(...arguments),
        };
    },
};
</script>

<style lang="scss" scoped>
.select-button :deep(.n-button__content) {
    width: 100%;
}
</style>