<template>
<n-button
    v-if="!botShortInfo?.simulate"
    size="small"
    class="mr-3 mb-3 text-white/90 rounded-md"
    :color="gl.mainColor"
    @click="showResetFixOrder">
    {{ stats.localization['trade_statistics_management_resetfixorder_button'] }}
</n-button>

<base-drawer-mobile
    :label="stats.localization['new_rate_for_finish_cycle_order']"
    v-model:show="show">
    <template #default>
        <div class="flex items-center text-gray-600 dark:text-white/75 text-xs">
            {{ stats.localization['finish_cycle_order__rate_to_finish_cycle_with_0_profit'] }}&nbsp;
            <span
                class="text-main font-semibold underline hover:no-underline cursor-pointer"
                @click="onRateValueClicked(reSetFixOrder.rate_to_finish_cycle)">
                {{ reSetFixOrder.rate_to_finish_cycle }}
            </span>
        </div>
        <div class="flex items-center text-gray-600 dark:text-white/75 text-xs mt-2">
            {{ stats.localization['finish_cycle_order__current_order_rate'] }}&nbsp;
            <span
                class="text-main font-semibold underline hover:no-underline cursor-pointer"
                @click="onRateValueClicked(reSetFixOrder.rate_to_get_profit_hint)">
                {{ reSetFixOrder.rate_to_get_profit_hint }}
            </span>
        </div>
        <n-divider class="mt-3" />

        <div class="flex items-end">
            <!-- <div>{{ stats.localization['finish_cycle_order__calculate_rate_to_get'] }}</div> -->
            <rb-input
                type="percent"
                :label="stats.localization['finish_cycle_order__calculate_rate_to_get']"
                v-model:value="reSetFixOrder.rate_to_get_profit" />
            <span class="text-main font-bold px-2 pb-1">%</span>
            <span class="whitespace-nowrap pb-1">{{ stats.localization['finish_cycle_order__profit'] }}</span>
            <n-button
                class="ml-2 text-white/90 rounded-md"
                :color="gl.mainColor"
                :disabled="isNaN(Number.parseFloat(reSetFixOrder.rate_to_get_profit, 10))"
                @click="onCalculateRateGetProfit">
                {{ t('calculate') }}
            </n-button>
        </div>
        <n-divider class="mb-2" />

        <div class="inline-flex items-center justify-start text-gray-600 dark:text-white/75 text-xs whitespace-nowrap">
            <span>{{ stats.localization['finish_cycle_order__current'] }}&nbsp;</span>
            <span class="rb-td-stock-icon w-12 mx-2" :class="`rb-td-stock-icon-${exchange(botShortInfo?.exchange)?.code_name}`"></span>
            <span>{{ stats.localization['finish_cycle_order__tickers_for'] }}</span>
            <div class="flex items-center">
                <rb-coin class="w-6" :coin="currency(botShortInfo?.tickers_for?.split('/').shift().trim())" />
                <div class="ml-2">{{ botShortInfo?.tickers_for }}</div>
            </div>
        </div>
        <div class="flex items-center justify-between text-gray-600 dark:text-white/75 text-xs whitespace-nowrap">
            <span>{{ stats.localization['finish_cycle_order__buy'] }}:</span>
            <strong class="text-main font-bold text-base underline" @click="onRateValueClicked(botShortInfo.buy)">&nbsp;{{ botShortInfo?.buy }}</strong>
            <span>{{ stats.localization['finish_cycle_order__sell'] }}:</span>
            <strong class="text-main font-bold text-base underline" @click="onRateValueClicked(botShortInfo.sell)">&nbsp;{{ botShortInfo?.sell }}</strong>

            <n-button
                size="small"
                class="text-white/90 rounded-md"
                :color="gl.mainColor"
                @click="showResetFixOrder">
                {{ stats.localization['finish_cycle_order__update'] }}
            </n-button>
        </div>

        <n-divider />

        <template v-if="calculateShow">
            <div class="text-gray-600 dark:text-white/75 text-xs">
                {{ stats.localization['finish_cycle_order__new_rate_with'] }}&nbsp;
                <span
                    class="text-main text-base font-semibold">
                    {{ reSetFixOrder.new_rate_to_get_profit_hint }} 
                </span>
                {{ stats.localization['finish_cycle_order__profit'] }}&nbsp;
                <span
                    class="text-main text-base font-semibold underline hover:no-underline cursor-pointer"
                    @click="onRateValueClicked(reSetFixOrder.new_rate_to_finish_cycle_suggest)">
                    {{ reSetFixOrder.new_rate_to_finish_cycle_suggest }}
                </span>
            </div>
            <n-divider />
        </template>

        <rb-input
            :label="stats.localization['finish_cycle_order__new_rate_to_finish_cycle']"
            v-model:value="reSetFixOrder.new_rate_to_finish_cycle" />
    </template>
    <template #footer>
        <rb-checkbox
            class="mb-4"
            :label="stats.localization['finish_cycle_order__stop_bot_after_finish_cycle_order_completed']"
            v-model:checked="reSetFixOrder.stop_bot_after_fix_completed" />
        <n-button
            :color="gl.mainColor"
            class="text-white/90 rounded-md w-full"
            @click="doResetFixOrder"
            :disabled="isNaN(Number.parseFloat(reSetFixOrder.new_rate_to_finish_cycle, 10)) || reSetFixOrder.new_rate_to_finish_cycle < 0">
            {{ stats.localization['finish_cycle_order__reset_finish_cycle_order'] }}
        </n-button>
    </template>
</base-drawer-mobile>
</template>

<script>
// general
import general from './general';

// components
import RbInput from '@components/rb-input/mobile.vue';
import RbCheckbox from '@components/rb-checkbox/mobile.vue';

// UI
import {
    NButton,
    NDivider } from 'naive-ui';

export default {
    name: 'reset-fix-order-mobile',
    props: {
        botShortInfo: {
            type: Object,
            required: true,
        },
    },
    components: {
        NButton,
        RbInput,
        NDivider,
        RbCheckbox,
    },
    setup(props, context) {
        
        return {
            ...general(props, context),
        };
    },
};
</script>