// vue
import { h, ref, reactive, computed, watch, onMounted, onBeforeUnmount } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useBots } from '@store/bots';
import { useConfirm } from '@store/confirm';

// i18n
import { useI18n } from 'vue-i18n';

// router
import { useRouter } from 'vue-router';

// shared
import { CRUD_PAGER_FACTORY } from '@shared/factories';

// naive-ui
import { 
    NCheckbox,
    useNotification } from 'naive-ui';

// icons
import {
    Edit20Filled,
    Play16Regular,
    Stop20Regular,
    CopyAdd24Regular,
    RecordStop28Regular,
    ArrowSync12Regular,
    CopySelect20Filled,
    ArrowDownload16Filled,
    PauseCircle24Regular,
    DeleteDismiss24Regular,
    ArchiveMultiple16Filled,
    ArrowTrendingLines20Filled } from '@vicons/fluent';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const download = require('downloadjs');

// services
import BotsService from '@services/bots';
import LocalStorage from '@services/ts/localStorage';

export default function (props, context) {
    // store
    const gl = useGl();
    const refs = useRefs();
    const bots = useBots();
    const confirm = useConfirm();

    // vars
    const { _ } = window;
    const init = ref(false);

    // i18n
    const { t } = useI18n();

    // naive-ui
    const notification = useNotification();

    // router
    const router = useRouter();

    // vars
    const actionType = ref();
    const loading = ref(false);
    const showCreateModalFormConfig = ref(false);
    const showConfirmation = ref(false);
    const cloneMoreShowId = ref();
    const showPackageEditing = ref(false);
    const loadings = reactive({
        table: false,
    });

    // storage filters
    const storeFilterKey = '___filters_bots';
    let storeFilters = LocalStorage.getItem(storeFilterKey);

    // set filter settings
    if (storeFilters == null) {
        LocalStorage.setItem(storeFilterKey, CRUD_PAGER_FACTORY({
            statuses: [ -1 ],
            states: [ -1 ],
            exchanges: [ -1 ],
            wallets: [ -1 ],
            apikeys: [ -1 ],
            algos: [ -1 ],
            matrix: [ -1 ],
            presets: [ -1 ],
            tags: [ -1 ],
            include_profit: false,
        }));

        storeFilters = LocalStorage.getItem(storeFilterKey);
    }

    // filters
    const filters = reactive({
        ...storeFilters,
    });

    const filtersChanged = computed(() => {
        return filters.filters.statuses[0] !== -1
          || filters.filters.states[0] !== -1
          || filters.filters.exchanges[0] !== -1
          || filters.filters.wallets[0] !== -1
          || filters.filters.apikeys[0] !== -1
          || filters.filters.algos[0] !== -1
          || filters.filters.matrix[0] !== -1
          || filters.filters.presets[0] !== -1
          || filters.filters.tags[0] !== -1
          || filters.filters.include_profit !== false;
    });

    const actions = computed(() => [
        {
            id: 'bots__start',
            icon: row => Play16Regular,
            fn: row => canDoAction('start', row),
            popover: row => bots.localization?.['available_bots_table_mass_start_button'],
        }, {
            id: 'bots__stop',
            icon: row => Stop20Regular,
            fn: row => canDoAction('stop', row),
            popover: row => bots.localization?.['available_bots_table_mass_stop_button'],
        }, {
            id: 'bots__pause',
            icon: row => PauseCircle24Regular,
            fn: row => canDoAction('pause', row),
            popover: row => bots.localization?.['available_bots_table_mass_pause_button'],
        }, {
            id: 'bots__restart',
            icon: row => ArrowSync12Regular,
            fn: row => canDoAction('restart', row),
            popover: row => bots.localization?.['available_bots_table_mass_restart_button'],
        }, {
            id: 'bots__edit',
            icon: row => Edit20Filled,
            fn: row => router.push({ name: 'bots.edit', params: { id: row.id } }),
            popover: row => bots.localization?.['available_bots_table_mass_edit'],
        }, {
            id: 'bots__stats',
            icon: row => ArrowTrendingLines20Filled,
            fn: row => router.push({ name: 'trade-statistic.management', params: { id: row.id } }),
            popover: row => bots.localization?.['available_bots_table_mass_statistics'],
        }, {
            id: 'bots__clone',
            icon: row => CopyAdd24Regular,
            fn: row => router.push({ name: 'bots.clone', params: { id: row.id } }),
            popover: row => bots.localization?.['available_bots_table_mass_clone'],
        }, {
            id: 'bots__clone_more',
            icon: row => CopySelect20Filled,
            fn: row => cloneMoreShowId.value = row.id,
            popover: row => bots.localization?.['available_bots_table_mass_more_clone'] || 'Клонировать несколько ботов',
            tag: {
                type: 'warning',
                text: 'new',
            },
        }, {
            id: 'bots__download_config',
            icon: row => ArrowDownload16Filled,
            fn: row => downloadConfig(row.id),
            popover: row => bots.localization?.['download_config'] || 'Скачать конфигурацию',
            tag: {
                type: 'warning',
                text: 'new',
            },
        }, {
            id: 'bots__archive',
            icon: row => ArchiveMultiple16Filled,
            fn: row => row.status == -5 ? canDoAction('unarchive', row) : canDoAction('archive', row),
            popover: row => row.status == -5
                ? bots.localization?.['available_bots_table_unarchive']
                : bots.localization?.['available_bots_table_archive'],
        }, {
            id: 'bots__force_stop',
            icon: row => RecordStop28Regular,
            fn: row => canDoAction('force_stop', row),
            popover: row => bots.localization?.['bots_available_bots_table_mass_force_stop'],
        }, {
            id: 'bots__delete',
            textType: 'error',
            icon: row => DeleteDismiss24Regular,
            fn: row => canDoAction('delete', row),
            popover: row => bots.localization?.['available_bots_table_mass_delete_button'],
        },
    ]);

    const checkedBots = computed(() => bots.dataTable?.records.filter(({ checked }) => checked));
    const checkedCountBots = computed(() => checkedBots.value.length);
    const packageEditingNotMore = computed(() => checkedCountBots.value > 10);
    const packageEditingNotLess = computed(() => checkedCountBots.value == 1);
    const packageEditingNotSameAlgo = computed(() => {
        const algo = checkedBots.value[0]?.algo;

        return algo ? !!~checkedBots.value.findIndex(el => el.algo != algo) : false;
    });
    const showEditingNotMessages = computed(() => packageEditingNotMore.value || packageEditingNotSameAlgo.value || packageEditingNotLess.value);
    const canPackageEditing = computed(() => !showEditingNotMessages.value);

    const columns = computed(() => {
        if (bots.columns) {
            const arr = [...bots.columns].filter(({ title }) => title);

            arr.unshift({
                key: 'checked',
                name: 'checked',
                isHidden: false,
                title() {
                    return h(NCheckbox, {
                        checked: bots.records.every(({ checked }) => checked),
                        onUpdateChecked(v) {
                            bots.records.forEach(el => el.checked = v);
                        },
                    });
                },
            });

            return arr;
        } else return [];
    });

    const refFilters = computed(() => [
        {
            mode: 'exchanges',
            title: refs.localization.filters.bots.filters_bots_exchanges_label,
            refs: refs.exchanges,
            filter: 'filters.exchanges',
            seeable: filters.filters.exchanges[0] !== -1,
        }, {
            mode: 'checkbox_group',
            title: refs.localization.filters.bots.filters_bots_algos_label,
            refs: bots.algos,
            filter: 'filters.algos',
            seeable: filters.filters.algos[0] !== -1,
        }, {
            mode: 'checkbox_group',
            title: refs.localization.filters.bots.filters_bots_order_grids_label,
            refs: bots.ordersMatrix,
            filter: 'filters.matrix',
            seeable: filters.filters.matrix[0] !== -1,
        }, {
            mode: 'checkbox_group',
            title: refs.localization.filters.bots.filters_bots_statuses_label,
            refs: refs.botStatuses,
            filter: 'filters.statuses',
            seeable: filters.filters.statuses[0] !== -1,
        }, {
            mode: 'checkbox_group',
            title: refs.localization.filters.bots.filters_bots_states_label,
            refs: refs.botStates,
            filter: 'filters.states',
            seeable: filters.filters.states[0] !== -1,
        }, {
            mode: 'checkbox_group',
            title: refs.localization.filters.bots.filters_bots_taxonomy_label,
            refs: bots.tags,
            filter: 'filters.tags',
            seeable: filters.filters.tags[0] !== -1,
        }, {
            mode: 'filter_switch',
            value: filters.filters.include_profit,
            title: refs.localization.filters.bots.filters_bots_show_profit_commissions,
            filter: 'filters.include_profit',
            seeable: filters.filters.include_profit !== false,
        },
    ]);

    const actionButtonsDisabled = computed(() => bots.dataTable.records ? bots.dataTable.records.some(({ checked }) => checked) : false);

    const canDoAction = async (curAction, row) => {
        gl.showLoading = true;

        if (curAction === 'start') {
            try {
                const result = await BotsService.canDoAction({
                    id: row.id,
                    action: curAction,
                });

                if (result) {
                    console.log('result', result);
                    if (result.data.confirm_label && result.data.decline_message) {
                        confirm.openConfirm({
                            confirmLabel: result.data.confirm_label,
                            declineMessage: result.data.decline_message,
                            fn: () => onGeneralConfirmClicked(curAction, row),
                            type: 'confirm_check',
                        });
                    } else {
                        confirm.openConfirm({
                            title: refs.localization.confirmations.bot?.[curAction],
                            fn: () => onGeneralConfirmClicked(curAction, row),
                        });
                    }
                }
            } catch (e) {
                console.log(e);
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            };
        }  else {
            confirm.openConfirm({
                title: refs.localization.confirmations.bot?.[curAction],
                fn: () => onGeneralConfirmClicked(curAction, row),
            });
        }

        gl.showLoading = false;
    };

    const onGeneralConfirmClicked = async (curAction, row) => {
        gl.showLoading = true;

        try {
            let result;

            if (curAction === 'delete') {
                result = await BotsService.delete({
                    ids: [row.id],
                });
            } else {
                result = await BotsService.action({
                    id: row.id,
                    action: curAction,
                });
            }

            if (result) {
                // show messages
                if (result.postMessages)
                    result.postMessages.forEach(el => {
                        gl.showNotification({
                            type: el.success ? 'success' : 'error',
                            msg: el.msg,
                        });
                    });

                if (result.data.status) {
                    getData();
                }
            }
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        gl.showLoading = false;
    };

    const getData = async (localLoading = false) => {
        // localLoading
        //     ? loading.value = true
        //     : gl.showLoading = true;
        loadings.table = true;

        // save filters
        LocalStorage.setItem(storeFilterKey, filters);

        filters.filters.include_profit_changed = bots.dataTable?.pager.filters.include_profit != filters.filters.include_profit;
        
        try {
            const result = await BotsService.get({
                pager: filters,
            });

            if (bots.dataTable) {
                Object.keys(result.data).forEach(key => {
                    if (!['columns'].includes(key)) {
                        bots.dataTable[key] = result.data[key];
                    }
                });
            } else {
                bots.dataTable = result.data;
            }

            bots.dataTable.records = bots.dataTable.records.map((el, i) => (
                {
                    key: i,
                    checked: false,
                    ...el,
                }
            ));

        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        loadings.table = false;

        // localLoading
        //     ? loading.value = false
        //     : gl.showLoading = false;
    };

    const changeFilter = ([ key, value ], update = false) => {
        const canUpdate = key === 'query' && !value && bots.pager.query;

        if (key !== 'page') {
            _.set(filters, 'page', 1);
        }

        if (key === 'perPage') {
            LocalStorage.setItem('__filters_bots', value);
        }

        _.set(filters, key, value !== null && value !== undefined ? value : [ -1 ]);
      
        if (update || canUpdate)
            getData();
    };

    const groupAction = async action => {
        gl.showLoading = true;
        showConfirmation.value = false;

        try {
            const result = await BotsService.groupAction({
                ids: [...bots.dataTable.records.filter(({ checked }) => checked).map(({ id }) => id)],
                action,
            });

            if (result) {
                if (result.postMessages)
                    result.postMessages.forEach(el => {
                        gl.showNotification({
                            type: el.success ? 'success' : 'error',
                            msg: el.msg,
                        });
                    });
                
                // update table
                // bots.records = bots.records.filter(({ id }) => !result.data?.ids.includes(id));
                await getData();
            }
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        bots.dataTable.records.forEach(el => el.checked = false);

        gl.showLoading = false;
    };


    const doSomething = ([ action, value ]) => {
        const fns = {
            onQuestionInfo: () => {
                // showQuestionInfo.value = true;
                // questionInfo.value = marketplace.iMentorRecords.find(({ id }) => id === value);
            },
        };

        // fns[action]();
    };

    const letsShowConfirmation = action => {
        actionType.value = action;
        showConfirmation.value = true;
    };

    const resetFilters = () => {
        filters.filters.statuses = [ -1 ];
        filters.filters.states = [ -1 ];
        filters.filters.exchanges = [ -1 ];
        filters.filters.wallets = [ -1 ];
        filters.filters.apikeys = [ -1 ];
        filters.filters.algos = [ -1 ];
        filters.filters.matrix = [ -1 ];
        filters.filters.presets = [ -1 ];
        filters.filters.tags = [ -1 ];
      
        getData();
    };

    const oneBotIsDone = $event => {
        bots.records.unshift($event);
    };

    const downloadConfig = async id => {
        gl.showLoading = true;

        const config = await getFullInfo(id);

        const configName = `Config - ${config.id}.json`;

        delete config.id;
        delete config.status;
        delete config.settings.tradingview_hook_url;
        delete config.settings.tradingview_msg;
        delete config.tags;
        delete config.notes;
        delete config.tradingview_msg;
        delete config.tradingview_hook_url;
        delete config.use_custom_notifications;
        delete config.reloaded_message;

        config.wallet = -1;
        config.api_key = -1;
        config.notifications = [];
        config.notifications_contacts = {
            email: [],
            sms: [],
        };


        if (config.settings.order_matrix != null && config.settings.order_matrix != -1) {
            config.settings.matrix_list = bots.ordersMatrix.find(({ id }) => id == config.settings.order_matrix);
        }

        download(JSON.stringify(config), configName, 'application/json');

        gl.showLoading = false;
    };

    const getFullInfo = async id => {
        try {
            const result = await BotsService.getFullInfo(id);

            if (!result.data.status) {
                result.data.errors.forEach(({ msg }) => {
                    gl.showNotification({
                        type: 'error',
                        msg,
                    });
                });
                router.push({ name: 'bots.review' });
            } else {
                return result.data.record;
            }
        } catch  {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        }
    };

    const openPackageEditing = () => {
        if (canPackageEditing.value) {
            showPackageEditing.value = true;
        }
    };

    onMounted(async () => {
        if (!bots.dataTable)
            await getData(true);

        init.value = true;
    });
  
    return {
        t,
        gl,
        bots,
        refs,
        init,
        router,
        loading,
        filters,
        actions,
        columns,
        loadings,
        refFilters,
        actionType,
        checkedBots,
        cloneMoreShowId,
        filtersChanged,
        checkedCountBots,
        showConfirmation,
        showPackageEditing,
        packageEditingNotLess,
        packageEditingNotMore,
        actionButtonsDisabled,
        showEditingNotMessages,
        showCreateModalFormConfig,
        packageEditingNotSameAlgo,
        getData,
        groupAction,
        doSomething,
        changeFilter,
        resetFilters,
        oneBotIsDone,
        openPackageEditing,
        letsShowConfirmation,
    };
}