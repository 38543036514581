<template>
<base-modal-desktop
    :label="bots.localization?.['package_editing'] || 'Пакетное редактирование'"
    :show="show"
    :loading="loadings.modal"
    width="800px"
    @update:show="!$event ? $emit('update:show', false) : null">
    <template #default>
        <section class="border border-dashed border-gray-600 rounded-md flex flex-col items-center py-6 px-4 h-full">
            <div
                class="text-sm text-center text-gray-500 dark:text-white/65 mt-2"
                v-html="bots.localization['select_the_bot_configuration_settings'] || 'Выберите настройки конфигурации бота из списка, которые хотите изменить'">
            </div>
            
            <n-dropdown
                scrollable
                trigger="click"
                class="bg-white dark:bg-darkCardBg"
                placement="bottom-start"
                :show="showDropdownRef"
                :options="settingsOptions"
                @clickoutside="showDropdownRef = false">
                <n-button
                    class="rounded-md mt-4"
                    @click="showDropdownRef = true">
                    + {{ bots.localization?.['select_settings'] || 'Выбрать настройки' }}
                </n-button>
            </n-dropdown>
        </section>
        <section v-if="visibleSettings.length" class="mt-8 flex flex-wrap">
            <template v-for="setting in visibleSettings" :key="setting">
                <template v-if="setting == 'leverage'">
                    <div class="w-6/12 odd:pr-4 even:pl-4 mb-8">
                        <rb-shoulder
                            :help-warning="dataForm.leverage.info"
                            :pair="leverageMinMax"
                            :label="dataForm.leverage.label"
                            v-model="dataForm.leverage.value">
                            <template #messages>
                                <div v-if="dataForm.leverage.status === 'error'" class="w-full mt-2 text-red-400 text-right">{{ dataForm.leverage.msg }}</div>
                            </template>
                        </rb-shoulder>
                    </div>
                </template>
                <template v-else-if="setting == 'profit'">
                    <div class="w-6/12 odd:pr-4 even:pl-4 mb-8">
                        <rb-select
                            :defaultValue="-1"
                            :label="bots.localization['bot_profit_f']"
                            :help="bots.help['profit']"
                            :options="profitOptions"
                            v-model:value="dataForm.settings.profit" />
                    </div>
                </template>
                <template v-else-if="setting == 'rate_cover'">
                    <div class="w-6/12 odd:pr-4 even:pl-4 mb-8">
                        <rb-select
                            id="rate_cover"
                            :defaultValue="-1"
                            :label="bots.localization['bot_rate_cover_f']"
                            :help="bots.help['rate_cover']"
                            :options="rateCoverOptions"
                            :status="!!bots.errorsForm?.rate_cover ? 'error' : undefined"
                            :msg="bots.errorsForm?.rate_cover?.msg"
                            v-model:value="dataForm.settings.rate_cover"
                            @update:modelValue="delete bots.errorsForm.rate_cover" />
                    </div>
                </template>
                <template v-else-if="setting == 'logarithmic_scale'">
                    <div class="w-6/12 odd:pr-4 even:pl-4 mb-8">
                        <rb-checkbox
                            :nowrap="false"
                            :label="'- ' + bots.localization['bot_additional_settings_logarithmic_scale_checkbox']"
                            :help="bots.help['logarithmic_scale_for_orders']"
                            v-model:checked="dataForm.settings.logarithmic_scale" />

                        <rb-select
                            v-if="dataForm.settings.logarithmic_scale"
                            class="mt-2"
                            :defaultValue="-1"
                            :label-width="345"
                            :nowrap="false"
                            :options="logarithmicFactorOptions"
                            v-model:value="dataForm.settings.logarithmic_factor" />
                    </div>
                </template>
                <template v-else-if="setting == 'cycle_up'">
                    <div class="w-6/12 odd:pr-4 even:pl-4 mb-8">
                        <rb-select
                            :defaultValue="-1"
                            :label="bots.localization['bot_cycle_up_f']"
                            :help="bots.help['cycle_up']"
                            :options="cycleUpOptions"
                            :status="!!bots.errorsForm?.cycle_up ? 'error' : undefined"
                            :msg="bots.errorsForm?.cycle_up?.msg"
                            @update:modelValue="delete bots.errorsForm.cycle_up"
                            v-model:value="dataForm.settings.cycle_up" />
                    </div>
                </template>
                <template v-else-if="setting == 'first_order_indent'">
                    <div class="w-6/12 odd:pr-4 even:pl-4 mb-8">
                        <rb-select
                            :defaultValue="-1"
                            :label="bots.localization['bot_first_order_indent_f']"
                            :help="bots.help['first_order_indent']"
                            :options="firstOrderIndentOptions"
                            :status="!!bots.errorsForm?.first_order_indent ? 'error' : undefined"
                            :msg="bots.errorsForm?.first_order_indent?.msg"
                            :value="dataForm.settings.first_order_indent + ''"
                            @update:value="delete bots.errorsForm.first_order_indent, dataForm.settings.first_order_indent = $event" />
                    </div>
                </template>
                <template v-else-if="setting == 'part_orders_enabled'">
                    <div class="w-6/12 odd:pr-4 even:pl-4 mb-8">
                        <rb-checkbox
                            :nowrap="false"
                            :label="'- ' + bots.localization['bot/settings/part_orders_enabled']"
                            :help="bots.help['part_orders_enabled']"
                            v-model:checked="dataForm.settings.part_orders_enabled" />
                        <rb-matrix-count
                            class="mt-4"
                            :max="compMatrixMaxCount"
                            v-model="dataForm.settings.part_orders_value"
                            :disabled="!dataForm.settings.part_orders_enabled"/>
                        <div v-if="dataForm.settings.part_orders_enabled" class="pt-4">
                            <rb-checkbox
                                :nowrap="false"
                                :label="'- ' + bots.localization['bot/settings/part_orders_unstopable']"
                                v-model:checked="dataForm.settings.part_orders_unstopable" />
                        </div>
                    </div>
                </template>
                <template v-else-if="setting == 'switch_check_running'">
                    <div class="w-6/12 odd:pr-4 even:pl-4 mb-8" v-if="isAlgoLong">
                        <rb-checkbox
                            :label="'- ' + bots.localization['bot_auto_switch_unique_checkbox']"
                            :help="bots.help['switch_check_running']"
                            v-model:checked="dataForm.switch_tp.switch_check_running" />
                    </div>
                </template>
                <template v-else-if="setting == 'stop_loss'">
                    <n-divider />
                    <div class="w-full mb-4">
                        <bot-stop-loss
                            :data="dataForm"
                            :in-modal="true" />
                    </div>
                </template>


                <!-- <template v-else-if="setting == 'profit'"></template> -->
            </template>
        </section>
        <div class="w-full flex flex-wrap" v-if="start">
            <section v-for="(bot, index) in allBots" :key="bot.id" class="mt-4 w-3/12 bots">
                <n-card
                    size="small"
                    :bordered="false"
                    class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg"
                    :segmented="{
                        content: true,
                        footer: 'soft'
                    }">
                    <section class="flex items-center justify-between">
                        <div class="whitespace-nowrap text-sm text-gray-600 dark:text-white/75 py-1">#{{ bot.id }}</div>
                        <n-spin v-if="loadings.bots?.[index]" size="tiny" class="ml-2" />
                        <template v-if="messages?.[index]?.length">
                            <n-popover
                                v-for="(msg, idx) in messages[index]"
                                :key="idx"
                                trigger="hover" placement="top"
                                class="rounded-md"
                                :class="msg.type == 'success' ? 'bg-green-500' : 'bg-red-400'">
                                <template #trigger>
                                    <n-icon
                                        class="cursor-pointer text-xl mx-2"
                                        :class="msg.type == 'success' ? 'text-green-500' : 'text-red-400'">
                                        <QuestionCircle20Filled />
                                    </n-icon>
                                </template>
                                <section>
                                    <span
                                        class="notes text-xs text-white"
                                        v-html="msg.msg">
                                    </span>
                                </section>
                            </n-popover>
                        </template>
                    </section>
                </n-card>
            </section>
        </div>
    </template>
    <template #footer>
        <div class="flex justify-end">
            <n-button
                strong
                class="rounded-md text-white/90"
                :color="gl.mainColor"
                :loading="loadings.button"
                :disabled="loadings.button || !visibleSettings.length"
                @click="onSave">
                {{ bots.localization['bot_update_button'] }}
            </n-button>
        </div>
    </template>
</base-modal-desktop>
</template>

<script>
// general
import general from './general';

// components
import RbShoulder from '@components/rb-shoulder';
import RbMatrixCount from '@components/rb-matrix-count';
import BotStopLoss from '@components/bots/childs/bot-stop-loss';

// icons
import { Warning24Filled, QuestionCircle20Filled } from '@vicons/fluent';

// naive-ui
import {
    NIcon,
    NTag,
    NCard,
    NSpin,
    NDivider,
    NButton,
    NPopover,
    NDropdown } from 'naive-ui';

export default {
    name: 'bot-package-edition-desktop',
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        checkedBots: {
            type: Object,
            required: true,
        },
    },
    components: {
        NTag,
        NIcon,
        NSpin,
        NCard,
        NButton,
        NDivider,
        NPopover,
        NDropdown,
        RbShoulder,
        BotStopLoss,
        RbMatrixCount,
        QuestionCircle20Filled,
    },
    setup(props, context) {
        
        return {
            ...general(props, context),
        };
    },
};
</script>

<style lang="scss" scoped>
.bots {
    padding: 0 8px;

    &:nth-child(4n + 1) {
        padding: 0 8px 0 0 ;
    }

    &:nth-child(4n + 4) {
        padding: 0 0 0 8px;
    }
}
</style>