// vue
import { computed } from 'vue';

// icons
import { QuestionCircle20Filled } from '@vicons/fluent';

export default function (props) {
    const colors = {
        'info': 'main',
        'warning': 'yellow-600',
    };

    const labelClasses = computed(() => {
        return [
            props.status == 'error' ? 'text-red-400' : 'text-gray-600 dark:text-white/75',
            props.helpPosition == 'right' ? 'order-first' : 'order-last ml-2',
            props.disabled == true ? 'opacity-50' : '',
            props.noWrap == true ? 'whitespace-nowrap' : '',
            numberOfHelpersOnTheLeft.value ? `order-${numberOfHelpersOnTheLeft.value + 1}` : 'order-first',
        ];
    });

    const firstHelpCLasses = computed(() => {
        return [
            props.helpPosition == 'right' ? 'order-2 ml-2' : 'order-first',
            props.disabled ? 'opacity-50' : 'cursor-pointer',
        ];
    });

    const secondHelpCLasses = computed(() => {
        return [
            props.helpPosition == 'right' ? 'order-3 ml-2' : 'order-2',
            props.disabled ? 'opacity-50' : 'cursor-pointer',
        ];
    });

    const numberOfHelpersOnTheLeft = computed(() => {
        return props.helpers.reduce((accum, el) => {
            if (el?.position == 'left') {
                return accum + 1;
            }

            return accum;
        }, 0);
    });

    const localHelpers = computed(() => {
        return props.helpers.map((el, i) => {
            return {
                ...el,
                color: colors[el.type],
                icon: el?.icon || QuestionCircle20Filled,
                classes: [
                  el?.position == 'left' ? 'order-first mr-1' : 'order-last ml-1',
                ],
            };
        })
            .filter(({ content }) => !!content);
    });

    return {
        labelClasses,
        localHelpers,
        firstHelpCLasses,
        secondHelpCLasses,
        numberOfHelpersOnTheLeft,
    };
}