<template>
<div
    v-if="isFooter"
    class="h-full flex justify-center items-center text-main text-md dark:bg-darkCardBg bg-gray-50 font-semibold relative overflow-hidden">
    {{ dataFooter }} <div class="absolute w-full top-0 left-0 h-[2px] bg-main shadow-xl"></div>
</div>
<section v-else class="h-full px-3" :class="classes">
    <div class="flex justify-between items-center px-2">
        <div class="flex flex-col items-center">
            <rb-coin class="w-5" :coin="currency(coin?.split('/')[0])" />
            <div class="uppercase text-[8px] text-gray-600 dark:text-white/75 font-semibold whitespace-nowrap">{{ data?.split('/')[0] }}</div>
        </div>
        <div class="opacity-50 px-2">/</div>
        <div class="flex flex-col items-center">
            <rb-coin class="w-5" :coin="currency(coin?.split('/')[1])" />
            <div class="uppercase text-[8px] text-gray-600 dark:text-white/75 font-semibold whitespace-nowrap">{{ data?.split('/')[1] }}</div>
        </div>
    </div>
    <n-button
        v-if="twData?.tradingview_name"
        tertiary
        size="tiny"
        class="rounded-2xl flex items-cneter my-1 w-full"
        :color="gl.mainColor"
        @click="show = true">
        <img v-if="gl.darkTheme" class="w-4 h-4" src="@assets/icons/tv_dark.svg" />
        <img v-else class="w-4 h-4" src="@assets/icons/tv.svg" />
        <div class="text-xs font-semibold pl-1 dark:text-white/75">{{ twData.label }}</div>
    </n-button>
</section>
<base-drawer-mobile
    :label="mainButtonLabel"
    :x-padding="false"
    :y-padding="false"
    v-model:show="show">
    <template #default>
        <section class="h-full">
            <Chart :options="options" />
        </section>
    </template>
</base-drawer-mobile>
</template>

<script>
// general
import general from './general';

import { Chart } from 'vue-tradingview-widgets';

// components
import RbCoin from '@components/rb-coin';

// naive-ui
import {
    NButton } from 'naive-ui';

export default {
    name: 'cell-pair-mobile',
    props: {
        isFooter: {
            type: Boolean,
            default: false,
            required: true,
        },
        dataFooter: {
            default: '',
        },
        data: {
            required: true,
        },
        coin: {
            type: String,
            required: true,
        },
        classes: {
            type: String,
        },
        twData: {
            type: Object,
            required: true,
        },
    },
    components: {
        RbCoin,
        Chart,
        NButton,
    },
    setup(props, context) {

        return {
            ...general(props, context),
        };
    },
};
</script>

<style lang="scss" scoped>
#tradingview-chart {
    height: 100% !important;
}
</style>