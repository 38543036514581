<template>
<n-modal
    :show="show"
    :mask-closable="maskClosable"
    @update:show="$emit('update:show', $event)"
    transform-origin="center"
    @after-enter="$emit('after-enter')"
    @after-leave="$emit('after-leave')">
    <n-card
        size="small"
        role="dialog"
        aria-modal="true"
        header-style="padding: 8px 12px;"
        class="rounded-lg"
        :class="[classes, lightDark ? 'bg-white dark:bg-darkCardBg' : 'bg-white dark:bg-darkbg']"
        :segmented="{
          content: true,
          footer: 'soft'
        }"
        :style="`width: ${width}; height: ${height}; max-height: 98vh; padding: 0;`"
        :content-style="noPadding ? 'padding: 0;' : ''">
        <template #header v-if="(label || showCLoseButton) && !loading">
            <div class="relative min-h-[30px] flex items-center justify-center">
                <div class="flex items-center justify-center text-sm text-gray-600 dark:text-white/80">
                    <n-icon
                        v-if="type === 'error'"
                        class="cursor-pointer text-lg mr-2 text-red-500">
                        <Warning24Filled />
                    </n-icon>
                    <n-icon
                        v-if="type === 'success'"
                        class="cursor-pointer text-lg mr-2 text-green-600">
                        <Checkmark12Filled />
                    </n-icon>
                    {{ label }}
                </div>
                <slot name="headerContent"></slot>

                <n-button
                    v-if="showCLoseButton"
                    circle
                    secondary
                    size="small"
                    class="absolute -right-1 top-1/2 -translate-y-1/2"
                    @click="$emit('update:show', false)">
                    <template #icon>
                        <n-icon class="text-gray-600 dark:text-white" size="16"><Dismiss16Filled /></n-icon>
                    </template>
                </n-button>
            </div>
        </template>
        <template #default>
            <slot name="default" v-if="!loading"></slot>
            <section v-else class="flex items-center justify-center py-14">
                <n-spin size="small" />
            </section>
        </template>
        <template #footer v-if="!loading"><slot name="footer"></slot></template>
    </n-card>
</n-modal>
</template>

<script>
// vue
import { ref } from 'vue';

// store
import { useGl } from '@store/ts/gl';

// naive-ui
import {
    NIcon,
    NCard,
    NModal,
    NSpin,
    NButton } from 'naive-ui';

// icons
import {
    Warning24Filled,
    Dismiss16Filled,
    Checkmark12Filled } from '@vicons/fluent';

export default {
    name: 'base-modal-desktop',
    props: {
        label: {
            type: String,
            required: true,
        },
        show: {
            type: Boolean,
            default: false,
        },
        width: {
            type: String,
            default: '600px',
        },
        height: {
            type: Number, String,
        },
        maskClosable: {
            type: Boolean,
            default: true,
        },
        showCLoseButton: {
            type: Boolean,
            default: true,
        },
        classes: {
            type: String,
        },
        lightDark: {
            type: Boolean,
            default: true,
        },
        type: {
            type: String,
            default: 'default',
        },
        loading: {
            type: Boolean,
            default: false,
        },
        noPadding: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        NSpin,
        NIcon,
        NCard,
        NModal,
        NButton,
        Warning24Filled,
        Dismiss16Filled,
        Checkmark12Filled,
    },
    setup() {
    // store
        const gl = useGl();

        return {
            gl,
        };
    },
};
</script>

<style lang="scss" scoped>
:deep(.n-card__content) {
    overflow-y: auto;
}
</style>