// vue
import { h, ref, reactive, computed, watch } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useBots } from '@store/bots';

// i18n
import { useI18n } from 'vue-i18n';


// services
import botsService from '@services/bots';

// shared
import { BOTS_DATA_FORM_FACTORY_PREPARE } from '@shared/ts/factories';

// naive-ui
import {
    useMessage,
    useNotification } from 'naive-ui';

// components
import RbCoin from '@components/rb-coin';
import RbLabel from '@components/rb-label';

export default function (props, context) {
    // store
    const gl = useGl();
    const bots = useBots();
    const refs = useRefs();

    // i18n
    const { t } = useI18n();

    // naive-ui
    
    const notification = useNotification();

    // vars
    const { _ } = window;
    const dataForm = bots.dataForm;
    const botShortInfo = ref();
    const showCreateNewPreset = ref(false);
    const newPresetLoading = ref(false);
    const orderMatrixLoading = ref(false);
    const orderMatrixModal = ref(false);
    const viewOrdersMatrixModal = ref(false);
    const newOrderMatrixLoading = ref(false);

    const martinModel = reactive({
        martin: null,
        martinPart: null,
    });
    const minDepMessages = reactive({
        minDep10: '',
        minDep5: '',
    });

    const fields = [
        'restruct_cycle_i',
        'restruct_cycle_rate_cover',
    ];

    const newPresetModel = reactive({
        name: {
            value: '',
            title: bots.localization['bot_auto_switch_type_volatility_analyzer_save_preset_name_f'],
            placeholder: bots.localization['bot_auto_switch_type_volatility_analyzer_save_preset_name_f'],
            info: undefined,
            status: undefined,
            msg: undefined,
        },
        description: {
            value: '',
            title: bots.localization['bot_auto_switch_type_volatility_analyzer_save_preset_description_i'],
            placeholder: bots.localization['bot_auto_switch_type_volatility_analyzer_save_preset_description_i'],
            info: undefined,
            status: undefined,
            msg: undefined,
        },
    });

    const newOrderMatrixModel = reactive({
        name: {
            value: '',
            title: bots.localization['bot_orders_matrix_pro_button_modal_matrix_name_f'],
            placeholder: bots.localization['bot_orders_matrix_pro_button_modal_matrix_name_i'],
            info: undefined,
            status: undefined,
            msg: undefined,
        },
        amount: {
            value: 0,
            title: bots.localization['bot_orders_matrix_pro_button_modal_matrix_amount_f'],
            placeholder: null,
        },
        matrix: {
            value: [],
            inputHardMartin: [],
            inputHardMartinShow: false,
            info: undefined,
            status: undefined,
            msg: undefined,
        },
    });

    watch(() => newOrderMatrixModel.name.value, () => {
        newOrderMatrixModel.name.status = undefined;
        newOrderMatrixModel.name.msg = undefined;
    });

    watch(() => Object.keys(bots.innerForms).length, v => {
        if (v > 0) {
            const firstKey = Object.keys(bots.innerForms)[0];

            if (fields.includes(firstKey)) {

                setTimeout(() => {
                    const selector = firstKey;
                    const el = document.querySelector(`#${selector}`);
                    
                    if (el)
                        el.scrollIntoView({ block: gl.isMobile ? 'start' : 'center', behavior: 'smooth' });
                }, 1000);
            }
        }
    });
  
    (() => {
        Object.keys(dataForm.settings).forEach(el => {
            watch(() => dataForm.settings[el], () => {
                delete bots.errorsForm[el];
            });
        });
    })();

    const pairSelected = computed(() => dataForm.pair.value && String(dataForm.pair.value).split('/').length === 2);
    const isExchangeFutures = computed(() => !!refs.exchanges.find(({ id }) => id === dataForm.exchange.value)?.futures);
    const isExchangeTrailingstop = computed(() => !!(_.get(props.exchangeInfoRef, ['extra', 'trailingstop']) == 1));

    const rateCoverOptions = computed(() =>
        bots.settingsRefs.rate_cover
            .map(el => ({
                label: el.title + '',
                value: el.id + '',
                ...el,
            }))
            .filter(el => isAlgoLong.value ? el.value <= 99 : true ),
    );

    const firstOrderIndentOptions = computed(() => bots.settingsRefs.first_order_indent.map(el => ({
        label: el.title,
        value: el.id + '',
        ...el,
    })));

    const rateModesOptions = computed(() => bots.settingsRefs.rateModes.map(el => ({
        label: el.title + '',
        value: el.id + '',
        ...el,
    })));

    const orderMatrixOptions = computed(() => bots.ordersMatrix.map(el => ({
        label: el.title,
        value: el.id,
        ...el,
    })));

    const profitOptions = computed(() => bots.settingsRefs.profit.map(el => ({
        label: el.title + '',
        value: el.id + '',
        ...el,
    })));

    const trailingstopRateOptions = computed(() => bots.settingsRefs.trailingstop_rate.map(el => ({
        label: el.title + '',
        value: el.id + '',
        ...el,
    })));
  
    const cycleUpOptions = computed(() => bots.settingsRefs.cycle_up.map(el => ({
        label: el.title + '',
        value: el.id + '',
        ...el,
    })));

    const sleepBeforeCancelOptions = computed(() => bots.settingsRefs.sleep_before_cancel.map(el => ({
        label: el.title + '',
        value: el.id + '',
        ...el,
    })));

    const sleepBeforeUpOptions = computed(() => bots.settingsRefs.sleep_before_up.map(el => ({
        label: el.title + '',
        value: el.id + '',
        ...el,
    })));

    const sleepAfterDoneOptions = computed(() => bots.settingsRefs.sleep_after_done.map(el => ({
        label: el.title + '',
        value: el.id + '',
        ...el,
    })));

    const columns = computed(() => [
        {
            key: 'id',
            title() {
                return h(RbLabel, {
                    class: 'text-sm',
                    label: '#',
                });
            },
        }, {
            key: 'volume_one_coin',
            title() {
                return h(RbLabel, {
                    noWrap: true,
                    class: 'text-sm',
                    label: (bots.localization?.volume_one_coin || 'Объем {{#coin}}').replace('{{#coin}}', `<strong class="uppercase">${String(botShortInfo.value?.pair).split('/')[0]}</strong>`),
                    helpers: [{
                        type: 'info',
                        content: bots.localization?.volume_one_coin_help,
                    }],
                });
            },
        }, {
            key: 'order_matrix_price',
            title() {
                return h(RbLabel, {
                    noWrap: true,
                    class: 'text-sm',
                    label: bots.localization?.order_matrix_price || 'Цена',
                    helpers: [{
                        type: 'info',
                        content: bots.localization?.order_matrix_price_help,
                    }],
                });
            },
        }, {
            key: 'volume_two_coin',
            title() {
                return h(RbLabel, {
                    noWrap: true,
                    class: 'text-sm',
                    label: (bots.localization?.volume_two_coin || 'Объем {{#coin}}').replace('{{#coin}}', `<strong class="uppercase">${String(botShortInfo.value?.pair).split('/')[1]}</strong>`),
                    helpers: [{
                        type: 'info',
                        content: bots.localization?.volume_two_coin_help,
                    }],
                });
            },
        }, {
            key: 'exit_price_at_zero',
            title() {
                return h(RbLabel, {
                    noWrap: true,
                    class: 'text-sm',
                    label: bots.localization?.exit_price_at_zero || 'Цена выхода в 0',
                    helpers: [{
                        type: 'info',
                        content: bots.localization?.exit_price_at_zero_help,
                    }],
                });
            },
        }, {
            key: 'take_profit_price',
            title() {
                return h(RbLabel, {
                    noWrap: true,
                    class: 'text-sm',
                    label: bots.localization?.take_profit_price || 'Цена тейк профита',
                    helpers: [{
                        type: 'info',
                        content: bots.localization?.take_profit_price_help || 'Отскок от текущей цены относительно провала',
                    }],
                });
            },
        }, {
            key: 'order_matrix_profit',
            title() {
                return h(RbLabel, {
                    noWrap: true,
                    class: 'text-sm',
                    label: bots.localization?.order_matrix_profit || 'Профит',
                    helpers: [{
                        type: 'info',
                        content: bots.localization?.order_matrix_profit_help,
                    }],
                });
            },
        },
    ].map(el => ({
        render: (row, i) => {
            if (el.key == 'id') {
                return h('div', {
                    class: 'whitespace-nowrap',
                }, i + 1);
            } else if (['order_matrix_price', 'volume_two_coin', 'exit_price_at_zero'].includes(el.key)) {
                return h('div', {
                    class: 'flex items-center whitespace-nowrap text-xs',
                }, [
                    h('span', null, row[el.key].value + ' ('),
                    h('span', { class: row[el.key].percent >= 0 ? 'text-green-600' : 'text-red-500' }, row[el.key].percent + '%'),
                    h('span', null, ' )'),
                ]);
            } else if (el.key === 'take_profit_price') {
                return h('div', {
                    class: 'flex items-center whitespace-nowrap text-xs',
                }, [
                    h('span', null, row[el.key].value + ' ('),
                    h('span', { class: row[el.key].percent[0] >= 0 ? 'text-green-600' : 'text-red-500' }, row[el.key].percent[0] + '%'),
                    // h('span', { class: `${row[el.key].percent[1] >= 0 ? 'text-green-600' : 'text-red-500'} pl-1` }, row[el.key].percent[1] + '%'),
                    h('span', null, ' )'),
                ]);
            } else if (el.key === 'order_matrix_profit') {
                return h('div', {
                    class: 'flex items-center font-semibold whitespace-nowrap text-xs',
                }, [
                    h('span', { class: row[el.key] >= 0 ? 'text-green-600' : 'text-red-500' }, row[el.key]),
                    h(RbCoin, {
                        coin: currency(row.curCurrency),
                        class: 'w-4 mt-[4px] ml-2',
                    }),
                ]);
            } else {
                return h('div', {
                    class: 'whitespace-nowrap text-xs',
                }, row[el.key]);
            }
        },
        ...el,
    })));

    const records = computed(() =>
            botShortInfo.value?.orders_matrix.length
                ? botShortInfo.value.orders_matrix.map((el, i) => ({
                    key: i,
                    ...el,
                }))
                : []);

    const depoPairsOptions = computed(() => {
        if (!pairSelected.value) return [];
        const [fSymbol, sSymbol] = String(dataForm.pair.value).split('/').map(s => s.trim());
        const pairsOptions = [refs.currencies.find(el => el.id === fSymbol), refs.currencies.find(el => el.id === sSymbol)];
      
        return pairsOptions.filter(o => o).map(el => ({
            label: el.title + '',
            value: el.id + '',
            ...el,
        }));
    });

    const restructCycleOptions = computed(() => {
        const arr = [];

        for (let i = 0; i < compMatrixMaxCount.value - 1; i++) {
            arr.push({
                label: `${i + 1}`,
                value: `${i + 1}`,
            });
        }

        return arr;
    });

    const restructCycleRateCoverOptions = computed(() => {
        if (dataForm.settings.rate_cover <= 0) return [];
  
        const curRateCoverIdx = bots.settingsRefs.rate_cover.findIndex(({ id }) => parseFloat(id, 10) == dataForm.settings.rate_cover);

        let res = [];

        if (~curRateCoverIdx) {
            if (isAlgoLong.value) {
                res = dataForm.settings.rate_cover == 99
                    ? [ bots.settingsRefs.rate_cover.find(({ id }) => id == 99) ]
                    : bots.settingsRefs.rate_cover.slice(curRateCoverIdx + 1)
                        .filter(({ id }) => isAlgoLong.value ? id <= 99 : true );
            } else {
                res = bots.settingsRefs.rate_cover.slice(curRateCoverIdx + 1);
            }
        }
      
        return res.map(el => ({
            label: el.title + '',
            value: el.id + '',
            ...el,
        }));
    });

    const isOrderMatrixCorrect = computed(() => {
        if (newOrderMatrixModel.amount.value == 0) return;
        return omPoolToSpread.value == 0 || (omPoolToSpread.value <= 0.5 && omPoolToSpread.value > 0);
    });

    const omUsedPool = computed(() =>
        newOrderMatrixModel.matrix.value.length
            ? newOrderMatrixModel.matrix.value.slice(0, newOrderMatrixModel.amount.value)
                .reduce((a, b) => {
                    const lA = typeof a === 'string' ? +a.replace(',', '.') : a;
                    const lB = typeof b === 'string' ? +b.replace(',', '.') : b;
                    return Math.abs(lA) + Math.abs(lB);
                }, 0)
            : null);

    const omPoolToSpread = computed(() => Number(Number(100 - omUsedPool.value).toFixed(2)));
    watch(() => omPoolToSpread.value, () => {
        newOrderMatrixModel.matrix.status = undefined;
        newOrderMatrixModel.matrix.msg = undefined;
    });

    const omPoolToSpreadLabel = computed(() =>  omPoolToSpread.value < 0.5 && omPoolToSpread.value > 0 ? '<0.5%' : `${omPoolToSpread.value}%`);

    const canCreateNewOrderMatrix = computed(() =>
        newOrderMatrixModel.amount.value == 0 || newOrderMatrixModel.name.value.trim().length === 0
            ? false
            : isOrderMatrixCorrect.value);
  
    const compMatrixMaxCount = computed(() => {
        const ordersMatrix = bots.ordersMatrix.find(({ id }) => id == dataForm.settings.order_matrix);
  
        const matrix_count = parseInt(ordersMatrix?.matrix_count || ordersMatrix?.matrix.length, 10);

        return matrix_count;
    });
    watch(compMatrixMaxCount, v => {
        if (dataForm.settings.restruct_cycle_i > v) {
            context.emit('update:restruct_cycle_i', -1);
        }
    });

    const isCoinSettled = computed(() => !!props.exchangeInfoRef.extra.coin);
    const isAlgoLong = computed(() => dataForm.algo.value === 'long');
    const isAlgoShort = computed(() => dataForm.algo.value === 'short');
    const compMatrixMaxCountAllowed = computed(() => compMatrixMaxCount.value > 1);
    const partOrderValueShow = computed(() => dataForm.settings.order_matrix != -1 && compMatrixMaxCountAllowed.value);
    watch(partOrderValueShow, v => {
        if (!v) {
            dataForm.settings.part_orders_enabled = false;
            dataForm.settings.restruct_cycle_enabled = false;
        }
    });

    const isProfitCoinEditable = computed(() => {
        if (isAlgoLong.value && !isExchangeFutures.value) return false;
        if (isAlgoShort.value && !isExchangeFutures.value) return true;

        return isCoinSettled.value ? false : !(isExchangeFutures.value && !isCoinSettled.value);
    });

    const pairSymbols = computed(() => String(dataForm.pair.value).split('/').map(s => s.trim()));

    const coinSymbolForProfitCoin = computed(() => {
        const [ fSymbol, sSymbol ] = pairSymbols.value;

        if (isAlgoLong.value && !isExchangeFutures.value) return sSymbol;
        if (isAlgoShort.value && !isExchangeFutures.value) return sSymbol;

        if (isExchangeFutures.value && !isCoinSettled.value) return sSymbol;
        if (isCoinSettled.value) return fSymbol;

        return null;
    });

    const orderMatrixDec = () => {
        if (newOrderMatrixModel.amount.value == 1) return;
        --newOrderMatrixModel.amount.value;
    };

    const orderMatrixInc = () => {
        if (newOrderMatrixModel.amount.value == 100) return;
        ++newOrderMatrixModel.amount.value;
    };

    const orderMatrixIncEl = i => {
        if (!newOrderMatrixModel.matrix.value[i]) {
            newOrderMatrixModel.matrix.value[i] = 1;
        } else {
            if (typeof newOrderMatrixModel.matrix.value[i] === 'string')
                newOrderMatrixModel.matrix.value[i] = Math.floor(+newOrderMatrixModel.matrix.value[i].replace(',', '.'));

            newOrderMatrixModel.matrix.value[i]++;
        }
    };

    const orderMatrixDecEl = i => {
        if (newOrderMatrixModel.matrix.value[i]) {
            if (typeof newOrderMatrixModel.matrix.value[i] === 'string') {
                newOrderMatrixModel.matrix.value[i] = Math.floor(+newOrderMatrixModel.matrix.value[i].replace(',', '.'));
            } else {
                if (newOrderMatrixModel.matrix.value[i] - 1 > 0) 
                    newOrderMatrixModel.matrix.value[i]--;
            }
        }
    };

    const exchange = (exchange) => {
        return refs.exchanges.find(el => el.id === exchange);
    };

    const currency = icon => refs.currencies.find(el => el.id === icon);

    const prepareNote = (str) => {
        return str
            .replace('text-color--green', 'text-green-400')
            .replace('text-color--red', 'text-red-400');
    };

    const onNewOrderMatrix = async () => {
        newOrderMatrixLoading.value = true;

        const matrix = newOrderMatrixModel.matrix.value.slice(0, newOrderMatrixModel.amount.value);
        const orderMatrix = { title: newOrderMatrixModel.name.value, matrix };

        let result;

        try {
            result = await botsService.createNewOrderMatrix(orderMatrix);
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        }

        if (result) {
            if (!result?.data.status) {
                if (result.data?.errors_form) {

                    for (let key in newOrderMatrixModel) {
                        const fields = result.data.errors_form.i_0.fields;
                        const el = Object.keys(fields).find(el => el === key);

                        if (el) {
                            newOrderMatrixModel[key].status = 'error';
                            newOrderMatrixModel[key].msg = fields[el].msg;
                        } else {
                            newOrderMatrixModel[key].status = 'success';
                            newOrderMatrixModel[key].msg = undefined;
                        }
                    }
                }
            } else {
                const { id } = result.data.orders_matrix.find(({ title }) => title === newOrderMatrixModel.name.value);

                // clean apiKeyModel
                newOrderMatrixModel.name.value = '';
                newOrderMatrixModel.amount.value = 0;
                newOrderMatrixModel.matrix.value = [];

                // show messages
                if (result?.postMessages)
                    result.postMessages.forEach(el => {
                        gl.showNotification({
                            type: el.success ? 'success' : 'error',
                            msg: el.msg,
                        });
                    });

                orderMatrixModal.value = false;

                bots.refs.orders_matrix = result.data.orders_matrix;

                setTimeout(() => {
                    dataForm.settings.order_matrix = id;
                }, 200);
            }
        }

        newOrderMatrixLoading.value = false;
    };

    const onCreateNewPreset = async () => {
        newPresetLoading.value = true;
      
        const settings = { ...bots.dataForm.settings };
        // fields not in preset
        delete settings.profit_coin;
        delete settings.number_of_executed_orders;
      
        const newPreset = {
            _formPath: 'savePresetModal',
            title: newPresetModel.name.value,
            description: newPresetModel.description.value,
            settings,
        };

        try {
            const result = await botsService.createNewPreset(newPreset);

            if (result) {
                if (!result?.data.status) {
                    if (result.data?.errors_form) {

                        for (let key in newPresetModel) {
                            const fields = result.data.errors_form.savePresetModal.fields;
                            const el = Object.keys(fields).find(el => el === key);

                            if (el) {
                                newPresetModel[key].status = 'error';
                                newPresetModel[key].msg = fields[el].msg;
                            } else {
                                newPresetModel[key].status = 'success';
                                newPresetModel[key].msg = undefined;
                            }
                        }
                    }
                } else {
                    // show messages
                    if (result?.postMessages)
                        result.postMessages.forEach(el => {
                            notification[el.success ? 'success' : 'error']({
                                content: el.msg,
                                duration: 2500,
                                keepAliveOnHover: true,
                            });
                        });

                    bots.refs.presets = { [result.data.preset.title]: result.data.preset, ...bots.presets };
                    bots.currentPreset = result.data.preset.title;

                    newPresetModel.name.value = '';
                    newPresetModel.description.value = '';

                    showCreateNewPreset.value = false;
                }
            }
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        }

        newPresetLoading.value = false;
    };

    const onViewOrderMatrix = async (idOrdersMatrix) => {
        orderMatrixLoading.value = true;
        // eslint-disable-next-line no-param-reassign
        idOrdersMatrix = idOrdersMatrix || -1;

        const params = BOTS_DATA_FORM_FACTORY_PREPARE(dataForm);
        delete params.notifications;
        delete params.notifications_contacts;

        let result;

        try {
            result = await botsService.getOrdersMatrixForLogarithmicFactor(params);
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        }

        if (result?.msg) {
            notification.error({
                content: result.msg,
                duration: 2500,
                keepAliveOnHover: true,
            });
        }
  
        if (result?.data?.status) {
            const curCurrencyFirstCoin = String(result.data.pair).split('/')[0].toLowerCase();
            const curCurrencySecondCoin = String(result.data.pair).split('/')[1].toLowerCase();
            const fixedCountFirstCoin = ['usdt'].includes(curCurrencyFirstCoin) ? 2 : 8;
            const fixedCountSecondCoin = ['usdt'].includes(curCurrencySecondCoin) ? 2 : 8;
            const orders = [];
            const prices = [];

            result.data.orders_matrix.forEach(el => {
                prices.push(el.rate);
                orders.push(el.rate * el.amount);
            });
            const sumOfVolume = getSumOfArray(orders);

            let curVolume = 0;
            let curMoney = 0;
            const profit = dataForm.settings.profit / 100;

            result.data.orders_matrix = result.data.orders_matrix.map((el, i) => {
                curVolume += +el.amount;
                curMoney += orders[i];
                const zeroPrice = parseFloat((curMoney / curVolume).toFixed(8));
                const profitPrice = parseFloat((zeroPrice * (1 + profit)).toFixed(8));
                const profitPercent = ((profitPrice / el.rate - 1) * 100).toFixed(2);
                const priceDiff = i === 0 ? (0.0).toFixed(2) : ((100 * (el.rate - result.data.orders_matrix[i - 1].rate)) / result.data.orders_matrix[i - 1].rate).toFixed(2);
                const USDTVolume = parseFloat((el.rate * el.amount).toFixed(fixedCountSecondCoin));
                const volPerc = (((el.rate * el.amount).toFixed(8) * 100) / sumOfVolume).toFixed(3);
                const zeroPercent = ((zeroPrice / el.rate - 1) * 100).toFixed(2);
                const jumpPrice = (profitPrice - prices[i]) / (prices[0] - prices[i]);
                const resJumpPrice = jumpPrice > 1 ? '---' : (jumpPrice * 100).toFixed(2);
                const profitMoney = parseFloat((profit * curMoney).toFixed(8));

                return {
                    ...el,
                    priceDiff,
                    USDTVolume,
                    volPerc,
                    zeroPrice,
                    profitPrice,
                    profitPercent,
                    zeroPercent,
                    resJumpPrice,
                    profitMoney,
                    curCurrency: curCurrencySecondCoin,

                    volume_one_coin: Number(el.amount).toFixed(fixedCountFirstCoin),
                    order_matrix_price: {
                        value: parseFloat((Number(el.rate))?.toFixed(8)),
                        percent: priceDiff,
                    },
                    volume_two_coin: {
                        value: USDTVolume,
                        percent: volPerc,
                    },
                    exit_price_at_zero: {
                        value: zeroPrice,
                        percent: zeroPercent,
                    },
                    take_profit_price: {
                        value: profitPrice,
                        percent: [profitPercent, resJumpPrice],
                    },
                    order_matrix_profit: profitMoney,
                };
            });

            botShortInfo.value = result.data;

            viewOrdersMatrixModal.value = true;
        }

        // show messages
        if (result?.postMessages)
            result.postMessages.forEach(el => {
                notification[el.success ? 'success' : 'error']({
                    content: el.msg,
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            });

        orderMatrixLoading.value  = false;
    };

    const renderDepoPairLabel = (option) => {
        return h(
            'div',
            {
                style: {
                    display: 'flex',
                    alignItems: 'center',
                },
            },
            [
                h(RbCoin, {
                    class: 'ml-2 my-2',
                    coin: currency(option.value),
                }),
                h(
                    'div',
                    {
                        style: {
                            marginLeft: '8px',
                            padding: '4px 0',
                        },
                    },
                    [
                        h('div', null, [option.label]),
                    ],
                ),
            ],
        );
    };

    const hasFilter = id => {
        return dataForm.start_filters.findIndex(el => id == el.id);
    };

    const triggerFilter = id => {
        const localId = hasFilter(id);

        if(~localId) {
            dataForm.start_filters.splice(localId, 1);
        } else {
            dataForm.start_filters.push(
                {
                    id,
                    id_op: '==',
                    value: null,
                });
        }
    };

    const getOrdersGrid = (ordLength, martinVal, martinPart) => {
        const ordersGrid = [];
        let curMartin = 1;
        for (let i = 0; i < ordLength; i++) {
            // const pageMartin = $('.input-hard-martin').eq(i).val();
            const pageMartin = newOrderMatrixModel.matrix.inputHardMartin[i];
            martinVal = pageMartin ? pageMartin : martinVal;
            const martin = i === 0 ? 0 : martinVal;
            const partOfMartin = i < 2 ? 1 : 1 + (martinPart * (i - 1)) / 100;
            const martinParts = i < 2 ? 1 : 1 + martinPart * (i - 1);
            const martinMove = Math.pow(1 + (martin * partOfMartin) / 100, i);
            curMartin *= 1 + (martin * partOfMartin) / 100;
            ordersGrid.push(curMartin);
        }
        const sum = getSumOfArray(ordersGrid);
        const orders = [];
        for (let i = 0; i < ordersGrid.length; i++) {
            const order = (ordersGrid[i] * 100) / sum;
            orders.push(order);
        }
        return orders;
    };

    const getSumOfArray = arr => {
        let sum = 0;
        for (let i = 0; i < arr.length; i++) {
            sum += arr[i];
        }
        return sum;
    };

    const fill = () => {
        const orderCount = newOrderMatrixModel.amount.value;

        const martinPart = martinModel.martinPart;
        const martinVal = martinModel.martin.replace(',', '.');
        const orders = getOrdersGrid(orderCount, martinVal, martinPart);
        setOrderGrid(orders);
        setMinDep(Math.min(...orders));
    };

    const setOrderGrid = orders => {
        let totalVol = 100;
        for (let i = 0; i < orders.length; i++) {
            const orderSize = orders[i].toFixed(4);
            totalVol -= orderSize;
            const val = i === orders.length - 1 ? (Number(orderSize) + totalVol).toFixed(4) - 0.001 : orderSize;
            newOrderMatrixModel.matrix.value[i] = Number(val)?.toFixed(4);
        }
    };

    const setMinDep  = val => {
        const minDep10 = ((100 * 10) / val).toFixed(2);
        const minDep5 = ((100 * 5) / val).toFixed(2);
        minDepMessages.minDep10 = `Минимальный ориентировочный депозит (при первом ордере $10) = $${minDep10}`;
        
        minDepMessages.minDep5 = `Минимальный ориентировочный депозит (при первом ордере $5) = $${minDep5}`;
    };

    const hardMapMartin = () => {
        newOrderMatrixModel.matrix.inputHardMartinShow = !newOrderMatrixModel.matrix.inputHardMartinShow;
        
        if (!newOrderMatrixModel.matrix.inputHardMartinShow) {
            newOrderMatrixModel.matrix.inputHardMartin = [];
        }
    };

    return {
        gl,
        bots,
        records,
        columns,
        dataForm,
        omUsedPool,
        martinModel,
        botShortInfo,
        profitOptions,
        cycleUpOptions,
        newPresetModel,
        minDepMessages,
        newPresetLoading,
        depoPairsOptions,
        orderMatrixModal,
        rateModesOptions,
        rateCoverOptions,
        isExchangeFutures,
        orderMatrixOptions,
        compMatrixMaxCount,
        partOrderValueShow,
        omPoolToSpreadLabel,
        orderMatrixLoading,
        newOrderMatrixModel,
        showCreateNewPreset,
        sleepBeforeUpOptions,
        restructCycleOptions,
        isProfitCoinEditable,
        isOrderMatrixCorrect,
        newOrderMatrixLoading,
        viewOrdersMatrixModal,
        sleepAfterDoneOptions,
        isExchangeTrailingstop,
        firstOrderIndentOptions,
        canCreateNewOrderMatrix,
        coinSymbolForProfitCoin,
        trailingstopRateOptions,
        sleepBeforeCancelOptions,
        compMatrixMaxCountAllowed,
        restructCycleRateCoverOptions,
        t,
        fill,
        hasFilter,
        exchange,
        currency,
        prepareNote,
        hardMapMartin,
        triggerFilter,
        orderMatrixInc,
        orderMatrixDec,
        orderMatrixIncEl,
        orderMatrixDecEl,
        onNewOrderMatrix,
        onViewOrderMatrix,
        onCreateNewPreset,
        renderDepoPairLabel,
        onlyAllowNumber: (value) => {
            const isTrue = (value === '' || /^\d+$/.test(value)) && (value >= 0 && value <= 100);

            if (!isTrue && value > 100)
                newOrderMatrixModel.amount.value = 100;

            return isTrue;
        },
        onlyAllowNumberMatrix: (value) => value === '' || /^\d+[.,]?\d{0,2}$/.test(value),
    };
}