// vue
import { h, ref, reactive, computed, watch, onMounted } from 'vue';

// services
import VolatilityService from '@services/volatility';

// store
import { useTools } from '@store/tools';

// router
import { useRoute, useRouter } from 'vue-router';

// components
import Presets from '@components/toolss/childs/presets';
import CoinList from '@components/toolss/childs/coin-list';
import newTemplate from '@components/toolss/childs/new-template';

export default function () {
    // store
    const tools = useTools();

    // router
    const route = useRoute();

    // vars
    const currentTab = ref(route.query.tab || 'rbpsSettings');

    const tabs = computed(() => [
        {
            name: 'rbpsSettings',
            component: newTemplate,
            title: tools.localization.tools_volatility_analyzer_create_new_preset_title,
        }, {
            name: 'rbpsPresets',
            component: Presets,
            title: tools.localization.tools_volatility_analyzer_presets_tab_title,
        }, {
            name: 'rbpsCoinList',
            component: CoinList,
            title: tools.localization['tools/va/coin_list/tabs/title'],
        },
    ]);

    return {
        tabs,
        tools,
        currentTab,
    };
}