// vue
import { h, ref, computed } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';

// i18n
import { useI18n } from 'vue-i18n';

// components
import RbCoin from '@components/rb-coin';

export default function (props, context) {
    // store
    const gl = useGl();
    const refs = useRefs();
        
    // i18n
    const { t } = useI18n();

    // vars
    const { _ } = window;
    const showDrawer = ref(false);

    
    const currency = (icon) => refs.currencies.find(el => el.id === icon);

    const renderLabel = option => {
        return h(
            'div',
            {
                class: 'flex items-center py-1',
            },
            [
                h(RbCoin, {
                    class: 'w-4',
                    coin: currency(option.value),
                }),
                h(
                    'div',
                    {
                        class: 'px-3 text-xs',
                    }, option.label,
                ),
            ],
        );
    };

    const currentValue = computed(() => {
        if (props.multiple) {
            const result = [];

            for (const item of props.options) {
                if (Array.isArray(props.value) && ~props.value.findIndex(el => el == item.value)) {
                    result.push(item);
                }
            }

            return result.length ? result : null;
        } else {
            const i = props.options.findIndex(el => el.value == props.value);
            return ~i ? props.options[i] : null;
        }
        
    });

    const addOrRemove = (array, value) => {
        var index = array.indexOf(value);

        if (index === -1) {
            array.push(value);
        } else {
            array.splice(index, 1);
        }

        return array;
    };

    const setValue = v => {
        if (props.multiple) {
            const copy = Array.isArray(props.value)
                ? _.cloneDeep(props.value)
                : [ props.value ];

            context.emit('update:value', addOrRemove(copy, v.value));
        } else {
            context.emit('update:value', v.value);
        }
    };

    const isChecked = v => {
        return Array.isArray(props.value)
            ? ~props.value.findIndex(el => el == v.value)
            : props.value == v.value;
    };

    const letsCopyList = () => {
        const res = currentValue.value.map(({ label }) => label)?.join(',');

        navigator.clipboard.writeText(res)
            .then(() => {
                gl.showNotification({
                    type: 'success',
                    msg: refs.localization?.confirmations?.currencys?.copied_seccessfull || 'Список монет успешно скопирован',
                });
            })
            .catch(err => {
                console.log('Something went wrong', err);
            });
    };

    const letsPasteList = () => {
        navigator.clipboard.readText()
            .then(text => {
                const coinList = text.split(',');

                if (coinList?.length) {
                    const res = props.options.filter(({ label }) => ~coinList.findIndex(el => el == label));

                    const copy = Array.isArray(props.value)
                        ? _.cloneDeep(props.value)
                        : [ props.value ];

                    res.forEach(coin => {
                        if (coin?.id != null && !~copy.findIndex(el => el == coin.id)) {
                            copy.push(coin.id);
                        }
                    });

                    context.emit('update:value', copy);
                }
            })
            .catch(err => {
                console.log('Something went wrong', err);
            });
    };

    return {
        t,
        refs,
        showDrawer,
        currentValue,
        renderLabel,
        currency,
        isChecked,
        setValue,
        letsCopyList,
        letsPasteList,
    };
}