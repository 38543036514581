import { defineStore } from 'pinia';

export const useBots = defineStore('bots', {
    state: () => ({
        refs: null,
        loading: false,
        dataForm: {},
        dataTable: null,
        errorsForm: {},
        currentPreset: null,
        innerForms: {},
        walletsRefs: null,
    }),
    getters: {
        localization(state) {
            return state.refs?.localization[Object.keys(state.refs.localization)[0]];
        },
        help(state) {
            return state.refs.help;
        },
        algos(state) {
            return state.refs.algos;
        },
        presets(state) {
            return state.refs.presets;
        },
        notifications(state) {
            return state.refs.notifications;
        },
        settingsRefs(state) {
            return state.refs.settingsRefs;
        },
        ordersMatrix(state) {
            return state.refs.orders_matrix;
        },
        errorAutorestartTypes(state) {
            return state.refs.error_autorestart_types;
        },
        positionmodes(state) {
            return state.refs.positionmodes;
        },
        switchTypes(state) {
            return state.refs.switch_types;
        },
        coinLists(state) {
            return state.refs.coinLists;
        },
        coinListStrategies(state) {
            return state.refs.coin_list_strategies;
        },
        periodUnits(state) {
            return state.refs.period_units;
        },
        intervalUnits(state) {
            return state.refs.interval_units;
        },
        vaPresets(state) {
            return state.refs.vaPresets;
        },
        startFilters(state) {
            return state.refs.start_filters;
        },
        startFilterOpts(state) {
            return state.refs.start_filter_opts;
        },
        notificationsContacts(state) {
            return state.refs.notifications_contacts;
        },
        tags(state) {
            return state.refs?.tags;
        },
        pager(state) {
            return state.dataTable.pager;
        },
        columns(state) {
            return state.dataTable.columns;
        },
        records(state) {
            return state.dataTable.records;
        },
        filterPresets(state) {
            return state.refs.filter_presets;
        },
        tradingViewOptions(state) {
            return state.refs.trading_view_options;
        },
        startFiltersInd(state) {
            // const result = [];
            // state.refs.start_filters_ind.array.forEach((element, i) => {
            //     if (i == state.refs.start_filters_ind.length - 1) {
            //         element.fields = [];
            //     }
            // });
            // return result;
            return state.refs.start_filters_ind;
        },
        startFiltersAutoInversion(state) {
            return state.refs.start_filters_auto_inversion;
        },
        startFiltersStopLoss(state) {

            return Array.isArray(state.refs.start_filters_stop_loss)
                ? state.refs.start_filters_stop_loss
                : [];
        },
        startFiltersStopLossValues(state) {
            if (Array.isArray(state.refs.start_filters_stop_loss)) {
                return state.refs.start_filters_stop_loss.reduce((accum, el) => {
                    accum.push(el.id);
                    const index = el.fields.findIndex(({ name }) => name == 'id');
        
                    if (~index) {
                        if (el.fields[index].input_type == 'select') {
                            el.fields[index].options.forEach(({ value }) => accum.push(value));
                        } else if (el.fields[index]?.value !== undefined) {
                            accum.push(el.fields[index].value);
                        }
                    }
        
                    return accum;
                }, []);
            }

            return [];
            
        },
    },
    actions: {
        clearErrors() {
            this.errorsForm = {};
            this.innerForms = {};
        },
        setInnerForms({ data, startFilters }) {
            Object.keys(data).forEach(key => {
                const { id } = startFilters.find(({ _formPath }) => _formPath == key);

                this.innerForms[id] = {
                    sf: key,
                    ...data[key],
                };
            });
            console.log('data', data);
            console.log('startFilters', startFilters);
            console.log('innerForms', this.innerForms);
        },
    },
});