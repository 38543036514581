<template>
<div class="flex items-center cursor-pointer" @click="show = !show">
    <div class="text-md text-gray-600 dark:text-white/75 font-semibold">{{ bots.localization['bots/tradingview/menu/title'] }}</div>
    <n-icon class="ml-2 transition transform text-base" :class="show ? 'rotate-90' : ''">
        <IosArrowRtl24Filled />
    </n-icon>
</div>
<n-collapse-transition :show="show">
    <div class="mt-4 text-left">
        <div class="flex items-center">
            <rb-checkbox
                :label="'- ' + bots.localization['bots/tradingview/tradingview_enabled']"
                v-model:checked="dataForm.settings.tradingview_enabled" />
            <rb-select
                v-if="dataForm.settings.tradingview_enabled"
                class="w-3/12 pl-2"
                :options="tradingViewOptions"
                :value="tradingViewValue"
                @update:value="tradingViewValue = $event" />
        </div>
        
        <div v-if="dataForm.settings.tradingview_enabled" class="mt-4">
            <!-- trading_view_signal -->
            <template v-if="tradingViewValue == 'trading_view_signal'">
                <n-alert class="rounded-md mt-4" type="warning" size="small">
                    <div class="notes text-xs" v-html="bots.localization?.bots_trading_view_signal_info"></div>
                </n-alert>

                <div class="text-mt text-gray-600 dark:text-white/75 whitespace-nowrap mt-4">
                    {{ bots.localization['bots/tradingview/tradingview_hook_url'] }}
                </div>
                <div
                    class="mt-2 w-full flex flex-row items-center justify-between bg-gray-100 dark:bg-darkbg p-2 rounded-md text-base cursor-pointer"
                    @click="copy(dataForm.settings.tradingview_hook_url)">
                    <!-- {{ dataForm.settings.tradingview_hook_url }} -->
                    https://app.revenuebot.io/external/tv
                    <n-icon class="ml-4 text-main" size="25">
                        <Copy16Regular />
                    </n-icon>
                </div>
                <!-- <n-alert class="rounded-md mt-4" type="warning" size="small">
                    <div class="notes text-xs" v-html="bots.localization['bots/tradingview/tradingview_hook_url/info']"></div>
                </n-alert> -->

                <rb-title
                    class="mt-6"
                    :label="bots.localization['bots/tradingview/tradingview_msg']" />
                <n-card
                    class="relative rounded-md bg-gray-100 dark:bg-darkbg mt-2"
                    content-style="padding: 0;">
                    <n-icon
                        class="absolute top-2 right-2 cursor-pointer text-main"
                        size="25"
                        @click="copy(dataForm.settings.tradingview_msg)">
                        <Copy16Regular />
                    </n-icon>
                    <div class="text-xs pl-2 pr-8 py-2">
                        <n-code
                            word-wrap
                            vertical
                            :code="changeJSON(dataForm.settings.tradingview_msg)"
                            language="javascript" />
                    </div>
                </n-card>
                
                <!-- :help="bots.localization?.message_for_stop_loss_signal_help" -->
                <rb-title
                    class="mt-6"
                    :label="bots.localization?.message_for_stop_loss_signal + ':'" />
                <n-card
                    class="relative rounded-md bg-gray-100 dark:bg-darkbg mt-2"
                    content-style="padding: 0;">
                    <n-icon
                        class="absolute top-2 right-2 cursor-pointer text-main"
                        size="25"
                        @click="copy(changeJSON(dataForm.settings.tradingview_msg, ['change', { 'action': 'stoploss' }]))">
                        <Copy16Regular />
                    </n-icon>
                    <div class="text-xs pl-2 pr-8 py-2">
                        <n-code
                            word-wrap
                            vertical
                            :code="changeJSON(dataForm.settings.tradingview_msg, ['change', { 'action': 'stoploss' }])"
                            language="javascript" />
                    </div>
                </n-card>

                <rb-title
                    class="mt-6"
                    :help="bots.localization?.message_for_signal_to_switch_trading_pair_help"
                    :label="bots.localization?.message_for_signal_to_switch_trading_pair + ':'" />
                <n-card
                    class="relative rounded-md bg-gray-100 dark:bg-darkbg mt-2"
                    content-style="padding: 0;">
                    <n-icon
                        class="absolute top-2 right-2 cursor-pointer text-main"
                        size="25"
                        @click="copy(changeJSON(dataForm.settings.tradingview_msg, ['add', { 'pair': 'link/usdt' }]))">
                        <Copy16Regular />
                    </n-icon>
                    <div class="text-xs pl-2 pr-8 py-2">
                        <n-code
                            word-wrap
                            vertical
                            :code="changeJSON(dataForm.settings.tradingview_msg, ['add', { 'pair': 'link/usdt' }])"
                            language="javascript" />
                    </div>
                </n-card>
                <!-- <n-alert class="rounded-md mt-4" type="warning" size="small">
                    <div class="notes text-xs" v-html="bots.localization['bots/tradingview/tradingview_msg/info']"></div>
                </n-alert> -->
            </template>
            <template v-else-if="tradingViewValue == 'trading_view_strategy'">
                <n-alert class="rounded-md mt-4" type="warning" size="small">
                    <div class="notes text-xs" v-html="bots.localization?.bots_trading_view_signal_strategy"></div>
                </n-alert>

                <div class="text-mt text-gray-600 dark:text-white/75 whitespace-nowrap mt-4">
                    {{ bots.localization['bots/tradingview/tradingview_hook_url'] }}
                </div>
                <div
                    class="mt-2 w-full flex flex-row items-center justify-between bg-gray-100 dark:bg-darkbg p-2 rounded-md text-base cursor-pointer"
                    @click="copy(dataForm.settings.tradingview_hook_url)">
                    {{ dataForm.settings.tradingview_hook_url }}
                    <n-icon class="ml-4 text-main" size="25">
                        <Copy16Regular />
                    </n-icon>
                </div>

                <rb-title
                    class="mt-6"
                    :label="(bots.localization?.message_for_signal || 'Сообщение для сигнала') + ':'" />
                <n-card
                    class="relative rounded-md bg-gray-100 dark:bg-darkbg mt-2"
                    content-style="padding: 0;">
                    <n-icon
                        class="absolute top-2 right-2 cursor-pointer text-main"
                        size="25"
                        @click="copy('{{syminfo.basecurrency}}-{{syminfo.currency}}/{{strategy.order.action}}/{{strategy.market_position}}')">
                        <Copy16Regular />
                    </n-icon>
                    <div class="text-xs pl-2 pr-8 py-3">
                        <n-code
                            word-wrap
                            vertical
                            code="{{syminfo.basecurrency}}-{{syminfo.currency}}/{{strategy.order.action}}/{{strategy.market_position}}"
                            language="json" />
                    </div>
                </n-card>
            </template>
        </div>
    </div>
</n-collapse-transition>
</template>

<script>
// general
import general from './general';

// naive-ui
import {
    NCode,
    NIcon,
    NCard,
    NAlert,
    NButton,
    NDivider,
    NCollapseTransition,
    useNotification } from 'naive-ui';

// components
import RbInput from '@components/rb-input';
import RbSelect from '@components/rb-select';
import RbCheckbox from '@components/rb-checkbox';
import RbPairSelect from '@components/rb-pair-select';

// icons
import { Copy16Regular, Dismiss16Filled, IosArrowRtl24Filled, DeleteDismiss24Regular } from '@vicons/fluent';

export default {
    name: 'bot-trading-view-signals',
    components: {
        NCode,
        NIcon,
        NCard,
        NAlert,
        RbInput,
        NButton,
        NDivider,
        RbSelect,
        RbCheckbox,
        RbPairSelect,
        Copy16Regular,
        Dismiss16Filled,
        IosArrowRtl24Filled,
        NCollapseTransition,
        DeleteDismiss24Regular,
    },
    setup(props, context) {

        return {
            ...general(...arguments),
        };
    },
};
</script>