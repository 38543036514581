// vue
import { ref, watch, computed, reactive } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useBots } from '@store/bots';
import { useRefs } from '@store/ts/refs';

// services
import BotsService from'@services/bots';
import WalletsService from '@services/walletsService';
import ApiKeysService from'@services/apiKeysService';
import ExchangesService from '@services/exchangesService';

// shared
import { CRUD_PAGER_FACTORY, BOTS_DATA_FORM_FACTORY_PREPARE } from '@shared/factories';

// i18n
import { useI18n } from 'vue-i18n';

export default function (props, context) {
    // store
    const gl = useGl();
    const refs = useRefs();
    const bots = useBots();

    // vars
    const dataForm = bots.dataForm;
    const info = reactive({
        liquidPrice: '',
        percPrice: '',
        orderLiquidStr: '',
        coin: '',
        liquid: '',
    });
    const loading = ref(false);
    
    // 18n
    const { t } = useI18n();

    const curWallet = computed(() => bots.walletsRefs.find(({ id }) => id == dataForm.wallet.value));
    const totalFunds = ref(curWallet.value.amount);
    const isAlgoLong = computed(() => dataForm.algo.value == 'long');

    const infoText = () => {
        // isAlgoLong - алгоритм ЛОНГ
        // totalFunds - тот депо, который ввел пользователь
        // curWallet.value.amount - баланс текущего кашелька бота
        // dataForm.leverage.value - плечо
        let orderLiquidStr = '';
        let liquidInfo = (isAlgoLong.value ? bots.localization?.full_liquid_info_text_long : bots.localization?.full_liquid_info_text_short)
            || `Цена ликвида - {{#liquidPrice}} {{#coin}} ({{#percPrice}}% от цены первого ордера) ({{#100percPrice}}% ${isAlgoLong.value ? 'просадки' : 'роста'})`;

        if (isAlgoLong.value) {
            if (totalFunds.value > curWallet.value.amount * dataForm.leverage.value) {
                orderLiquidStr = (bots.localization?.liquidation_will_not_happen || 'Ликвидация не произойдет');
                liquidInfo = '';
            } else if (info.liquidPrice.ordersCount != 0) {
                orderLiquidStr = (bots.localization?.liquidation_will_occur_before_the_execution || 'Ликвидация произойдет до выполнения {{#liquidPrice}} ордера')
                    .replace('{{#liquidPrice}}', `<strong>${info.liquidPrice.ordersCount}</strong>`);
            } else {
                orderLiquidStr = bots.localization?.beyond_the_elimination_grid || 'За пределами сетки ликвидации';
            }
        } else {
            if (info.liquidPrice.ordersCount != 0) {
                orderLiquidStr = (bots.localization?.liquidation_will_occur_before_the_execution || 'Ликвидация произойдет до выполнения {{#liquidPrice}} ордера')
                    .replace('{{#liquidPrice}}', `<strong>${info.liquidPrice.ordersCount}</strong>`);
            } else {
                orderLiquidStr = bots.localization?.beyond_the_elimination_grid || 'За пределами сетки ликвидации';
            }
        }

        return {
            liquidInfo,
            orderLiquidStr,
        };
    };

    const whereIsTheLiquid = async () => {
        loading.value = true;
        info.liquid = null;
        info.orderLiquidStr = null;

        info.liquidPrice = await calcLiquid();

        info.coin = dataForm.pair.value.split('/')[1];
        info.percPrice = (info.liquidPrice.liquidPrice / info.liquidPrice.firstPrice) * 100;

        const { liquidInfo, orderLiquidStr } = infoText();

        info.orderLiquidStr = orderLiquidStr;
        info.liquid =
            liquidInfo
                .replace('{{#liquidPrice}}', `<strong>${info.liquidPrice.liquidPrice.toFixed(6)}</strong>`)
                .replace('{{#coin}}', `<strong>${info.coin?.toUpperCase()}</strong>`)
                .replace('{{#percPrice}}', `<strong>${info.percPrice.toFixed(1)}</strong>`)
                .replace(
                    '{{#100percPrice}}',
                    isAlgoLong.value
                        ? `<strong>${(100 - info.percPrice).toFixed(1)}</strong>`
                        : `<strong>${((100 - info.percPrice) * -1).toFixed(1)}</strong>`,
                );

        loading.value = false;
    };

    const calcLiquid = async () => {
        // totalFunds.value - депо, который указал юзер
        // matrix - сетка ордеров
        // isAlgoLong - алгоритм ЛОНГ

        const matrix = await onViewOrderMatrix();
        
        return isAlgoLong.value
            ? getLongInfo(matrix.orders_matrix, totalFunds.value)
            : getShortInfo(matrix.orders_matrix, totalFunds.value);
    };

    const getLongInfo = (matrix, totalFunds) => { 
        const position = { vol: 0, price: 0 };
        let money = 0;
        let liquidPrice = 0;
        let ordersCount = 0;
        for (let i = 0; i < matrix.length; i++) {
            const order = matrix[i];
            const nextOrder = matrix[i + 1];
            money += Number(order.amount) * Number(order.rate);
            position.vol += Number(order.amount);
            position.price = money / position.vol;
            if (nextOrder != null) {
                const currResult = (Number(nextOrder?.rate || '0') - position.price) * position.vol;

                if (totalFunds < currResult * -1) {
                    liquidPrice = (1 - totalFunds / money) * position.price;
                    ordersCount = i + 2;
                    break;
                }
            } else { 
                ordersCount = 0;
                liquidPrice = (1 - totalFunds / money) * position.price;
            }
        }

        return {
            firstPrice: matrix[0].rate,
            liquidPrice,
            ordersCount,
        };
    };

    const getShortInfo = (matrix, totalFunds) => {
        const position = { vol: 0, price: 0 };
        let money = 0;
        let liquidPrice = 0;
        let ordersCount = 0;
        for (let i = 0; i < matrix.length; i++) {
            const order = matrix[i];
            const nextOrder = matrix[i + 1];
            money += Number(order.amount) * Number(order.rate);
            position.vol += Number(order.amount);
            position.price = money / position.vol;
            if (nextOrder != null) {
                const currResult = (position.price - Number(nextOrder.rate)  ) * position.vol;
                if (totalFunds < currResult * -1) {
                    liquidPrice = (1 + totalFunds / money) * position.price;
                    ordersCount = i + 2;
                    break;
                }
            } else { 
                ordersCount = 0;
                liquidPrice = (1 + totalFunds / money) * position.price;
            }
        }
        
        return {
            firstPrice: matrix[0].rate,
            liquidPrice,
            ordersCount,
        };
    
    };

    const onViewOrderMatrix = async () => {
        const params = BOTS_DATA_FORM_FACTORY_PREPARE(dataForm);
        delete params.notifications;
        delete params.notifications_contacts;

        let result;

        try {
            result = await BotsService.getOrdersMatrixForLogarithmicFactor(params);
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        }

        if (result?.msg) {
            gl.showNotification({
                type:  'error',
                msg: result.msg,
            });
        }

        // show messages
        if (result?.postMessages) {
            result.postMessages.forEach(el => {
                gl.showNotification({
                    type:  el.success ? 'success' : 'error',
                    msg: el.msg,
                });
            });
        }

        if (result?.data?.status) {
            return result.data;
        }
    };

    return {
        gl,
        bots,
        refs,
        info,
        loading,
        totalFunds,
        whereIsTheLiquid,
    };

}