<template>
<base-modal
    v-if="gl.stage"
    width="550"
    :maskClosable="false"
    :showCLoseButton="false"
    v-model:show="showStage">
    <template #headerContent>
        <div class="text-xl">{{ gl.stage.caption }}</div>
    </template>
    <template #default>
        <n-alert class="bg-[#eaf0fe] dark:bg-blue-900 dark:bg-opacity-20 rounded-md mb-2" type="info">
            <div class="font-md">{{ gl.stage.message }}</div>
        </n-alert>
        <template
            v-for="(field, key) in gl.stage.fields" :key="field.title+key">
            <rb-input
                :status="gl.stageErrors[key] ? 'error' : undefined"
                :msg="gl.stageErrors[key]?.msg"
                :label="field.title"
                :placeholder="field.placeholder"
                v-model:value="field.value"
                @update:value="gl.cleanError('stageErrors', key)" />
          </template>
    </template>
    <template #footer>
        <div class="flex justify-end">
            <n-button
                v-if="!!gl.stage.nahLabel"
                secondary
                class="rounded-md flex items-center text-gray-600 dark:text-white/75"
                @click="onCancelStagedResponse">
                {{ gl.stage.nahLabel }}
            </n-button>
            <n-button
                class="rounded-md flex items-center text-white/90 ml-3"
                :loading="loading"
                :disabled="loading"
                :color="gl.mainColor" 
                @click="onVerifyStagedResponse">
                {{ gl.stage.yepLabel }}
            </n-button>
        </div>
    </template>
</base-modal>
<base-modal
    v-if="!!gl.new_build"
    :show="true"
    width="550"
    :maskClosable="false"
    :showCLoseButton="false">
    <template #headerContent>
        <div class="flex items-center text-sm">
            <n-spin size="small" />
            <div class="ml-4 notes">
                <span v-html="gl.new_build?.message"></span>
                <span class="pl-2">{{ gl.new_build?.autorefresh_in }}</span>
            </div>
        </div>
    </template>
</base-modal>
<base-dialog-desktop
    :show="confirm.show"
    :disabled="confirm.showCheck && confirm.disabledByChecked ? !confirm.genConfCheck : false"
    @positive-click="confirm.doFn"
    @update:show="confirm.closeConfirm"
    @negative-click="confirm.closeConfirm">
    <div v-if="confirm.title" class="text-sm text-gray-500 dark:text-white/75" v-html="confirm.title"></div>
    <div v-if="confirm.declineMessage" class="text-sm text-gray-500 dark:text-white/75" v-html="confirm.declineMessage"></div>
    <template #footerCheck v-if="confirm.showCheck">
        <rb-checkbox
            :nowrap="false"
            :label="confirm.confirmLabel"
            v-model:checked="confirm.genConfCheck" />
    </template>
</base-dialog-desktop>
</template>

<script>
// vue
import {ref, watch, onMounted } from 'vue';

// naive-ui
import {
    NSpin,
    NAlert,
    NButton,
    useNotification } from 'naive-ui';

// services
import Twostage from '@services/twostage';

// i18n
import { useI18n } from 'vue-i18n';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useConfirm } from '@store/confirm';

// components
import RbInput from '@components/rb-input';
import BaseModal from '@components/base/base-modal';

export default {
    name: 'root-component',
    components: {
        NSpin,
        NAlert,
        RbInput,
        NButton,
        BaseModal,
    },
    setup() {
        // store
        const gl = useGl();
        const refs = useRefs();
        const confirm = useConfirm();

        // i18n
        const { t } = useI18n();

        // vars
        const { _ } = window;
        const loading = ref(false);
        const showStage = ref();

        watch(() => gl.serverError, v => {
            if (v) gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        });

        watch(() => gl.stage, v => {
            if (v) showStage.value = true;
        });

        watch(showStage, v => {
            // if (!v) gl.stage = null;
        });

        const onCancelStagedResponse = async () => {
            gl.showLoading = true;
            const data = await Twostage.cancel({
                fields: _.get(gl.stage, 'fields', {}),
                extra: _.get(gl.stage, 'extra', {}),
                _formPath: 'GSR',
            });

            gl.stage = null;
            gl.stageState = 'canceled';

            // show messages
            data.postMessages.forEach(el => {
                gl.showNotification({
                    type: el.success ? 'success' : 'error',
                    msg: el.msg,
                });
            });

            showStage.value = false;

            gl.showLoading = false;
        };

        const onVerifyStagedResponse = async () => {
            loading.value = true;

            const data = await Twostage.execute({
                fields: _.get(gl.stage, 'fields', {}),
                extra: _.get(gl.stage, 'extra', {}),
                _formPath: 'GSR',
            });

            if (!data.data.status) {
                gl.stageErrors = data.data.errors_form['GSR'].fields;
            } else {
                // show messages
                // data.data.messages.forEach(el => {
                //     message.success(el.msg);
                // });

                gl.stageExtra = data.data;
                gl.stageState = 'inactive';

                showStage.value = false;
            }

            loading.value = false;
        };

        onMounted(() => {
            gl.notification = useNotification();
        });

        return {
            gl,
            refs,
            loading,
            confirm,
            showStage,
            onCancelStagedResponse,
            onVerifyStagedResponse,
        };
    },
};
</script>