<template>
<div v-if="init">
    <n-card
        size="small"
        :bordered="false"
        class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-md"
        :segmented="{
          content: true,
        }">
        <rb-select
            :label="dataForm.exchange.title"
            :help="dataForm.exchange.dataHelp"
            :options="exchangeOptions"
            :status="dataForm.exchange.status === 'error' ? 'error' : undefined"
            :msg="dataForm.exchange.msg"
            v-model:value="dataForm.exchange.value"
            @update:value="dataForm.exchange.status = null, dataForm.exchange.msg = null" />

        <rb-select
            v-if="dataForm.market.show"
            class="mt-4"
            render="currencies"
            :label="dataForm.market.title"
            :help="dataForm.market.dataHelp"
            :options="currencyOptions"
            :status="dataForm.market.status === 'error' ? 'error' : undefined"
            :msg="dataForm.market.msg"
            :defaultValue="-1"
            v-model:value="dataForm.market.value"
            @update:value="dataForm.market.status = null, dataForm.market.msg = null" />

        <template v-if="dataForm.use_bw_list.show">
            <n-divider />
            <rb-switch
                largeHelp
                :label="dataForm.use_bw_list.title"
                :help="dataForm.use_bw_list.dataHelp"
                v-model:value="dataForm.use_bw_list.value" />
            
            <n-divider class="my-4" />
            <template v-if="dataForm.use_bw_list.value">

                <div class="flex justify-between">
                    <rb-switch
                        :label="tools.localization['tools_volatility_analyzer_set_white_list']"
                        :value="dataForm.is_white_list"
                        @update:value="dataForm.is_white_list = $event"/>

                    <rb-switch
                        :label="tools.localization['tools_volatility_analyzer_set_black_list']"
                        :value="!dataForm.is_white_list"
                        @update:value="dataForm.is_white_list = !$event"/>
                </div>

                <n-divider class="my-4" />
                <rb-select
                    :label="tools.localization['tools/va/settings/coin_list/select_strategy']"
                    :options="coinListStrategiesOptions"
                    :help="tools.help['coin_list_strategy']"
                    v-model:value="dataForm.coin_list_strategy" />

                <rb-select
                    v-if="dataForm.coin_list_strategy != -1 && dataForm.coin_list_strategy != 'local'"
                    clearable
                    class="mt-4"
                    :label="tools.localization['tools/va/settings/coin_list/shared_coin_list']"
                    :options="sharedCoinListItems"
                    :help="tools.help['shared_coin_list']"
                    v-model:value="dataForm.shared_coin_list" />
            </template>
            
            <section v-if="dataForm.use_bw_list.value">
                <n-divider />
                <!-- coin list selector -->
                <div v-if="canShowSharedCoinList" class="flex flex-col items-end">
                    <rb-coin-list
                        multiple
                        disabled
                        class="w-full"
                        :label="tools.localization['tools/va/settings/coin_list/shared_list_coins']"
                        :dataHelp="tools.help['shared_list_coins']"
                        :value="sharedCoinList"
                        :options="sharedCoinListRef" />
                    <!-- @click.prevent="router.push({name: 'tools.va', query: { tab: 'rbpsCoinList' }})" -->
                    <n-button
                        strong
                        class="text-white/90 rounded-md mt-4"
                        :color="gl.mainColor">
                        {{ tools.localization['tools/va/settings/coin_list/create_update_coin_list'] }}
                    </n-button>
                    <n-divider />
                </div>
                <div v-if="dataForm.coin_list_strategy != 'shared' && dataForm.coin_list_strategy != -1">
                    <rb-coin-list
                        multiple
                        :label="tools.localization['tools_volatility_analyzer_black_white_lists_currencies_list_f']"
                        v-model:value="dataForm.bw_list"
                        :options="symbolsForMarketRefsOptions" />
                    <!-- @click.prevent="router.push({name: 'tools.va', query: { tab: 'rbpsCoinList' }})" -->
                    <n-divider />
                </div>
            </section>
                
            <template v-if="marketSelected">
                <div class="flex justify-between items-end">
                    <rb-input
                        class="w-6/12"
                        :label="dataForm.period_value.title"
                        :help="dataForm.period_value.dataHelp"
                        :status="dataForm.period_value.status === 'error' ? 'error' : undefined"
                        :msg="dataForm.period_value.msg"
                        v-model:value="dataForm.period_value.value"
                        @update:value="dataForm.period_value.status = null, dataForm.period_value.msg = null" />

                    <rb-select
                        class="w-6/12 pl-2"
                        :options="periodUnitsOptions"
                        v-model:value="dataForm.period_unit" />
                </div>

                <div class="flex justify-between items-end mt-4">
                    <rb-input
                        class="w-6/12"
                        :label="dataForm.interval_value.title"
                        :help="dataForm.interval_value.dataHelp"
                        :status="dataForm.interval_value.status === 'error' ? 'error' : undefined"
                        :msg="dataForm.interval_value.msg"
                        v-model:value="dataForm.interval_value.value"
                        @update:value="dataForm.interval_value.status = null, dataForm.interval_value.msg = null" />

                    <rb-select
                        class="w-6/12 pl-2"
                        :options="intervalUnitsOptions"
                        v-model:value="dataForm.interval_unit" />
                </div>
                <n-divider />
            </template>
            
            <template v-if="marketSelected">
                <rb-input
                    :label="dataForm.rate_change.title"
                    :help="dataForm.rate_change.dataHelp"
                    :status="dataForm.rate_change.status === 'error' ? 'error' : undefined"
                    :msg="dataForm.rate_change.msg"
                    :placeholder="dataForm.rate_change.placeholder"
                    v-model:value="dataForm.rate_change.value"
                    @update:value="dataForm.rate_change.status = null, dataForm.rate_change.msg = null" />
                <rb-input
                    class="mt-4"
                    :label="dataForm.min_rate_changes_count.title"
                    :help="dataForm.min_rate_changes_count.dataHelp"
                    :status="dataForm.min_rate_changes_count.status === 'error' ? 'error' : undefined"
                    :msg="dataForm.min_rate_changes_count.msg"
                    :placeholder="dataForm.min_rate_changes_count.placeholder"
                    v-model:value="dataForm.min_rate_changes_count.value"
                    @update:value="dataForm.min_rate_changes_count.status = null, dataForm.min_rate_changes_count.msg = null" />
                <rb-input
                    class="mt-4"
                    :label="dataForm.min_trade_volume.title"
                    :help="dataForm.min_trade_volume.dataHelp"
                    :status="dataForm.min_trade_volume.status === 'error' ? 'error' : undefined"
                    :msg="dataForm.min_trade_volume.msg"
                    :placeholder="dataForm.min_trade_volume.placeholder"
                    v-model:value="dataForm.min_trade_volume.value"
                    @update:value="dataForm.min_trade_volume.status = null, dataForm.min_trade_volume.msg = null" />
                <rb-input
                    class="mt-4"
                    :label="dataForm.min_price.title"
                    :help="dataForm.min_price.dataHelp"
                    :status="dataForm.min_price.status === 'error' ? 'error' : undefined"
                    :msg="dataForm.min_price.msg"
                    :placeholder="dataForm.min_price.placeholder"
                    v-model:value="dataForm.min_price.value"
                    @update:value="dataForm.min_price.status = null, dataForm.min_price.msg = null" />
                <n-divider />
            </template>

            <template v-if="marketSelected">
                <section>
                    <div class="flex mb-4">
                        <div class="text-xs text-gray-600 dark:text-white/75 whitespace-nowrap">
                            {{ tools.localization['tools_volatility_analyzer_pump_dump_filters_title'] }}
                        </div>
                        <n-popover
                            scrollable
                            trigger="hover"
                            class="max-w-[200px] max-h-[200px] rounded-md bg-main"
                            placement="top">
                                <template #trigger>
                                    <n-icon class="text-main cursor-pointer text-xl mx-2">
                                        <QuestionCircle20Filled />
                                    </n-icon>
                                </template>
                            <span class="text-xs text-white" v-html="tools.help['switch_pd_filters']"></span>
                        </n-popover>
                    </div>
                    
                    <section class="flex flex-wrap">
                        <div
                            v-for="(pd_filter, i) in dataForm.pd_filters" :key="i"
                            class="pb-4">
                            <n-card
                                size="small"
                                class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-md relative"
                                :segmented="{
                                    content: true,
                                    footer: 'soft'
                                }">
                                <n-icon
                                    class="text-red-500 text-2xl ml-4 cursor-pointer absolute top-2 right-2"
                                    @click="onDeletePDFilter(i)">
                                    <DeleteDismiss24Regular />
                                </n-icon>
                                
                                <div class="flex mt-4">
                                    <rb-input
                                        class="w-6/12 pr-1"
                                        :label="tools.localization['tools_volatility_analyzer_pump_dump_filter_period']"
                                        :help="tools.help['switch_pd_period']"
                                        v-model:value="dataForm.pd_filters[i].pd_period" />
                                    <rb-input
                                        class="w-6/12 pl-1"
                                        :label="tools.localization['tools_volatility_analyzer_pump_dump_filter_rate_change_percent']"
                                        :help="tools.help['switch_pd_rate_change']"
                                        v-model:value="dataForm.pd_filters[i].pd_rate_change" />
                                </div>
                            </n-card>
                        </div>

                        <n-button
                            strong
                            secondary
                            class="w-full rounded-md"
                            @click="onAddPDFilter">
                            {{ tools.localization['tools_volatility_analyzer_pump_dump_filters_add_filter_button'] }}
                        </n-button>
                    </section>
                </section>
                
                <template v-if="marketSelected">
                    <n-button
                        class="text-white/90 rounded-md mt-4 w-full"
                        :class="!vaResultsFetched ? 'w-full' : ''"
                        :color="gl.mainColor"
                        @click="onRunPreset">
                        {{ tools.localization['tools_volatility_analyzer_run_analyzer_button'] }}
                    </n-button>
                    <n-checkbox
                        v-if="vaResultsFetched"
                        class="mt-4 text-white/90 rounded-md w-full"
                        :label="tools.localization['tools_volatility_analyzer_save_preset_title']"
                        v-model:checked="continueSavingPreset">
                    </n-checkbox>
                </template>

                <template v-if="continueSavingPreset">
                    <n-divider />
                    <rb-input
                        class="mt-4"
                        :label="tools.localization['tools_volatility_analyzer_save_preset_name_f']"
                        :placeholder="tools.localization['tools_volatility_analyzer_save_preset_descr_f']"
                        :status="errorsPresetField.name.status ? 'error' : ''"
                        :msg="errorsPresetField.name.msg"
                        v-model:value="dataForm.title"
                        @update:value="errorsPresetField.name.status = null, errorsPresetField.name.msg = null" />
                    <rb-input
                        class="mt-4"
                        typeField="textarea"
                        :label="tools.localization['tools_volatility_analyzer_save_preset_description_f']"
                        :placeholder="tools.localization['tools_volatility_analyzer_save_preset_description_i']"
                        v-model:value="dataForm.description" />
                    <n-button
                        class="mt-4 w-full text-white/90 rounded-md"
                        :color="gl.mainColor"
                        @click="onSavePreset">
                        {{ tools.localization['tools_volatility_analyzer_save_preset_save_button'] }}
                    </n-button>
                    <n-divider />
                </template>
            </template>
                
            <super-table
                v-if="vaResultsFetched"
                ref="botTvaResultsBlock"
                :refresh="false"
                :showFooter="false"
                :pager="vaResultsFetched.pager"
                :columns="vaResultsFetched.columns"
                :records="vaResultsFetched.records"
                columnsStorageName="__columns_volatility-analyzer">
            </super-table>
        </template>
    </n-card>
</div>
</template>

<script>
// general
import general from './general';

// components
import RbInput from '@components/rb-input/mobile.vue';
import RbSelect from '@components/rb-select/mobile.vue';
import RbSwitch from '@components/rb-switch/mobile.vue';
import RbCoinList from '@components/rb-coin-list';
import RbCurrencies from '@components/rb-currencies';
import SuperTable from '@components/super-table/mobile.vue';

// icons
import { Dismiss16Filled, DeleteDismiss24Regular, QuestionCircle20Filled } from '@vicons/fluent';

// UI
import {
    NIcon,
    NCard,
    NButton,
    NDivider,
    NPopover,
    NCheckbox } from 'naive-ui';

export default {
    name: 'newTemplate',
    components: {
        NIcon,
        NCard,
        RbInput,
        NButton,
        NDivider,
        RbSelect,
        NPopover,
        RbSwitch,
        NCheckbox,
        SuperTable,
        RbCoinList,
        RbCurrencies,
        Dismiss16Filled,
        DeleteDismiss24Regular,
        QuestionCircle20Filled,
    },
    setup(props, context) {
        
        return {
            ...general(...arguments),
        };
    },
};
</script>