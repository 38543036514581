// vue
import { h, ref, computed, reactive, watch, onMounted } from 'vue';
import moment from 'moment';

// services
import BotsService from '@services/bots';
import StatsService from '@services/statsService';
import LocalStorage from '@services/ts/localStorage';

// shared
import { CRUD_PAGER_FACTORY } from '@shared/factories';

// router
import { useRouter } from 'vue-router';

// i18n
import { useI18n } from 'vue-i18n';


// store
import { useGl } from '@store/ts/gl';
import { useEnv } from '@/store/ts/env';
import { useRefs } from '@store/ts/refs';
import { useStats } from '@store/stats';
import { useBots } from '@store/bots';
import { useConfirm } from '@store/confirm';

// UI
import {
    NCheckbox,
    useNotification } from 'naive-ui';

// icons
import {
    Edit20Filled,
    Play16Regular,
    Stop20Regular,
    CopyAdd24Regular,
    RecordStop28Regular,
    ArrowSync12Regular,
    PauseCircle24Regular,
    CopySelect20Filled,
    ArrowDownload16Filled,
    DeleteDismiss24Regular,
    ArchiveMultiple16Filled } from '@vicons/fluent';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const download = require('downloadjs');

export default function (props, context) {
    // store
    const gl = useGl();
    const env = useEnv();
    const refs = useRefs();
    const bots = useBots();
    const stats = useStats();
    const confirm = useConfirm();

    // naive-ui
    const notification = useNotification();

    // i18n
    const { t } = useI18n();

    // router
    const router = useRouter();

    // ranges
    const ranges = computed(() => refs.ranges);

    // vars
    const { _ } = window;
    const init = ref(false);
    const loading = ref(false);
    const actionType = ref();
    const revenueStats = ref();
    const cloneMoreShowId = ref();
    const showConfirmation = ref(false);
    const loadings = reactive({
        table: false,
    });
    const sumWallets = ref('active_wallets');
    const critPers = ref();

    // storage filters
    const storeFilterKey = '___filters_stats';
    let storeFilters = LocalStorage.getItem(storeFilterKey);

    // period
    const localDefaultPeriod = ref();
    const defaultPeriod = moment().subtract(7, 'days').format('YYYY-MM-DD') + ' ' + moment().add(7, 'days').format('YYYY-MM-DD');
    const storagePeriod = () => {
        const i = ranges.value.findIndex(({ id }) => id == storeFilters?.filters.period);

        const result = ~i
            ? ranges.value[i].value.join(' ')
            : storeFilters?.filters.period || defaultPeriod;

        if (!localDefaultPeriod.value) {
            localDefaultPeriod.value = result;
        }

        return result;
    };

    // set filter settings
    if (storeFilters == null) {
        LocalStorage.setItem(storeFilterKey, CRUD_PAGER_FACTORY({
            bots: {},
            exchanges: [-1],
            pairs: [-1],
            period: storagePeriod(),
            statuses: [-1],
            states: [-1],
            include_rates: false,
            include_rates_changed: false,
            targets: ['bots'],
            tags: [-1],
        }, {
            perPage: 10,
        }));

        storeFilters = LocalStorage.getItem(storeFilterKey);
    }
    
    const filters = reactive({
        ...storeFilters,
    });

    const filtersChanged = computed(() => {
        return Object.keys(filters.filters.bots).length
          || filters.filters.exchanges[0] !== -1
          || filters.filters.pairs[0] !== -1
          || filters.filters.statuses[0] !== -1
          || filters.filters.states[0] !== -1
          || filters.filters.include_rates
          || filters.filters.include_rates_changed
          || filters.filters.targets[0] !== 'bots'
          || filters.filters.tags[0] !== -1
          || filters.filters.period !== defaultPeriod;
    });

    const resetFilters = () => {
        filters.filters.bots = {};
        filters.filters.exchanges = [ -1 ];
        filters.filters.pairs = [ -1 ];
        filters.filters.period = localDefaultPeriod.value || defaultPeriod;
        filters.filters.statuses = [ -1 ];
        filters.filters.states = [ -1 ];
        filters.filters.include_rates = false;
        filters.filters.include_rates_changed = false;
        filters.filters.targets = ['bots'];
        filters.filters.tags = [-1];

        // set default period
        // LocalStorage.setItem('__filters_stats_period', localDefaultPeriod.value);
      
        botsGet();
    };
    const refFilters = computed(() => [
        {
            mode: 'exchanges',
            title: refs.localization.filters.stats.filters_stats_exchanges_label,
            refs: refs.exchanges,
            filter: 'filters.exchanges',
            seeable: filters.filters.exchanges[0] !== -1,
        }, {
            type: 'render',
            mode: 'date_picker',
            value: periods.value,
            filter: 'filters.period',
            title: refs.localization.filters.stats.filters_stats_period_label,
            seeable: true,
        }, {
            mode: 'bots',
            title: refs.localization.filters.stats.filters_stats_bots_label,
            refs: stats.botFilters,
            filter: 'filters.bots',
            seeable: !!Object.keys(filters.filters.bots).length,
        }, {
            mode: 'checkbox_group',
            title: refs.localization.filters.stats.filters_stats_statuses_label,
            refs: refs.botStatuses,
            filter: 'filters.statuses',
            seeable: filters.filters.statuses[0] !== -1,
        }, {
            mode: 'checkbox_group',
            title: refs.localization.filters.stats.filters_stats_states_label,
            refs: refs.botStates,
            filter: 'filters.states',
            seeable: filters.filters.states[0] !== -1,
        }, {
            mode: 'checkbox_group',
            title: refs.localization.filters.stats.filters_stats_taxonomy_label,
            refs: stats.tags,
            filter: 'filters.tags',
            seeable: filters.filters.tags[0] !== -1,
        }, {
            mode: 'filter_switch',
            value: filters.filters.include_rates,
            title: refs.localization.filters.stats.filters_stats_withdraw_rates_label,
            filter: 'filters.include_rates',
            seeable: !!filters.filters.include_rates,
        }, {
            mode: 'filter_button_groupe',
            refs: radios.value,
            value: filters.filters.targets[0],
            title: refs.localization.filters.stats.filters_stats_grouping_label,
            filter: 'filters.targets',
            seeable: filters.filters.targets[0] !== 'bots',
        },
    ]);
    const someCoins = {
        profit: 'profit_coin',
        profit_usdt: 'profit_usdt_coin',
        profit_wo_comm: '_profit_wo_comm_symbol',
        profit_percent: 'profit_percent',
        comm_amount_btc: '_comm_amount_btc_symbol',
        comm_amount_usdt: '_comm_amount_usdt_symbol',
        rb_comm_amount_btc: 'rb_comm_amount_btc_coin',
        rb_comm_amount_usdt: 'rb_comm_amount_usdt_coin',
        profit_usdt_wo_comm: '_profit_usdt_wo_comm_symbol',
    };

    // const actions = computed(() => [
    //     'bots__start',
    //     'bots__stop',
    //     'bots__pause',
    //     'bots__restart',
    //     'bots__edit',
    //     'bots__delete',
    //     'bots__clone',
    //     'bots__archive',
    //     'bots__force_stop',
    // ]);
    const actions = computed(() => [
        {
            id: 'bots__start',
            icon: row => Play16Regular,
            fn: row => canDoAction('start', row),
            popover: row => stats.localization?.['trade_statistics_management_start_button'],
        }, {
            id: 'bots__stop',
            icon: row => Stop20Regular,
            fn: row => canDoAction('stop', row),
            popover: row => stats.localization?.['trade_statistics_management_stop_button'],
        }, {
            id: 'bots__pause',
            icon: row => PauseCircle24Regular,
            fn: row => canDoAction('pause', row),
            popover: row => stats.localization?.['trade_statistics_bots_table_actions_pause'],
        }, {
            id: 'bots__restart',
            icon: row => ArrowSync12Regular,
            fn: row => canDoAction('restart', row),
            popover: row => stats.localization?.['trade_statistics_management_restart_button'],
        }, {
            id: 'bots__edit',
            icon: row => Edit20Filled,
            fn: row => router.push({ name: 'bots.edit', params: { id: row.id } }),
            popover: row => stats.localization?.['trade_statistics_management_edit_button'],
        }, {
            id: 'bots__clone',
            icon: row => CopyAdd24Regular,
            fn: row => router.push({ name: 'bots.clone', params: { id: row.id } }),
            popover: row => stats.localization?.['trade_statistics_bots_table_clone'],
        }, {
            id: 'bots__clone_more',
            type: 'render',
            icon: row => CopySelect20Filled,
            fn: row => cloneMoreShowId.value = row.id,
            popover: row => stats.localization?.['available_bots_table_mass_more_clone'] || 'Клонировать несколько ботов',
            tag: {
                type: 'warning',
                text: 'new',
            },
        }, {
            id: 'bots__download_config',
            icon: row => ArrowDownload16Filled,
            fn: row => downloadConfig(row.id),
            popover: row => stats.localization?.['download_config'] || 'Скачать конфигурацию',
            tag: {
                type: 'warning',
                text: 'new',
            },
        }, {
            id: 'bots__archive',
            icon: row => ArchiveMultiple16Filled,
            fn: row => row.status == -5 ? canDoAction('unarchive', row) : canDoAction('archive', row),
            popover: row => row.status == -5
                ? stats.localization?.['trade_statistics_bots_table_unarchive']
                : stats.localization?.['trade_statistics_bots_table_archive'],
        }, {
            id: 'bots__force_stop',
            icon: row => RecordStop28Regular,
            fn: row => canDoAction('force_stop', row),
            popover: row => stats.localization?.['trade_statistics_bots_table_mass_force_stop'],
        }, {
            id: 'bots__delete',
            textType: 'error',
            icon: row => DeleteDismiss24Regular,
            fn: row => canDoAction('delete', row),
            popover: row => stats.localization?.['trade_statistics_bots_table_mass_delete_button'],
        },
    ]);

    const periods = computed(() => {
        const arr = filters.filters.period?.split(' ');

        return [new Date(arr[0]).getTime(), new Date(arr[1]).getTime()];
    });

    const dashProfit = computed(() => (
        {
            title: stats.localization['trade_statistics_total_profit'],
            data: stats.botsDataTable?.revenue_stats,
            ciclos: stats.botsDataTable?.revenue_stats?.cycles.completed,
            stats: !stats.botsDataTable?.revenue_stats ? [] : (Object.entries(stats.botsDataTable?.revenue_stats.coins).map(([key, value]) => [...value, key]))
                .sort(([ , , a], [ , , b]) => {
                    if (a < b)
                        return -1;
                    if (a > b)
                        return 1;
                    return;
                }),
        }),
    );
    const isBots = computed(() => filters.filters.targets[0] === 'bots');
    const currentTable = computed(() => {
        return stats.isBots
            ? stats.botsDataTable
            : stats.datesDataTable;
    });
    const isDates = computed(() => filters.filters.targets[0] === 'dates');
    const actionButtonsDisabled = computed(() => stats.botsDataTable.records ? stats.botsDataTable.records.some(({ checked }) => checked) : false);

    const radios = computed(() => [
        {
            value: stats.targetFilters[0].id,
            label: stats.targetFilters[0].title,
        }, {
            value: stats.targetFilters[1].id,
            label: stats.targetFilters[1].title,
        },
    ]);

    const optionsSumWallets = computed(() => {
        return [
            {
                value: 'active_wallets',
                label: stats.localization?.sum_of_wallets_launched || 'Сумма запущенных кошельков',
            }, {
                value: 'all_wallets',
                label: stats.localization?.sum_of_all_wallets || 'Сумма всех кошельков',
            },
        ];
    });

    const dataSumWallets = computed(() => {
        const result = [];

        if (currentTable.value?.records?.length) {
            const list = currentTable.value.records.filter(el => {
                if (sumWallets.value == 'active_wallets') {
                    return el?.status == 1 ? true : false;
                }

                return true;
            });

            list.forEach(el => {
                const key = el.leverage != null ? `${el.leverage}${el._depo_symbol?.toUpperCase()}` : el._depo_symbol?.toUpperCase();
                const walletData = result.find(wallet => wallet.key == key);
                const depo = Number(el.depo);
                const fixedCount = el._depo_symbol == 'usdt' ? 2 : 8;

                if (!isNaN(depo)) {
                    if (walletData) {
                        walletData.value = parseFloat((walletData.value + depo).toFixed(fixedCount));
                    } else {
                        result.push({
                            key,
                            value: parseFloat(depo.toFixed(fixedCount)),
                            coin: el._depo_symbol,
                        });
                    }
                }
            });
        }

        return result;
    });

    const downloadConfig = async id => {
        gl.showLoading = true;
    
        const config = await getFullInfo(id);

        if (!bots.refs) {
            bots.refs = ( await BotsService.getRefs() ).data;
        }
    
        const configName = `Config - ${config.id}.json`;
    
        delete config.id;
        delete config.status;
        delete config.settings.tradingview_hook_url;
        delete config.settings.tradingview_msg;
        delete config.tags;
        delete config.notes;
        delete config.tradingview_msg;
        delete config.tradingview_hook_url;
        delete config.use_custom_notifications;
        delete config.reloaded_message;
    
        config.wallet = -1;
        config.api_key = -1;
        config.notifications = [];
        config.notifications_contacts = {
            email: [],
            sms: [],
        };
    
    
        if (config.settings.order_matrix != null && config.settings.order_matrix != -1) {
            config.settings.matrix_list = bots.ordersMatrix.find(({ id }) => id == config.settings.order_matrix);
        }
    
        download(JSON.stringify(config), configName, 'application/json');
    
        gl.showLoading = false;
    };

    const getFullInfo = async id => {
        try {
            const result = await BotsService.getFullInfo(id);

            if (!result.data.status) {
                result.data.errors.forEach(({ msg }) => {
                    gl.showNotification({
                        type: 'error',
                        msg,
                    });
                });
                // router.push({ name: 'bots.review' });
            } else {
                return result.data.record;
            }
        } catch  {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        }
    };

    const columns = computed(() => {
        if (currentTable.value?.columns) {
            const arr = [...currentTable.value.columns]
                .map(el => {
                    if (el.name === '_cycles_completed') {
                        el.title = t('columns._cycles_completed');
                    }

                    return Object.prototype.hasOwnProperty.call(el, 'isHidden')
                        ? reactive({
                            ...el,
                        })
                        : reactive({
                            isHidden: false,
                            ...el,
                        });
                })
                .filter(({ title }) => title);
          
            if (stats.isBots)
                arr.unshift({
                    key: 'checked',
                    name: 'checked',
                    isHidden: false,
                    title() {
                        return h(NCheckbox, {
                            checked: currentTable.value?.records.every(({ checked }) => checked),
                            onUpdateChecked(v) {
                                currentTable.value.records.forEach(el => el.checked = v);
                            },
                        });
                    },
                });
          
            // console.log('columns', arr);
            return arr;
        } else return [];
    });

    // watch(() => refs.lang, async () => {
    //     await getData();
    // });

    const changeFilter = ([ key, value, range ], update = false) => {
        const canUpdate = key === 'query' && !value && currentTable.value.pager.query;

        if (key !== 'page') {
            _.set(filters, 'page', 1);
        }

        if (key === 'perPage') {
            LocalStorage.setItem(storeFilterKey, value);
        }

        if (key === 'filters.period') {
            LocalStorage.setItem('__filters_stats_period', range || value);
        }

        if (key === 'filters.targets') {
            _.set(filters, key, value !== null && value !== undefined ? [ value ] : [ -1 ]);
        } else {
            _.set(filters, key, value !== null && value !== undefined ? value : [ -1 ]);
        }
      
        if (update || canUpdate)
            botsGet();
    };

    const canDoAction = async (curAction, row) => {
        gl.showLoading = true;

        if (curAction === 'start') {
            try {
                const result = await BotsService.canDoAction({
                    id: row.id,
                    action: curAction,
                });

                if (result) {
                    if (result.data.confirm_label && result.data.decline_message) {
                        confirm.openConfirm({
                            confirmLabel: result.data.confirm_label,
                            declineMessage: result.data.decline_message,
                            fn: () => onGeneralConfirmClicked(curAction, row),
                            type: 'confirm_check',
                        });
                    } else {
                        confirm.openConfirm({
                            title: refs.localization.confirmations.bot?.[curAction],
                            fn: () => onGeneralConfirmClicked(curAction, row),
                        });
                    }
                }
            } catch (e) {
                console.log(e);
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            };
        }  else {
            confirm.openConfirm({
                title: refs.localization.confirmations.bot?.[curAction],
                fn: () => onGeneralConfirmClicked(curAction, row),
            });
        }

        gl.showLoading = false;
    };

    const onGeneralConfirmClicked = async (curAction, row) => {
        gl.showLoading = true;

        try {
            let result;

            if (curAction === 'delete') {
                result = await BotsService.delete({
                    ids: [row.id],
                });
            } else {
                result = await BotsService.action({
                    id: row.id,
                    action: curAction,
                });
            }

            if (result) {
                // show messages
                if (result.postMessages)
                    result.postMessages.forEach(el => {
                        gl.showNotification({
                            type: el.success ? 'success' : 'error',
                            msg: el.msg,
                        });
                    });

                if (result.data.status) {
                    botsGet();
                }
            }
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        gl.showLoading = false;
    };

    const botsGet = async (showLoading = true) => {
        if (showLoading) {
            loadings.table = true;
            // gl.showLoading = true;
        }
            
        // save filters
        LocalStorage.setItem(storeFilterKey, filters);
          
        try {
            const prepare = await StatsService.botsGet({
                pager: filters,
            });

            const key = isBots.value ? 'botsDataTable' : 'datesDataTable';
            if (isBots.value) {
                const i = prepare.data.columns.findIndex(({ name }) => name == 'cycles');

                if (~i) {
                    prepare.data.columns.splice(i, 0, {
                        isSortable: true,
                        name: 'p_n_l',
                        title: 'PNL',
                    });
                }

                setTimeout(() => {
                    calculatePercentDeviation(false);
                });
            }

            if (stats[key]) {
                Object.keys(prepare.data).forEach(el => {
                    if(!['columns', 'revenue_stats'].includes(el)) {
                        stats[key][el] = prepare.data[el];
                    };
                });
            } else {
                stats[key] = prepare.data;
            }

            stats.isBots = isBots.value;
            revenueStats.value = prepare.data.revenue_stats;

            if (stats[key].records.length)
                stats[key].records.push({
                    type: 'footer',
                    ...stats[key].totals,
                });
          
        } catch (e) {
            console.log(e);
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        if (showLoading) {
            loadings.table = false;
            // gl.showLoading = false;
        }
    };

    const currency = icon => refs.currencies.find(el => el.id === icon);

    const doSomething = ([ action, value ]) => {
        const fns = {
            onQuestionInfo: () => {
                // showQuestionInfo.value = true;
                // questionInfo.value = marketplace.iMentorRecords.find(({ id }) => id === value);
            },
        };

        // fns[action]();
    };

    const groupAction = async action => {
        gl.showLoading = true;
        showConfirmation.value = false;

        try {
            const result = await BotsService.groupAction({
                ids: [...stats.botsDataTable.records.filter(({ checked }) => checked).map(({ id }) => id)],
                action,
            });

            if (result) {
                if (result.postMessages)
                    result.postMessages.forEach(el => {
                        gl.showNotification({
                            type: el.success ? 'success' : 'error',
                            msg: el.msg,
                        });
                    });

                await botsGet();
            }
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        stats.botsDataTable.records.forEach(el => el.checked = false);

        gl.showLoading = false;
    };

    const letsShowConfirmation = action => {
        actionType.value = action;
        showConfirmation.value = true;
    };

    const oneBotIsDone = $event => {
        stats.botsDataTable.records.unshift($event);
    };

    const calculatePercentDeviation = (colorize = true) => {
        const localCritPers = Number(critPers.value || 10);

        currentTable.value.records.forEach((item, i) => {
            const price = item.prices;

            if (price == null) {
                return;
            }
            
            if (price.includes('NA')) {
                return;
            }

            if (price.includes('%)')) {
                const clearText = price.replace(/\(.+\)/g, '');
                currentTable.value.records[i].prices_draft = clearText;
            }

            const [course, fixPrice, zeroPrice] = price.split('\n');
            let fixDelta = null;
            let zeroDelta = null;
            const isLong = item.algo == 'long';
            if (!isLong) {
                fixDelta = -Number(fixPrice) + Number(course);
                zeroDelta = -Number(zeroPrice) + Number(course);
            } else {
                fixDelta = Number(fixPrice) - Number(course);
                zeroDelta = Number(zeroPrice) - Number(course);
            }
            const fixPerc = ((fixDelta / Number(course)) * 100).toFixed(2);
            const zeroPerc = ((zeroDelta / Number(course)) * 100).toFixed(2);
            const newText = `${course}\n${fixPrice} (${fixPerc}%)\n${zeroPrice} (${zeroPerc}%)`;
            currentTable.value.records[i].prices_draft = newText;
            item['p_n_l'] = (((zeroDelta / Number(course)) * 100).toFixed(2)) * -1;

            if (colorize) {
                currentTable.value.records[i].badPrice = Number(zeroPerc) > localCritPers;
            }
        });
    };

    onMounted(async () => {
        gl.showLoading = true;
        await botsGet(false);
        gl.showLoading = false;

        init.value = true;
    });

    return {
        gl,
        env,
        init,
        refs,
        stats,
        radios,
        isBots,
        isDates,
        periods,
        filters,
        actions,
        columns,
        loadings,
        someCoins,
        critPers,
        dashProfit,
        refFilters,
        actionType,
        sumWallets,
        currentTable,
        revenueStats,
        cloneMoreShowId,
        filtersChanged,
        dataSumWallets,
        showConfirmation,
        optionsSumWallets,
        actionButtonsDisabled,
        botsGet,
        currency,
        oneBotIsDone,
        groupAction,
        changeFilter,
        doSomething,
        resetFilters,
        letsShowConfirmation,
        calculatePercentDeviation,
    };
}