<template>
<div class="flex items-center cursor-pointer" @click="show = !show">
    <div class="text-md text-gray-600 dark:text-white/75 font-semibold">{{ bots.localization['stop_loss'] }}</div>
    <n-icon class="ml-2 transition transform text-base flex-grow" :class="show ? 'rotate-90' : ''">
        <IosArrowRtl24Filled />
    </n-icon>
    <n-tag v-if="localStartFilters.length" type="error" size="small" round>
        {{ localStartFilters.length }}
    </n-tag>
</div>
<base-drawer-mobile
    :label="bots.localization['stop_loss']"
    v-model:show="show">
    <template #default>
        <section>
            <a
                :href="howToSetStopLossLinks[refs.lang]"
                class="text-main underline hover:no-underline"
                target="_blank">
                {{ bots.localization['how_to_set_stop_loss'] || 'Как настроить стоп-лосс?' }}
            </a>
            <section class="mt-4">
                <div :id="getHTMLid('stop_loss:last_rate:change')">
                    <rb-checkbox
                        :nowrap="false"
                        :label="bots.localization['last_order_executed_percent_price_change'] || 'Выполнен последний ордер (% изменения цены)'"
                        :help="bots.localization['last_order_executed_percent_price_change_help'] || 'Выполнен последний ордер (% изменения цены)'"
                        :checked="!!~hasFilter('stop_loss:last_rate:change')"
                        @update:checked="triggerFilter('stop_loss:last_rate:change')" />

                    <rb-input
                        v-if="!!~hasFilter('stop_loss:last_rate:change')"
                        class="mt-2 w-full"
                        :value="dataForm.start_filters[hasFilter('stop_loss:last_rate:change')].value"
                        :status="!!bots.innerForms['stop_loss:last_rate:change'] ? 'error' : undefined"
                        :msg="bots.innerForms['stop_loss:last_rate:change']?.fields.value.msg"
                        @update:value="dataForm.start_filters[hasFilter('stop_loss:last_rate:change')].value = $event" />
                </div>
                <n-divider />

                <div :id="getHTMLid('stop_loss:rate:chang')">
                    <rb-checkbox
                        :nowrap="false"
                        :label="bots.localization['percentage_of_price_change_from_the_entry_price_of_the_trade'] || '% изменения цены от цены входа в сделку (% изменения цены)'"
                        :help="bots.localization['stop_loss_percentage_of_price_change_from_the_entry_price_of_the_trade'] || '% изменения цены от цены входа в сделку (% изменения цены)'"
                        :checked="!!~hasFilter('stop_loss:rate:change')"
                        @update:checked="triggerFilter('stop_loss:rate:change')" />

                    <rb-input
                        v-if="!!~hasFilter('stop_loss:rate:change')"
                        class="mt-2 w-full"
                        :value="dataForm.start_filters[hasFilter('stop_loss:rate:change')].value"
                        :status="!!bots.innerForms['stop_loss:rate:change'] ? 'error' : undefined"
                        :msg="bots.innerForms['stop_loss:rate:change']?.fields.value.msg"
                        @update:value="dataForm.start_filters[hasFilter('stop_loss:rate:change')].value = $event" />
                </div>
                <n-divider />

                <div v-if="!dataForm.settings.simulate" :id="getHTMLid('stop_loss:timeout:time')">
                    <rb-checkbox
                        :nowrap="false"
                        :label="bots.localization['trigger_Stop_Loss_after_n_minutes_of_inactivity'] || 'Запустить Стоп-Лосс через N минут бездействия (Время в минутах)'"
                        :help="bots.localization['trigger_Stop_Loss_after_n_minutes_of_inactivity_help'] || 'Запустить Стоп-Лосс через N минут бездействия (Время в минутах)'"
                        :checked="!!~hasFilter('stop_loss:timeout:time')"
                        @update:checked="triggerFilter('stop_loss:timeout:time')" />

                    <rb-input
                        v-if="!!~hasFilter('stop_loss:timeout:time')"
                        class="mt-2 w-full"
                        :status="!!bots.innerForms['stop_loss:timeout:time'] ? 'error' : undefined"
                        :msg="bots.innerForms['stop_loss:timeout:time']?.fields.value.msg"
                        :value="dataForm.start_filters[hasFilter('stop_loss:timeout:time')].value"
                        @update:value="dataForm.start_filters[hasFilter('stop_loss:timeout:time')].value = $event" />
                </div>
            </section>
            <n-divider />

            <rb-checkbox
                :nowrap="false"
                :id="getHTMLid('stop_loss:exit:bool')"
                :label="bots.localization['stop_bot_after_stop_loss_is_executed'] || 'Остановить бота после исполнения Стоп-Лосса'"
                :help="bots.localization['stop_bot_after_stop_loss_is_executed_help'] || 'Остановить бота после исполнения Стоп-Лосса'"
                :checked="!!~hasFilter('stop_loss:exit:bool')"
                @update:checked="triggerFilter('stop_loss:exit:bool')" />

            <!-- <rb-checkbox
                v-if="!dataForm.settings.simulate"
                class="mt-4"
                :nowrap="false"
                :id="getHTMLid('stop_loss:signal:bool')"
                :label="bots.localization['trigger_stop_loss_on_signal'] || 'Запустить Стоп-Лосс по сигналу'"
                :help="bots.localization['trigger_stop_loss_on_signal_help'] || 'Запустить Стоп-Лосс по сигналу'"
                :checked="!!~hasFilter('stop_loss:signal:bool')"
                @update:checked="triggerFilter('stop_loss:signal:bool')" /> -->
            <n-divider />

            <div class="mt-4">
                <div
                    v-for="(filter, i) in localStartFilters"
                    :key="i+'_'+filter.id+'_'+filter.id_op"
                    class="mb-4 w-full"
                    :id="getHTMLid(dataForm.start_filters[filter.index].id)">
                    <n-card
                        class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg relative h-full"
                        :segmented="{
                            content: true,
                            footer: 'soft'
                        }">
                        <n-icon
                            class="text-red-500 text-2xl ml-4 cursor-pointer absolute top-2 right-2"
                            @click="onDeleteStartFilter(filter.index)">
                            <DeleteDismiss24Regular />
                        </n-icon>

                        <div class="mt-4">
                            <!-- <pre>{{ filter }}</pre> -->
                            <rb-select
                                class="mb-2"
                                :label="bots.localization['bot_start_filters_filter_number'] + ++i"
                                :options="startFiltersStopLossOptions"
                                :help="filter.help.value"
                                :value="dataForm.start_filters[filter.index].parent_id"
                                @update:value="setMainSelect($event, filter)" />

                            <template v-for="field in filter.fields.value" :key="field.name">
                                <template v-if="field.input_type == 'select'">
                                    <rb-select
                                        class="mb-2"
                                        :label="field.title"
                                        :placeholder="field.placeholder"
                                        :options="getOptions(field.options)"
                                        :status="!!bots.innerForms[filter.id]?.fields[field.name]"
                                        :msg="bots.innerForms[filter.id]?.fields[field.name]?.msg"
                                        :value="dataForm.start_filters[filter.index][field.name] || field.value"
                                        @update:value="dataForm.start_filters[filter.index][field.name] = $event" />
                                </template>
                                <template v-if="field.input_type == 'text'">
                                    <div v-if="field.min != undefined || field.max != undefined" class="mb-2">
                                        <div
                                            class="text-md dark:text-white/75 whitespace-nowrap label text-left"
                                            :class="!!bots.innerForms[filter.id]?.fields[field.name] ? 'text-red-500' : 'text-gray-600'"
                                            v-html="field.title">
                                        </div>
                                        <n-input-number
                                            class="mt-2"
                                            :update-value-on-input="false"
                                            :value="dataForm.start_filters[filter.index][field.name] || field.value || field.min"
                                            @update:value="dataForm.start_filters[filter.index][field.name] = $event"
                                            :placeholder="field.placeholder"
                                            :status="!!bots.innerForms[filter.id]?.fields[field.name] ? 'error' : undefined"
                                            :min="field.min"
                                            :max="field.max" />
                                        <div class="text-md text-red-400 whitespace-nowrap w-full text-left mt-4">
                                            {{ bots.innerForms[filter.id]?.fields[field.name]?.msg }}
                                        </div>
                                    </div>
                                    <template v-else>
                                        <rb-input
                                            class="mb-2"
                                            :label="field.title"
                                            :placeholder="field.placeholder"
                                            :status="!!bots.innerForms[filter.id]?.fields[field.name]"
                                            :msg="bots.innerForms[filter.id]?.fields[field.name]?.msg"
                                            :value="dataForm.start_filters[filter.index][field.name] || field.value"
                                            @update:value="dataForm.start_filters[filter.index][field.name] = $event" />
                                    </template>
                                </template>
                            </template>

                            <rb-pair-select
                                v-if="startFilterHasPair(filter.id, filter.index) && pairsRefs"
                                :options="pairsRefs"
                                :label="bots.localization['pair_f']"
                                :value="dataForm.start_filters[filter.index].pair"
                                :placeholder="bots.localization['bots/start_filters/current_pair/label']"
                                @update:value="updateStartFilters({ i: filter.index, key: 'pair',  value: $event })" />
                        </div>
                    </n-card>
                </div>
                <n-button
                    strong
                    secondary
                    class="rounded-md w-full mt-4"
                    @click="onAddNewStartFilter">
                    + {{ bots.localization['add_stop_loss_by_indicators'] || 'Добавить стоп-лосс по индикаторам' }}
                </n-button>
            </div>
        </section>
    </template>
    <!-- <template #footer>
        <n-button
            strong
            class="rounded-md text-white/90 w-full"
            :color="gl.mainColor"
            :loading="orderMatrixLoading"
            :disabled="orderMatrixLoading"
            @click="onViewOrderMatrix">
            {{ bots.localization['bot_orders_matrix_view_orders_matrix_modal_update'] }}
        </n-button>
    </template> -->
</base-drawer-mobile>
</template>

<script>
// general
import general from './general';

// naive-ui
import {
    NTag,
    NIcon,
    NCard,
    NAlert,
    NButton,
    NDivider,
    NInputNumber,
    NCollapseTransition } from 'naive-ui';

// components
import RbInput from '@components/rb-input/mobile.vue';
import RbSelect from '@components/rb-select/mobile.vue';
import RbCheckbox from '@components/rb-checkbox/mobile.vue';
import RbPairSelect from '@components/rb-pair-select/mobile.vue';

// icons
import { Dismiss16Filled, IosArrowRtl24Filled, DeleteDismiss24Regular } from '@vicons/fluent';

export default {
    name: 'bot-stop-loss-mobile',
    props: {
        pairsRefs: {
            type: Array,
            required: true,
        },
        data: {
            type: Object,
            default: null,
        },
        inModal: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        NTag,
        NIcon,
        NCard,
        NAlert,
        RbInput,
        NButton,
        NDivider,
        RbSelect,
        RbCheckbox,
        NInputNumber,
        RbPairSelect,
        Dismiss16Filled,
        IosArrowRtl24Filled,
        NCollapseTransition,
        DeleteDismiss24Regular,
    },
    setup(props, context) {
        
        return {
            ...general(...arguments),
        };
    },
};
</script>