<template>
<div class="flex" :class="classes">
    <div
        class="text-xs lg:text-sm mt-[2px] lg:mt-0 label"
        :class="labelClasses"
        v-html="label">
    </div>

    <template
        v-if="localHelpers.length">
        <n-popover
            v-for="(help, i) in localHelpers"
            :key="i"
            trigger="hover"
            class="lg:max-w-[300px] max-w-[180px] lg:max-h-[400px] max-h-[180px] rounded-md overflow-auto"
            placement="top"
            :class="`bg-${help.color}`"
            :disabled="disabled">
            <template #trigger>
                <n-icon
                    class="text-lg cursor-pointer"
                    :class="[`text-${help.color}`, help.classes]" >
                    <component :is="help.icon" />
                </n-icon>
            </template>
            <span class="text-[10px] lg:text-xs text-white" v-html="help.content"></span>
        </n-popover>
    </template>
</div>
</template>
  
<script>
// general
import general from './general';
  
// naive-ui
import {
    NIcon,
    NPopover } from 'naive-ui';
  
// icons
import { Warning24Filled, QuestionCircle20Filled } from '@vicons/fluent';
  
export default {
    name: 'rb-title',
    props: {
        classes: {
            type: String,
        },
        label: {
            type: String,
            required: true,
        },
        status: {
            type: String,
            default: undefined,
        },
        helpers: {
            type: Array,
            default: () => [],
        },
        help: {
            type: String,
        },
        helpWarning: {
            type: String,
        },
        helpPosition: {
            type: String,
            default: 'right',
        },
        noWrap: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        NIcon,
        NPopover,
        Warning24Filled,
        QuestionCircle20Filled,
    },
    setup(props) {
  
        return {
            ...general(...arguments),
        };
    },
};
</script>