<template>
<section>
    <rb-label
        :label="bots.localization?.total_funds || 'Общие средства (кошелек+маржа)'" />
    <section class="flex mt-2">
        <rb-input
            class="mr-1"
            type="percent"
            v-model:value="totalFunds" />
        <n-button
            strong
            secondary
            class="rounded-md ml-1"
            :loading="loading"
            :disabled="loading"
            @click="whereIsTheLiquid">
            <n-tag round size="small" type="warning" class="mr-2">
                <span class="text-yellow-500 font-semibold text-sm">new</span>
            </n-tag>
            {{ bots.localization?.where_is_the_liquid || 'Где ликвид?' }}
        </n-button>
    </section>
    <div v-if="info.liquid" class="text-gray-500 dark:text-white/75 text-xs mt-2 text-right" v-html="info.liquid"></div>
    <div v-if="info.orderLiquidStr" class="text-gray-500 dark:text-white/75 text-xs mt-2 text-right" v-html="info.orderLiquidStr"></div>
</section>
</template>

<script>
// general
import general from './general';

// icons
import { DrawerArrowDownload20Regular } from '@vicons/fluent';

// naive-ui
import {
    NAlert,
    NIcon,
    NCard,
    NSpin,
    NTag,
    NButton, 
    NDivider } from 'naive-ui';

export default {
    name: 'bot-point-elimination-mobile',
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        NTag,
        NIcon,
        NCard,
        NSpin,
        NAlert,
        NButton,
        NDivider,
        DrawerArrowDownload20Regular,
    },
    setup(props, context) {

        return {
            ...general(...arguments),
        };
    },
};
</script>