<template>
<n-alert
    id="coint-list-alert"
    v-if="tools.localization['tools/va/coin_list/about']"
    class="rounded-lg" type="warning" size="large">
    <div
        class="notes text-xs"
        v-html="tools.localization['tools/va/coin_list/about']">
    </div>
</n-alert>

<n-card
    size="small"
    :bordered="false"
    class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg mt-4"
    :segmented="{
      content: true,
    }">
    <section class="flex">
        <rb-input
            class="w-4/12 pr-2"
            :label="tools.localization['tools/va/coin_list/new_list_name']"
            v-model:value="dataForm.title" />

        <div class="flex items-end px-2 w-4/12">
            <rb-select
                clearable
                class="flex-grow"
                :disabled="dataForm.id"
                :label="tools.localization['tools/va/coin_list/select_exchange']"
                :options="exchangeOptions"
                v-model:value="dataForm.exchange" />
            <div
                class="rb-td-stock-icon m-0 ml-4 h-10"
                :class="`rb-td-stock-icon-${exchange(dataForm.exchange)?.code_name}`">
            </div>
        </div>
    </section>

    <div class="flex mt-6">
        <n-button
            class="text-white/90 rounded-md"
            :color="gl.mainColor"
            :disabled="dataForm.exchange == -1"
            @click="onAddAllExchangeCoins">
            {{ tools.localization['tools/va/settings/coin_list/add_all_exchange_coins'] }}
            <strong class="ml-2 text-base">{{ exchange(dataForm.exchange)?.title }}</strong>
        </n-button>

        <n-button
            class="ml-2 text-white/90 rounded-md"
            :color="gl.mainColor"
            :disabled="dataForm.exchange == -1"
            @click="onClearAllExchangeCoins">
            {{ tools.localization['tools/va/settings/coin_list/clear_all_exchange_coins'] }}
            <strong class="ml-2 text-base">{{ exchange(dataForm.exchange)?.title }}</strong>
        </n-button>
    </div>

    <rb-coin-list
        multiple
        class="w-full mt-4"
        :disabled="!exchangeCurrencies?.length"
        :label="tools.localization['tools/va/coin_list/select_coins']"
        v-model:value="dataForm.coins"
        :options="exchangeCurrencies" />

    <div class="flex justify-end mt-4">
        <n-button
            class="text-white/90 rounded-md"
            :disabled="!canCreate"
            :color="gl.mainColor"
            @click="onCreateCoinList">
            {{ refs.localization.dialogs.create }}
        </n-button>
        <template v-if="canUpdate" >
            <n-button
                class="ml-4 text-white/90 rounded-md"
                :disabled="!canUpdate"
                :color="gl.mainColor"
                @click="onUpdateCoinList">
                {{ refs.localization.dialogs.update }}
            </n-button>
            <n-button
                class="ml-4 text-white/90 rounded-md"
                :disabled="!canUpdate"
                :color="gl.mainColor"
                @click="onCancelCoinList">
                {{ refs.localization.dialogs.cancel }}
            </n-button>
        </template>
        <n-button
            v-if="canDelete"
            class="ml-4 text-white/90 rounded-md"
            :disabled="!canDelete"
            :color="gl.mainColor"
            @click="onDeleteCoinList">
            {{ refs.localization.dialogs.delete }}
        </n-button>
    </div>
</n-card>

<n-card
    size="small"
    :bordered="false"
    class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg mt-4"
    :segmented="{
      content: true,
    }">
    <template #header>
        <div class="text-left text-gray-600 dark:text-white/75">{{ tools.localization['tools/va/coin_list/existed_coin_lists'] }}</div>
    </template>

    <div class="flex flex-wrap">
        <div
            v-for="(item, i) in refsCoinLists"
            :key="i"
            class="w-4/12 mb-4 coin-card">
            <n-card
                class="flex flex-col items-center cursor-pointer hover:border hover:border-main rounded-md"
                content-style="padding: 0; width: 100%;"
                @click="onCoinListItemSelected(item)">
                <div
                    class="text-base text-main py-2 w-full"
                    :class="item.id === currCoinListId ? 'bg-main text-white' : ''">{{item.title}}&nbsp;<em>({{item.coins.length}})</em></div>
                <span
                    class="rb-td-stock-icon text-center"
                    :class="`rb-td-stock-icon-${exchange(item.exchange)?.code_name}`">
                </span>
            </n-card>
        </div>
    </div>
</n-card>
</template>

<script>
// general
import general from './general';

// components
import RbInput from '@components/rb-input';
import RbSelect from '@components/rb-select';
import RbCoinList from '@components/rb-coin-list';

// icons
import { Warning24Filled } from '@vicons/fluent';

// naive-ui
import {
    NIcon,
    NCard,
    NAlert,
    NButton } from 'naive-ui';

export default {
    name: 'tools-coin-list-desktop',
    components: {
        NCard,
        NIcon,
        NAlert,
        RbInput,
        NButton,
        RbSelect,
        RbCoinList,
        Warning24Filled,
    },
    setup(props, context) {
        
        return {
            ...general(...arguments),
        };
    },
};
</script>

<style lang="scss" scoped>
.coin-card {
    &:nth-child(3n + 2) {
        padding: 0 8px;
    }

    &:nth-child(3n + 1) {
        padding-right: 8px;
    }

    &:nth-child(3n + 3) {
        padding-left: 8px;
    }
}
</style>